const serviceStatus = [
  {
    name: "فعال",
    id: "active",
  },
  {
    name: "منقضی شده",
    id: "expired",
  },
];
export default serviceStatus;
