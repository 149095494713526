import { useEffect , useState } from "react"
import { axios } from '../boot'
const useGetPlan = (id = "") => {
    const [ plans , setPlans ] = useState([])
    const getPlan = () => {
        const url = "/plans/getPlans";
        axios.get(url).then(({ data }) => {
            setPlans(data.data);
        });
    }
    useEffect(getPlan,[id])
    let plan = id.length > 0 ? plans?.find(e => e._id == id) : plans
    return plan
}

export default useGetPlan