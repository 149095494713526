import React , { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useRenderNavItems from "./_useRenderNavLink";
import { Badge } from "../../components";
import { Link, NavLink } from "react-router-dom";
import { rolesPath } from "../../constants";
import { useShowFullName } from "../../hooks";
import { showCartCount } from "../../methods";
export default function Menu({ show = true, setShow = () => { } }) {
  const renderNavItems = useRenderNavItems();
  const isLogged = useSelector((s) => s.isLogged);
  const showFullName = useShowFullName();
  const cartCount = useSelector((s) => s.cartCount);
  const role = useSelector((s) => s.role);


  const menu = useRef();
  const handleShow = () => {
    menu.current.classList.toggle("active", show);
  };
  useEffect(handleShow, [show]);
  return (
    <div
      ref={menu}
      className="Menu position-fixed overflow-hidden w-100 h-100 transition d-block d-xl-none"
    >
      <button
        onClick={() => setShow(false)}
        className="hide-btn position-absolute w-100 h-100 border-0"
      />
      <nav className="position-relative d-flex flex-column row-gap-3 text-light h-100 bg-dark px-3 py-5 overflow-auto transition">

        {renderNavItems()}
        {isLogged ? (
            <React.Fragment>
              <NavLink to={rolesPath[role]} className="fs-13 fw-600">
                {showFullName()}
              </NavLink>
              <NavLink
                to="/cart"
                className="position-relative fs-13 fw-600"
              >
                {cartCount !== 0 && (
                  <Badge variant="danger" label={showCartCount(cartCount)} />
                )}
                سبد خرید
              </NavLink>
            </React.Fragment>
          ) : (
            <Link
              to='/login'
              className="fs-13 fw-600 rounded-0 text-white"
            >
              ورود / ثبت نام
            </Link>
          )}
      </nav>
    </div>
  );
}
