import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { Button, Table, Accordion, Modal, Form, Input } from "../../../components";
import { jsonToXlsx, toast } from "../../../methods";
import { axios } from "../../../boot";
import moment from "moment-jalaali";
import { useNavigate } from "react-router";




export default function Reporting() {
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const navigate = useNavigate();


  const getData = () => {
    const url = "/admins/services";
    const params = {
      sort: "createdAt:-1",
    };
    axios.get(url, { params }).then(({ data }) => {

      setData(data.data);
      
    });
  };


  const downloadXlsx = (jsonData = []) => {
    const title = "user-reports";
    const json = cloneDeep(jsonData).map((item) => {
      const user = item.user[0];
      item["user-fullName"] = `${user.firstName} ${user.lastName}`;
      item["user-phone"] = user.phone;
      item["plan"] = item.product?.name;
      item["info"] = item.defaultLogin ?? "-";
      delete item.user;
      delete item.product;
      delete item.createdAt;
      delete item.random;
      delete item.defaultLogin;
      return item;
    });
    jsonToXlsx({ json, title });
  };
  const handleFilterData = (e = {}) => {
    const fullName = `${e?.user[0]?.firstName ?? ""} ${e?.user[0]?.lastName ?? ""
      }`;

    const condition1 = (e?.ip ?? "").search(filterText) !== -1;
    const condition2 = fullName.search(filterText) !== -1;

    return condition1 || condition2;
  };
  const filterData = () => {
    const services = cloneDeep(data).filter(handleFilterData);
    const locations = [...new Set(services.map((i) => i.product.location))];
    const plans = [...new Set(services.map((i) => i.product.name))];

    return locations.map((location) => {
      return {
        title: location,
        plans: plans
          .map((plan) => {
            return {
              title: plan,
              items: services.filter(
                (e) =>
                  e.product.location === location && e.product.name === plan
              ),
            };
          })
          .filter((e) => e.items.length),
      };
    });
  };



 



  const handleDate = (date) => {
    const shamsi = date?.split("/").reverse().join("/")
    const miladi = moment(shamsi,'jYYYY/jM/jD').format("YYYY/M/D")
    const convert = miladi?.split("/").join("-")    
    const endDate = new Date(convert)
    const toDay = Date.now()

    if(toDay > endDate){
      return true
    }else{
      return false
    }
  }


  useEffect(getData, []);
  return (
    <Container className="Products">

      <Row className="w-100">

        <Col xs="12" className="d-flex flex-center">
          <Button className="text-white" onClick={() => downloadXlsx(data)}>
            دانلود فایل اکسل کل خریدها
            <i className="me-1 bi bi-download" />
          </Button>
        </Col>
        <Col xs="12" className="row">
          <Col xs="12" md="7" lg="5">
            <InputGroup dir="ltr">
              <InputGroup.Text as="span">
                <i className="bi bi-search" />
              </InputGroup.Text>
              <FormControl
                dir="rtl"
                placeholder="جستجو براساس نام و ip"
                onInput={({ target }) => setFilterText(target.value)}
              />
            </InputGroup>
          </Col>
        </Col>
        <Col xs="12" className="d-flex flex-column flex-center row-gap-3">
          {filterData().map((location, index) => (
            <Accordion variant="dark-blue" key={location.title} title={location.title}>
              <div className="d-flex flex-column flex-center row-gap-2">
                {location.plans.map((plans, index) => (
                  <Accordion
                    key={plans.title}
                    title={`${plans.title} (${plans.items.length})`}
                  >
                    <Button
                      onClick={() => downloadXlsx(plans.items)}
                      className="text-light"
                    >
                      {`دانلود فایل اکسل خریدهای ${location.title}-${plans.title}`}
                      <i className="me-1 bi bi-download" />
                    </Button>
                    <Table className="text-center">
                      <thead>
                        <tr>
                          <th>ردیف</th>
                          {/* <th>نام طرح</th> */}
                          <th>IP</th>
                          {/* <th>RAM</th>
                          <th>CPU CORE</th>
                          <th>حافظه ذخیره‌سازی</th>
                          <th>پهنای باند</th> */}
                          {/* <th>محل سرور</th> */}
                          <th>قیمت</th>
                          <th>تاریخ شروع</th>
                          <th>تاریخ پایان</th>
                          <th>کاربر</th>
                          {/* <th>حذف / ویرایش</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {plans.items.map((item, index) => (
                          <tr onClick={() => navigate(item._id)} className={handleDate(item?.endDate) ? "bg-danger text-white" : ""}  key={index}>
                            <td>{index + 1}</td>
                            {/* <td>{item.product.name}</td> */}
                            <td>{item.ip}</td>
                            {/* <td>{item.product.ram}</td>
                            <td>{item.product.cpu}</td>
                            <td>{item.product.storage}</td>
                            <td>{item.product.bandwidth}</td> */}
                            {/* <td>{item.product.location}</td> */}
                            <td>
                              {Number(item?.product?.price).toLocaleString()}{" "}
                              تومان
                            </td>
                            <td>
                              {item.startDate?.split("/").reverse().join("/")}
                            </td>
                            <td>
                              {item.endDate?.split("/").reverse().join("/")}
                            </td>
                            <td>
                              {`${item?.user[0]?.firstName} ${item?.user[0]?.lastName}`}
                              <span dir="ltr">({item?.user[0]?.phone})</span>
                            </td>
                            {/* <td>
                              <span className="mx-1" onClick={() => deleteUser(item._id)}><i className="bi bi-trash" aria-hidden="true"></i></span>
                              <span className="mx-1" ><i onClick={() => { setInfo(item);setShow(true) }} className="bi bi-pen"></i></span>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion>
                ))}
              </div>
            </Accordion>
          ))}
        </Col>
      </Row>

    </Container>
  );
}
