import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import BDropdown from "react-bootstrap/Dropdown";
import InputGroup from "../InputGroup";
import Input from "../Input";
export default function Select({
  label = "",
  value = "",
  items = [],
  itemLabel = "name",
  itemValue = "id",
  filter = false,
  className = "",
  drop = "down",
  align = "right",
  rules = [],
  setValue = () => { },
  cancelBtn = false,
}) {
  const [filterText, setFilterText] = useState("");
  const filterItem = () => {
    if (filter) {
      return items.filter((e) => e[itemLabel].search(filterText) !== -1);
    }
    return items;
  };
  const showTitle = () => {
    const defaultTitle = {};
    defaultTitle[itemLabel] = "";
    defaultTitle[itemValue] = "";
    const title =
      items.find((e) => String(e[itemValue]) === String(value)) ?? defaultTitle;
    return title[itemLabel];
  };
  const showCancelBtn = cancelBtn && !!showTitle();
  return (
    <BDropdown
      className={`Select  w-100 ${className}`}
      onSelect={setValue}
      drop={drop}
    >
      <BDropdown.Toggle as="span" className="d-block w-100 mb-3">
        <Input
          readOnly
          label={label}
          value={showTitle()}
          rules={rules}
          prepend={
            <i className="bi bi-chevron-down input-group-text px-0 opacity-0" />
          }
          append={<>
            <i className="bi bi-chevron-down input-group-text px-0 " />
            <button
              type="button"
              onClick={() => setValue(undefined)}
              className={`bi bi-x-lg text-danger input-group-text border-light-gray ${showCancelBtn ? "d-block" : "d-none"}`}
            />
          </>
          }
        />
      </BDropdown.Toggle>
      <BDropdown.Menu style={{minHeight : "80px",height:"300px",overflowY :"scroll"}} className="" align={align}>
        {filter && (
          <div className="dropdown-item">
            <FormControl
              value={filterText}
              onChange={({ target }) => {
                setFilterText(target.value);
              }}
            />
          </div>
        )}
        {filterItem().map((item, index) => (
          <BDropdown.Item
            key={index}
            className="text-center"
            eventKey={item[itemValue]}
          >
            {item[itemLabel]}
          </BDropdown.Item>
        ))}
      </BDropdown.Menu>
    </BDropdown>
  );
}
