import {useEffect, useState} from 'react'

import { Form , Input } from '../../../components'
import './index.scss'
import cup from '../../../assets/images/profile/cup.svg'
import medal from '../../../assets/images/profile/medal.svg'
import copy from '../../../assets/images/profile/copy.svg'
import {axios} from '../../../boot'
import { toast } from '../../../methods'
import { useSelector } from 'react-redux'

const Score = ({profile = {}}) => {
    const [ data , setData ] = useState({})
    const scoreInfo = [
        {
            label: "امتیاز:",
            value: profile?.Score ? profile?.Score : 0,
            src: cup
        },
        {
            label: "جایزه:",
            value: profile?.Reward ? profile?.Reward : 0,
            src: medal
        },
    ]
    const formControl = [
        {
            label : "ولت",
            state : "wallet"
        }
    ]
    const handleWallet = () => {
        const url = "/users/SetUserWallet"
        axios.post(url,data).then(({data})=>{
            let text = "آدرس ولت با موفقیت بروزرسانی شد"
            toast({text})
        })
    }
    return (
        <div className="w-100 Your-score bg-white">
            <div className="bg-white w-100 px-4 py-4">
                <p className="fs-24 mb-4 fw-700 text-black">امتیازات شما:</p>
                {scoreInfo.map((item) =>
                    <div key={item.label} className='bg-harp px-2 py-3 my-2 d-flex justify-content-between align-items-center'>
                        <p className="text-black fs-14 fw-700">{item.label}</p>
                        <div className="d-flex align-items-center">
                            <p className='fs-14 fw-700 mx-2 text-black'>{item.value}</p>
                            <img src={item.src} alt="score" />
                        </div>
                    </div>
                )}
                <div className='px-2 py-3 my-4 d-flex justify-content-between align-items-center Identification-code'>
                    <p className="text-black fs-14 fw-700">کد معرف شما:</p>
                    <div className="d-flex align-items-center">
                        <p className='fs-14 fw-700 mx-2 text-black'>{profile?.ReferralCode}</p>
                        <img src={copy} alt="copy" className='cursor-pointer' />
                    </div>
                </div>
            </div>
            <div className="border-top border-black border-3 bg-white w-100 px-4 py-4">
                <Form onSubmit={handleWallet} >
                    {formControl.map((item) => 
                        <Input
                        key={item.state}
                          {...item}
                          value={data[item.state]}
                          setValue={(val) =>
                            setData((p) => ({ ...p, [item.state]: val }))
                          }
                          className="border"
                        />
                    )}
                    <button className="btn btn-tufts-blue w-100 rounded-0 text-white py-2">ثبت</button>
                </Form>
            </div>
        </div>
    )
}

export default Score