import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { guidance } from "../../../constants";
import { PermitSection , CardServices } from "../../../components";
import headerImg from "../../../assets/icons/header.svg";
import backgroundImg from "../../../assets/icons/home/background.svg";
import serversImg from "../../../assets/icons/home/servers.svg";
import { AccordionHover } from "../../../components";
import MainSection from "./MainSection";
import JoinMarvelSchool from "./JoinMarvelSchool";
import Introduce from "./Introduce";
import "./index.scss";
import TermAndConditions from "./TermAndConditions";
import UserSatisfaction from "./UserSatisfaction";

export default function Home() {
  return (
    <div className="Home">
      <Container className="bg-tufts-blue position-relative">
        <MainSection />
      </Container>
      <UserSatisfaction/>
      <Container>
         <TermAndConditions/>
         <JoinMarvelSchool />
         <div className="guidance w-100 text-center pt-5 pb-4">
        <div className=" row-gap-4 w-100 py-2">
          <p className="text-black fs-50 fw-600 lh-normal">خدمات مارول ترید چیست؟</p>
          <p className="text-black fs-18 fw-400 my-4">
            راهکارهای اقتصادی بر پایه مطالعه و آگاه‌سازی
            
            پیشرفت اقتصادی با <br /> ذهنی آگاه و پویا امکانپذیر است
          </p>
          <div className=" w-100 my-4 d-flex flex-wrap justify-content-center gap-1">
            {guidance.map((item, index) => 
              <CardServices key={index} {...item} />
            )}
          </div>
        </div>
      </div>
      </Container>
      
      {/* guidance */}
      
      {/* windows server section */}
      {/* <div className="position-relative py-5">
        <img className="bg-img" src={backgroundImg} alt="backgroundImg" />
        <Container className="position-relative">
          <Row>
            <Col
              xs="12"
              md="10"
              lg="6"
              className="d-flex flex-column row-gap-5 text-center"
            >
              <h3 className="text-primary">
                ویندوز سرور مخصوص ترید + آموزش ویدئویی
              </h3>
              <div className="lh-lg fw-bold text-justify text-last-center">
                <p>
                  در حال حاضر استفاده از VPN برای معاملات امن نیست زیرا از IP
                  متفاوت استفاده می کنند و این امر باعث میشود مکان فعلی شما
                  (کشور های ممنوعه از جمله ایران و ... ) مشخص شود . ملاک ما در
                  استفاده از VPS ثابت بودن IP آن است که یکی از بهترین روش ها
                  برای جلوگیری از ایجاد مشکل در انجام معاملات در پلتفرم های
                  خارجی مانند بایننس ، کوکوین و ... است.
                  <br />
                  شما میتوانید با تهیه VPS از تغییر مکرر IP خود و مسدود شدن و
                  حساب خود جلوگیری کنید.
                </p>
              </div>
              <Link
                to="/windows-server-trade"
                className="w-100 Button btn btn-lg btn-primary text-light"
              >
                خرید ویندوز سرور ترید
                <i className="bi bi-chevron-left me-1" />
              </Link>
            </Col>
            <Col xs="12" md="10" lg="6">
              <img className="w-100" src={serversImg} alt="serversImg" />
            </Col>
          </Row>
        </Container>
      </div> */}
      {/* <PermitSection /> */}
    </div>
  );
}
