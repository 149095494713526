import { useState, useEffect } from 'react'

import { Row, Col , Tabs, Tab , Container } from 'react-bootstrap'
import { useDispatch , useSelector } from 'react-redux';
import { ChoosenPlan , ServerCard } from '../index'
import { toast } from '../../methods';
import { useNavigate } from 'react-router';
import {axios} from '../../boot'
import './index.scss'

const ChooseVps = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogged = useSelector((state) => state.isLogged);
    const profile = useSelector((state) => state.profile);
    const [products, setProducts] = useState({ plans: [], allPlans: [] });
    const addToCart = (id = "") => {
        if (!isLogged) {
            const text = "برای خرید باید وارد حساب کاربری خود شوید.";
            toast({ text, type: "error" });
            return;
        }
        if (profile.needRegister) {
            const text = "برای خرید باید حساب کاربری خود را تکمیل کنید.";
            toast({ text, type: "error" });
            navigate({ search: "needRegister=true" });
            return;
        }
        const url = "/carts";
        const body = {
            productId: id,
            count: 1,
        };
        axios.post(url, body).then(() => {
            dispatch({ type: "ADD_TO_CART_COUNT" });
            const text = "محصول مورد نظر با موفقیت به سبد خرید اضافه شد.";
            toast({ text });
        });
    };
    const getProducts = () => {
        const url = "/pub/products";
        axios.get(url).then(({ data }) => {
            let plans = data.map((plan) => plan.location);
            plans = [...new Set(plans)];
            plans = plans.map((location) => ({
                location,
                plans: data.filter((e) => e.location === location),
            }));
            setProducts({ plans, allPlans: data });
        });
    };
    useEffect(getProducts, []);
    return (
        <Container className="w-100 CHOOSEVPS">
            <Row className="">
                <Col className="" xs="12" lg="12">
                    <p className=" my-4 fs-18 fw-500 text-black text-start">کشور سرور را انتخاب کنید:</p>
                    <Tabs
                        dir="ltr"
                        defaultActiveKey={0}
                        className="tab-btns d-flex justify-content-center justify-content-lg-end gap-3 border-0"
                    >
                        {products.plans.map((item, index) => (
                            <Tab
                                className="my-3"
                                key={index}
                                eventKey={index}

                                title={
                                    <span className="tab-btn btn btn-outline-light">
                                        {item.location}
                                    </span>
                                }
                            >
                                <p className="fs-18 fw-500 text-black my-4 text-start">نوع پلن را انتخاب کنید:</p>

                                <Tabs
                                    dir="ltr"
                                    variant="pill"
                                    defaultActiveKey={0}
                                    className="tab-btns d-flex justify-content-center justify-content-lg-end gap-3"
                                >
                                    {item.plans.map((plan, index) => (

                                        <Tab
                                            className="my-3"
                                            key={index}
                                            eventKey={index}
                                            title={
                                                <span className="tab-btn btn btn-outline-light ">
                                                    {plan.name}
                                                </span>
                                            }
                                        >
                                            <div className="row align-items-start">
                                                <Col className="px-0" xs="12" lg="12"><ChoosenPlan {...plan} /></Col>
                                                <Col className="px-0" xs="12" lg="12"><ServerCard {...plan} addToCart={addToCart} /></Col>
                                            </div>

                                        </Tab>
                                    ))}
                                </Tabs>
                            </Tab>
                        ))}
                    </Tabs>
                </Col>
                {/* <Col lg="4" xs="12">
              <ServerCard {...details} addToCart={addToCart} />
            </Col> */}
            </Row>
        </Container>
    )
}

export default ChooseVps