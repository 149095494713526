const purchaseStatus = [
    {
        name: "رد شده",
        id: "REJECTED",
        color: "danger",
        filterable: true,
      },
   {
     name: "در انتظار تایید",
     id: "IN PROGRESS",
     color: "warning",
     filterable: true,
   },
   {
     name: "تایید شده",
     id: "APPROVED" ,
     color: "success",
     filterable: true,
     isUpdateStatus: true,
   },
 ];
 export default purchaseStatus;
 