import BBadge from "react-bootstrap/Badge";
import "./index.scss";
export default function Badge({
  label = "",
  pill = false,
  variant = "primary",
  color = "light",
  className = "",
  onClick = () => {},
}) {
  return (
    <BBadge
      pill={pill}
      onClick={onClick}
      className={`bg-${variant} text-${color} border-${variant} ${className} bg-opacity-75 border rounded-1 fs-7 transition`}
    >
      {label ?? ""}
    </BBadge>
  );
}
