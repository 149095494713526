import { useState } from "react";
import { axios } from "../boot";

export default function useGetChannels(data=[]) {
  const [channels, setChannels] = useState([]);
  const getChannels = () => {
    const url = "/channels/getChannels";
    axios.get(url).then((res) => {
      
      const data = res.data.data;
      
      setChannels(data);
    });

  };
  return [channels, getChannels];
}
