import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Form, Modal, Button, InputGroup, Select } from "../../components";
import { useQueryString } from "../../hooks";
import { rules, toast } from "../../methods";
import { axios } from "../../boot";
export default function RegisterModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = useQueryString();
  const [data, setData] = useState({});
  const show = queryString.needRegister ?? false;
  const onHide = () => {
    navigate({ search: "" });
  };
  const setProfile = (data) => {
    dispatch({ type: "SET_PROFILE", data });
  };
  const formControls = [
    {
      tag: InputGroup,
      label: "نام",
      state: "firstName",
      rules: rules.required,
    },
    {
      tag: InputGroup,
      label: "نام خانوادگی",
      state: "lastName",
      rules: rules.required,
    },
    {
      tag: InputGroup,
      label: "ایمیل",
      type: "email",
      state: "email",
      rules: rules.email,
    },
    {
      tag: InputGroup,
      label: "سال تولد",
      type: "number",
      state: "birthDate",
      rules: rules.birthDate,
    },
    {
      tag: Select,
      label: "جنسیت",
      state: "gender",
      items: [
        { name: "زن", id: "female" },
        { name: "مرد", id: "male" },
      ],
      rules: rules.required,
    },
  ];
  const register = () => {
    const url = "/users/me";
    const body = data;
    axios.put(url, body).then(({ data }) => {
      const text = "اطلاعات پروفایل با موفقیت ثبت شد";
      toast({ text });
      setProfile(data);
      onHide();
    });
  };
  return (
    <Modal title="تکمیل اطلاعات" show={show} onHide={onHide}>
      <Form onSubmit={register}>
        <Row>
          {formControls.map((item, index) => (
            <Col key={index} xs="12">
              {React.createElement(item.tag, {
                ...item,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
              })}
            </Col>
          ))}
          <Col xs="12">
            <Button
              className="w-100 text-light"
              variant="success"
              type="submit"
            >
              ثبت اطلاعات
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
