import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import serverIcon from "../../../assets/icons/cart/server.svg";
import undrawShoppingIcon from "../../../assets/icons/cart/undraw shopping.svg";
import CartCard from "../../../components/CartCard";
import { axios } from "../../../boot";
import { Button, Modal, VpsRules , Input } from "../../../components";
import { startOrder, toast } from "../../../methods";
import './index.scss'

export default function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartCount = useSelector(state => state.cartCount)
  const [products, setProducts] = useState([]);
  const [showRules, setShowRules] = useState(false);
  const [ data , setData ] = useState({})
  const getProducts = () => {
    const url = "/carts";
    axios.get(url).then(({ data }) => {
      const content = data?.content ?? [];
      setProducts(content);
      console.log(content)
    });
  };
  const removeItem = (id = "", count = 0) => {
    const url = "/carts";
    const data = {
      productId: id,
      count: count,
    };
    axios.delete(url, { data }).then(({ data }) => {
      dispatch({ type: "REMOVE_FROM_CART_COUNT", data: count });
      setProducts(data.content);
      const text = "عملیات با موفقیت انجام شد.";
      toast({ text });
    });
  };
  const addItem = (id = "") => {
    const url = "/carts";
    const body = {
      productId: id,
      count: 1,
    };
    axios.post(url, body).then(({ data }) => {
      dispatch({ type: "ADD_TO_CART_COUNT" });
      setProducts(data.content);
      const text = "عملیات با موفقیت انجام شد.";
      toast({ text });
    });
  };
  const startOrderUsd = () => {
    const url = "/carts/start-order-usd";
    axios.get(url).then(({ data }) => {
      navigate("/start-order-usd", { state: data });
    });
  };

  const finalPrice = (data) => {
    let a = 0
    for (let i = 0; data.length > i; i++) {
      a = a + Number(data[i]?.totalPrice)
    }
    return a.toLocaleString()
  }

  const formControls = [
    {
      label : "کد تخفیف:",
      state : "code"
    }
  ]
  useEffect(getProducts, []);
  return (
    <div className="Cart py-1">
      <div className="bg-black cart-header"></div>
      <Container className="card-body">
        <p className="fs-24 fw-700 text-white my-4">سبد خرید</p>
        {products.length ? (
          <Row className="align-items-start justify-content-end p-0 bg-white">
            {/* <Col xs="12" lg="6" className="d-flex row">
              <img
                className="col-12 object-fit-contain"
                height="290"
                src={serverIcon}
                alt="serverIcon"
              />
              <Button
                variant="success"
                className="col-12 col-md-6 col-lg-4 text-light"
                onClick={() => setShowRules(true)}
              >
                پرداخت
              </Button>
            </Col> */}

            <Col xs="12" lg="12" className="d-flex flex-column  border border-black border-3">
              {products.map((item, index) => (
                <CartCard
                  key={index}
                  next={products.length > 1 ? true : false}
                  onDelete={() => removeItem(item._id, item.count)}
                  onDeleteItem={() => removeItem(item._id, 1)}
                  onAddItem={() => addItem(item._id)}
                  {...item}
                />
              ))}
            </Col>

            <Col className=" py-4 cart-payment" xs="12" lg="4" xl="4">
              <div className="row">
                <>
                  <Col xs="6">
                    <p className="fs-24 fw-700 text-black text-start  ">قیمت کل</p>
                  </Col>
                  <Col xs="6">
                    {/* {(product.+price).toLocaleString()} */}
                    <p className="fs-24 fw-700 text-black text-end mx-2  ">{finalPrice(products)}</p>
                  </Col>
                </>
                <Col>
                  {formControls.map((item)=>
                    <Input
                    key={item.state} 
                      {...item}
                      className="border"
                      value={data[item.state]}
                      setValue={(val) =>
                        setData((p) => ({ ...p, [item.state]: val }))
                      }
                      
                    />
                  )}
                </Col>
                <Col xs="12">
                  <Button
                    variant="tufts-blue"
                    className="w-100 py-2 text-white "
                    onClick={() => setShowRules(true)}
                  >
                    پرداخت
                  </Button>
                </Col>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="d-flex flex-column flex-center text-center row-gap-4">
            <img width="245" src={undrawShoppingIcon} alt="undrawShoppingIcon" />
            <h4 className="text-secondary">سبد خرید شما خالی است</h4>
          </div>
        )}
      </Container>

      <Modal
        closeBtn
        title="قوانین (vps)"
        show={showRules}
        onHide={setShowRules}
      >
        <VpsRules />
        <p className="my-3">
          <i className="bi bi-info text-warning fs-4" />
          خرید محصولات به منزله تایید و پذیرش قوانین است.
        </p>
        <div className="buttons">
          <Button
            onClick={startOrderUsd}
            variant="black"
            className="text-light"
          >
            پرداخت با تتر
          </Button>
          <Button onClick={startOrder} variant="tufts-blue" className="text-light">
            پرداخت از طریق درگاه پرداخت
          </Button>
        </div>
      </Modal>
    </div>
  );
}
