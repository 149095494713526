import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Button, Table } from "../../../components";
import { axios } from "../../../boot";
import AddNewProduct from "./AddNewProduct";
export default function Products() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const getProducts = () => {
    const url = "/admins/pub/products";
    axios.get(url).then(({ data }) => {
      setProducts(data.data);
    });
  };
  useEffect(getProducts, []);
  return (
    <React.Fragment>
      <Container className="Products">
        <Row className="w-100">
          <Col xs="12" md="10">
            <Table className="text-center">
              <thead>
                <tr>
                  <th className="px-4">نام پلن</th>
                  <th className="px-4">RAM</th>
                  <th className="px-4">CPU CORE</th>
                  <th className="px-4">حافظه ذخیره‌سازی</th>
                  <th className="px-4">پهنای باند</th>
                  <th className="px-4">محل سرور</th>
                  <th className="px-4">قیمت(تومان)</th>
                  <th className="px-4">قیمت(USD)</th>
                  <th className="px-4">درصد تخفیف</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item, index) => (
                  <tr key={index} onClick={() => navigate(item._id)}>
                    <td className="px-4 font-en">{item.name}</td>
                    <td className="px-4 font-en">{item.ram}</td>
                    <td className="px-4 font-en">{item.cpu}</td>
                    <td className="px-4 font-en">{item.storage}</td>
                    <td className="px-4">{item.bandwidth}</td>
                    <td className="px-4">{item.location}</td>
                    <td className="px-4 font-en">
                      {Number(item.price).toLocaleString()} تومان
                    </td>
                    <td className="px-4 font-en">
                      {Number(item.priceUsd).toLocaleString()} USD
                    </td>
                    <td className="px-4 font-en">{`${item.off}%`}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="8">
                    <Button
                      onClick={() => setShowAddModal(true)}
                      variant="success"
                      className="text-light"
                    >
                      اضافه کردن پلن جدید
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <AddNewProduct
        show={showAddModal}
        onHide={setShowAddModal}
        afterSubmit={getProducts}
      />
    </React.Fragment>
  );
}
