import React, { useState } from 'react'
import { Col, Row, Container, Form as Formm } from 'react-bootstrap'
import { Input, Form, Button } from '../../../components'
import { axios } from '../../../boot'
import { convertPhone, toast } from '../../../methods'
const AdminUser = () => {
    const [phone, setPhone] = useState("")
    const [data, setData] = useState({})
    const searchHandler = () => {
        let convert = convertPhone(phone)

        const url = `/users/${convert?.slice(1)}/getUserByPhone`
        axios.get(url).then(({ data }) => {
            console.log(data)
            setData(data)
        })
    }
    const formControls = [
        {
            label: "شماره تماس",
            state: "phone",
            append: <button className="btn btn-black rounded-0" onClick={() => searchHandler()}><i className="bi bi-search"></i></button>
        }
    ]

    const adminData = [
        {
            pr: 0,
            label: "گزارش گیری خرید کاربران",
            value: false
        },
        {
            pr: 1,
            label: "مدیریت محصولات (ویندوز ترید)",
            value: false
        },
        {
            pr: 2,
            label: "سرویس‌های اختصاص داده نشده",
            value: false
        },
        {
            pr: 3,
            label: "IPهای آزاد",
            value: false
        },
        {
            pr: 4,
            label: "ثبت دستی خرید",
            value: false
        },
        {
            pr: 5,
            label: "خریدهای تتری",
            value: false
        },
        {
            pr: 6,
            label: "کاربران",
            value: false
        },
        {
            pr: 7,
            label: "پلن ها",
            value: false
        },
        {
            pr: 8,
            label: "پرداخت ها",
            value: false
        },
        {
            pr: 9,
            label: "کانال ها",
            value: false
        },
        {
            pr: 10,
            label: "سیگنال ها",
            value: false
        },
        {
            pr: 11,
            label: "کدتخفیف",
            value: false
        },
        {
            pr: 12,
            label: "اخبارها",
            value: false
        },
        {
            pr: 13,
            label: "آموزش ها",
            value: false
        },
        {
            pr: 14,
            label: "دسته بندی آموزش",
            value: false
        },
        {
            pr: 15,
            label: "دسته بندی اخبار",
            value: false
        },
        {
            pr: 16,
            label: "کانال خصوصی",
            value: false
        },
        {
            pr: 17,
            label: "ارسال پیامک منتور",
            value: false
        },
    ]
    const handleData = (id) => {
        let test = data
        test.priviliages[id] = !test?.priviliages[id]
        setData(test)
    }
    const submit = () => {
        const url = `/authentication/${data?._id}/addAdmin`
        let test = data?.priviliages
        if (test) {
            for (let i = 0; i < adminData.length; i++) {
                if (test[i]) {
                    test[i] = test[i]
                } else {
                    test[i] = false
                }
            }
        } else {
            test = []
        }
        const body = {}
        body.data = data?.priviliages
        axios.patch(url,body).then(({data})=>{
            let text = "دسترسی های موردنظر به کاربر داده شد"
            toast({text})
            setData({})
            setPhone("")
        })
    }
    return (
        <Container>
            <Row>
                {formControls.map((item, index) => (
                    <Col
                        key={index}
                        xs="12"
                        md="6"
                        lg="4"
                    >
                        {React.createElement(item.tag ?? Input, {
                            value: phone,
                            setValue: (val) =>
                                setPhone(val),
                            ...item,
                        })}
                    </Col>
                ))}
            </Row>
            {Object.keys(data).length > 0 &&
                <Row className="my-4">
                    <Col className="d-flex flex-center my-4" xs="12">
                        <div className="">
                            <p className="fs-20 fw-700 text-black text-center">نام و نام خانوادگی کاربر </p>
                            <p className="fs-18 fw-500 text-black text-center my-2">{data?.firstName} {data?.lastName}</p>
                        </div>
                    </Col>
                    <Form onSubmit={submit} className="row">
                        {adminData?.map((e) =>
                            <Col key={e.pr} xs="12" md="6" lg="4">
                                <Formm.Check
                                    label={e.label}
                                    name={e.label}
                                    id={e.pr}
                                    onChange={(i) => { }}
                                    onClick={() => handleData(e.pr)}
                                    type="checkbox"
                                    value={data?.priviliages ? data?.priviliages[e.pr] : e.value}
                                    defaultChecked={data?.priviliages && data?.priviliages[e.pr]}
                                />
                            </Col>
                        )}
                        <Col className="d-flex flex-center" xs="12">
                            <Button type='submit' className='text-white' variant="success">ثبت تغییرات</Button>
                        </Col>
                    </Form>
                </Row>
            }
        </Container>
    )
}

export default AdminUser