import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { Button, Select, Input, InputGroup , Form } from "../../../components";
import { serviceStatus } from "../../../constants";
import { rules, toast } from "../../../methods";
import { axios } from "../../../boot";
import { moment } from '../../../boot'

export default function ManualBuy() {
  const [data, setData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [products, setProducts] = useState([]);

  const number = `+98${phoneNumber.substring(1)}`;

  const formControls = [
    // {
    //   label: "کاربر",
    //   state: "userId",
    //   readOnly: true,
    // },
    {
      label: "نام",
      state: "firstName",
      readOnly: true,
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
      readOnly: true,
    },
    {
      tag: Select,
      label: "محصول",
      state: "productId",
      items: products,
    },
    {
      label: "قیمت",
      state: "price",
      readOnly: true,
    },
    {
      label: "تاریخ شروع",
      state: "startDate",
      type : "date",
      cancelBtn : true,
      placeholder : ""
    },
    {
      label: "تاریخ پایان",
      state: "endDate",
      type : "date",
      cancelBtn : true,
      placeholder : ""
    },
    {
      label: "IP",
      state: "ip",
      rules: rules.ip,
    },
    {
      label: "نام کاربری",
      state: "username",
      placeholder : ""
    },
    {
      label: "کلمه عبور",
      state: "password",
      placeholder : ""
    },
    {
      tag: Select,
      label: "وضعیت",
      state: "status",
      items: serviceStatus,
    },
  ].map((props) => ({ rules: rules.required, ...props }));
  const searchPhoneNumber = () => {
    const url = `/admins/users/phone/${number}`;
    axios.get(url).then(({ data }) => {
      const { firstName, lastName, _id } = data;
      setData((p) => ({ ...p, firstName, lastName, userId: _id }));
    });
  };
  const getProducts = () => {
    if (data.userId && !products.length) {
      const url = "/pub/products";
      axios.get(url).then(({ data }) => {
        const products = data.map((p) => ({
          name: p.name,
          price: p.price,
          id: p._id,
        }));
        setProducts(products);
      });
    }
  };
  const handleChangeProduct = () => {
    const product = products.find((e) => e.id === data.productId);
    const price = (product?.price ?? 0).toLocaleString();
    setData((p) => ({ ...p, price }));
  };
  
  const submit = () => {
    const url = "/admins/services";
    const body = cloneDeep(data);

    const startDate = moment.miladiToShamsi({
      date: data && data?.startDate[0],
      format: "jYYYY/jMM/jDD",
    })?.split("/").reverse().join("/")

    const endDate = moment.miladiToShamsi({
      date: data && data?.endDate[0],
      format: "jYYYY/jMM/jDD",
    })?.split("/").reverse().join("/")

    body.phone = number;
    body.price = body.price.replace(/,/g, "");
    body.price = Number(body.price);
    body.startDate = startDate
    body.endDate = endDate
    
    axios.post(url, body).then(() => {
      const text = "عملیات با موفقیت انجام شد.";
      toast({ text });
    });
  };
  useEffect(getProducts, [products, data.userId]);
  useEffect(handleChangeProduct, [data.productId]);
  return (
    <Container className="d-flex flex-column row-gap-4">
      <h1 className="text-center text-info h3">ثبت دستی خرید</h1>
      <Form onSubmit={searchPhoneNumber} className="w-100 row">
        <Col xs="12" md="6" lg="4">
          <InputGroup
            label="شماره کاربر"
            value={phoneNumber}
            setValue={setPhoneNumber}
            rules={rules.phoneNumber}
          />
        </Col>
        <Col xs="12" className="d-flex flex-center">
          <Button className="text-white" type="submit">
            جستجو
          </Button>
        </Col>
      </Form>
      {data.userId && (
        <Form onSubmit={submit} className="row w-100">
          {formControls.map((item, index) => (
            <Col key={index} xs="12" md="6" lg="4">
              {React.createElement(item.tag ?? Input, {
                ...item,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
              })}
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center">
            <Button className="text-white" type="submit">
              ثبت
            </Button>
          </Col>
        </Form>
      )}
    </Container>
  );
}
