import { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { PlanCard } from '../../../components'
import { axios } from '../../../boot'
import Context from './_context'
import './index.scss'
import Plan1 from '../../../assets/images/home/plan1.png'
import Plan2 from '../../../assets/images/home/plan2.png'
import Plan3 from '../../../assets/images/home/plan3.png'
import Plan4 from '../../../assets/images/home/plan4.png'
import { motion } from 'framer-motion'

const ChoosePlan = () => {
    const [plans, setPlans] = useState([]);
    const [page, setPage , planDetails , setPlanDetails] = useContext(Context);
    const getPlans = () => {
        const url = "/plans/getPlans";
        axios.get(url).then(({ data }) => {
            // console.log(data.data)
            const res = data?.data?.filter(e => e.sell !== false && e.active && e._id !== "65396705333123113d85ac55" )
            setPlans(res);
        });
    };
    const handleImage = () => {
        const a = Math.floor(Math.random() * (4 - 1 + 1)) + 1;

        if (a== 1) {
            return Plan1
        } else if (a == 2) {
            return Plan2
        } else if (a == 3) {
            return Plan4
        }else {
            return Plan3
        }
    }
    useEffect(getPlans, []);
    return (
        <div  className='w-100 CHOOSE-PLAN'>
            <Row className="w-100 my-4">
                <p className='fs-20 fw-700 text-black my-4'>یک گزینه را انتخاب کنید</p>
                {plans.map((plan, index) => (
                    <Col
                        key={plan._id}
                        xs="12"
                        md="6"
                        lg="4"
                        xxl="3"
                        className="px-2 px-lg-1"
                    >
                        {/* <PlanCard plan={plan} id={index} bg="harp" link={false} /> */}
                        <motion.div whileHover={{scale : 1.02}} whileTap={{scale : 0.9}} onClick={()=>{setPage("condition");setPlanDetails({...plan})}} className="PlanCard d-flex flex-column justify-content-between position-relative cursor-pointer">
                            <div className={`planCard-img bg-harp`}>
                                <img className='' width={118} height={146} src={handleImage(index)} loading='lazy' alt='plan picture' />
                            </div>
                            <div className="">
                                <p className=" text-truncate w-100 my-4 fs-18 fw-700 text-black">{plan.name}</p>
                                <p className='text-primary fw-900 fs-30 my-2'>{` ${plan.price} تتر`}</p>
                                <p className='text-primary fw-900 fs-25 my-2'>{` ${ plan?.toomanAmount ?  Number(plan?.toomanAmount)?.toLocaleString() : 0} تومان`}</p>
                                <p className="my-2 text-black fs-16 fw-400 my-2">{plan.duration ? `${plan.duration} ماهه` : "1 ماهه"}</p>
                                <p className='fw-700 fs-16'>{plan.channels?.length} <span className="">کانال</span></p>
                            </div>
                            <div className="bottom-0 left-0 right-0">

                                <button
                                    onClick={() => {setPage("condition");setPlanDetails({...plan})}}
                                    className="btn btn-black rounded-0 w-100 text-white fs-14 fw-500"
                                >
                                    خرید پلن
                                </button>
                            </div>
                        </motion.div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default ChoosePlan