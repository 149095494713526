import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Select, Modal, Input, MultiSelect } from "../../../../components";
import { axios } from "../../../../boot";
import { rules, toast } from "../../../../methods";
import { useGetChannels } from "../../../../hooks";
import { trueAndFalse } from "../../../../constants";

function Discount() {
    const { id } = useParams();
    const isNew = id === "new"
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const getData = () => {
        if (!isNew) {
            const url = `/discounts/${id}/getDiscountById`;
            axios.get(url).then(({ data }) => {
                setData(data);
            });
        }
    };
    const addDiscount = () => {
        const url = "/discounts/addDiscount"
        axios.post(url, data).then(({ data }) => {
            let text = "کد تخفیف جدید با موفقیت افزوده شد"
            toast({ text })
            navigate(-1)
        })
    }
    const updateDiscount = () => {
        const url = `/discounts/${id}/editDiscount`
        axios.patch(url, data).then(({ data }) => {
            let text = "کد تخفیف با موفقیت ویرایش شد"
            toast({ text });
            navigate(-1);
        })
    }
    const fromControls = [
        {
            tag: Select,
            state: "active",
            label: "وضعیت",
            items : trueAndFalse
        },
        {
            state: "description",
            label: "توضیحات",
        },
        {
            state: "amount",
            label: "مقدار",
        },
        {
            state: "code",
            label: "کد تخفیف",
        },
        {
            state: "ExpireDate",
            label: "تاریخ انقضاء",
            type : "date"
        }
    ]
    const deleteDiscount = () => {
        const url = `/discounts/${id}/deleteDiscount`
        axios.delete(url).then(() => {
            let text = "کد تخفیف با موفقیت حذف شد"
            toast({ text });
            navigate(-1);
        });
    }
    useEffect(getData, []);
    return (
        <Container className="Product">
            <Form onSubmit={isNew ? addDiscount : updateDiscount}>
                <Row>

                    {fromControls.map((item, index) => (
                        <Col
                            key={index}
                            xs="12"
                            md="6"
                            lg="4"
                        >
                            {React.createElement(item.tag ?? Input, {
                                rules: rules.required,
                                value: data[item.state],
                                setValue: (val) =>
                                    setData((p) => ({ ...p, [item.state]: val })),
                                ...item,
                            })}
                        </Col>
                    ))}
                    <Col xs="12" className="d-flex flex-center">
                        <Button type="submit" variant="primary" className="text-light">
                            {isNew ? " ثبت تخفیف جدید" : "ثبت تغییرات"}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {!isNew &&
                <div className="d-flex flex-center flex-wrap my-4">
                    <Button onClick={() => deleteDiscount()} variant="danger text-white" className="m-2 ">حذف تخفیف</Button>
                </div>
            }
            {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
        </Container>
    );
}

export default Discount;