import React, { useState } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import personIcon from "../../../assets/icons/weblog/person.svg";
import { Button, Form, Input } from "../../../components";
import { rules, toast } from "../../../methods";
import { axios } from "../../../boot";
import { articles } from "../../../constants";
import "./index.scss";
export default function Article() {
  const params = useParams();
  const article = articles.find((e) => `${e._id}` === params.id);
  const [data, setData] = useState({});
  // const [article, setArticle] = useState({});
  // const getArticle = () => {
  //   const url = `/pub/blog/${params.id}`;
  //   axios.get(url).then(({ data }) => {
  //     setArticle(data);
  //   });
  // };
  const submitComment = () => {
    const url = "/pub/blog/add-comment";
    const body = {
      blogId: params.id,
      ...data,
    };
    if (!("name" in body)) body.name = "";
    axios.post(url, body).then(() => {
      const text = "دیدگاه شما با موفقیت ارسال شد.";
      toast({ text });
    });
  };
  const formControl = [
    {
      label: "نام",
      state: "name",
      rules: [],
    },
    {
      label: "ایمیل",
      state: "email",
      rules: rules.email,
    },
    {
      label: "دیدگاه",
      state: "comment",
      rules: rules.required,
      as: "textarea",
    },
  ];
  // useEffect(getArticle, []);
  return (
    <div className="Article py-5">
      <Container>
        <div className="d-flex flex-column flex-center row-gap-4 border border-secondary rounded-3 p-3 px-lg-5">
          <h3 className="text-info">{article.title}</h3>
          <div className="d-flex flex-center col-gap-3 ms-auto">
            <img width="110" height="110" src={personIcon} alt="personIcon" />
            <div className="d-flex flex-column">
              <span className="text-dark h6 mb-3">
                نام نویسنده:{""}
                <span className="text-secondary">{article.author}</span>
              </span>
              <span className="text-dark">
                تاریخ مقاله:{" "}
                <span className="text-secondary">{article.createdAt}</span>
              </span>
              {/* <p>زمان مقاله</p> */}
              {/* <span className="text-dark">
                تعداد دیدگاه:{" "}
                <span className="text-secondary">
                  {article.comments?.length}
                </span>
              </span> */}
            </div>
          </div>
          {/* <img
            width="300"
            className="mw-100"
            src={srcFile(article.img)}
            alt={article.title}
          /> */}
          {/* <div dangerouslySetInnerHTML={{ __html: article.full }} /> */}
          {/* <p className="fs-5">{article.full}</p> */}
          {article.full.map((item, index) => (
            <React.Fragment key={index}>
              {"img" in item && (
                <img
                  width="350"
                  className="mw-100"
                  src={item.img}
                  alt={item.title}
                />
              )}
              {"title" in item && (
                <h4 className="lh-normal mb-0">{item.title}</h4>
              )}
              {"text" in item && (
                <p className="white-space-pre-wrap text-justify w-100 overflow-wrap-break-word">
                  {item.text}
                </p>
              )}
            </React.Fragment>
          ))}
        </div>
        <Row className="mt-5 d-none">
          <Col xs="12" md="10" lg="8">
            <Form
              onSubmit={submitComment}
              className="w-100 d-flex flex-column flex-center text-center row-gap-3"
            >
              <h4 className="text-info">دیدگاهتان را درمورد این پست بنویسید</h4>
              <p className="text-dark-gray">
                *نشانی ایمیل شما منتشر نخواهد شد. بخش‌های موردنیاز علامت‌گذاری
                شده‌اند
              </p>
              {formControl.map((item, index) => (
                <Input
                  key={index}
                  value={data[item.state]}
                  setValue={(val) =>
                    setData((p) => ({ ...p, [item.state]: val }))
                  }
                  {...item}
                />
              ))}
              <Button type="submit" size="lg" className="text-light w-100">
                ثبت دیدگاه
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
