import { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Table, Button, Pagination } from '../../../../components'
import { axios, moment } from '../../../../boot'
import { useNavigate } from 'react-router'
import { purchaseStatus } from '../../../../constants'
import { convertPhone } from '../../../../methods'
const Plans = () => {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [plans, setPlans] = useState([])
  const [ pages , setPages ] = useState({})
  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getPlans = () => {
    const url = "/plans/getPlans"
    saveParams();
    const config = {
      params: {
        perPage: 20,
        ...params,
      },
    };
    axios.get(url,config).then(({ data }) => {
      setPlans(data.data)
      setPages(data?.pages)
    })
  }
  useEffect(getPlans, [params])
  return (
    <Container className="">
      <Row className="w-100">
        <Col xs="12" md="12">
          <Table className="text-center">
            <thead>
              <tr>
                <th className="">عنوان</th>
                <th className="">کانال ها</th>
                <th className="">کیف پول</th>
                <th className="">قیمت</th>
                
              </tr>
            </thead>
            <tbody>
              {plans?.map((e) => (
                <tr key={e._id} onClick={() => navigate(e._id)}>
                  <td>{e.name}</td>
                  <td>{e?.channels?.length}</td>
                  <td>{e.wallet}</td>
                  <td>{e.price} - {`تومان ${e.toomanAmount ? Number(e.toomanAmount)?.toLocaleString() : 0} `}</td>                  
                </tr>
              ))}
              {/* <tr>
                <td colSpan="4" className="h5 py-3 text-danger">
                  <i className="bi bi-info fs-4" />
                  سفارشی موجود نیست
                </td>
              </tr> */}
              <tr>
                <td colSpan="8">
                  <Button
                    onClick={() => navigate("new")}
                    variant="success"
                    className="text-light"
                  >
                    اضافه کردن پلن جدید
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Pagination  
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
    </Container>
  )
}

export default Plans