import React, { useState } from 'react'
import { useNavigate } from "react-router";
import { Form, InputGroup, Button, Input } from "../../../components";
import { convertPhone, rules, toast } from "../../../methods";
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { axios } from '../../../boot';
import './index.scss'

const ResetPassword = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({})
  const [activePage, setActivePage] = useState("phone")
  const phoneControl = [
    {
      label: "شماره تلفن یا ایمیل",
      state: "phone"
    }
  ]
  const passwordControl = [
    {
      label: "کد تایید",
      state: "code"
    },
    {
      label: "رمزعبور جدید",
      state: "password",
      type: "password"
    },
    {
      label: "تکرار رمزعبور جدید",
      state: "repeatPass",
      type: "password"
    },
  ]
  const formControls = {
    phone: phoneControl,
    pass: passwordControl
  }
  const sendCode = () => {
    let url = ""
    let text = ""
    let reg = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    if (reg.test(data.phone)) {
      url = `/authentication/${data.phone}/resetPasswordEmail`
      text = `کد به ایمیل ${data?.phone} ارسال شد`
    } else {
      url = `/authentication/${convertPhone(data.phone)}/resetPasswordSms`
      text = `کد به شماره ${data?.phone} ارسال شد`
    }
    axios.get(url).then(() => {
      toast({ text })
      setActivePage("pass")
    })
  }
  const changePass = () => {
    let body = {}
    let reg = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    if(reg.test(data.phone)) {
        body.phone = data?.phone
        body.password = data?.password
    }else {
        body.phone = convertPhone(data?.phone)
        body.password = data?.password
    }
    const url = `/authentication/${data.code}/changePassword`
    if (data?.password != data?.repeatPass) {
      let text = "رمزعبور را درست وارد کنید"
      return toast({ type: "error", text })
    }

    axios.post(url, body).then(({ data }) => {
      console.log(data)
      let text = "رمز عبور با موفقیت تغییر کرد"
      toast({ text })
      navigate('/login')

    })
  }
  return (
    <div className="ResetPass">
      <div className="d-flex align-items-center justify-content-between">
        <p className="fs-30 fw-700 text-black mx-2">تغییر رمزعبور</p>
        <Link to="/"><i class="bi bi-arrow-left-short fs-25 fw-700"></i></Link>
      </div>
      <Form
        onSubmit={activePage === "phone" ? sendCode : changePass}
        className=" d-flex row  flex-center text-center row-gap-3 py-4"
      >
        {formControls[activePage].map(item =>
          <Col key={item.state} xs="12" lg="12">
            {React.createElement(item.tag ?? Input, {
              ...item,
              value: data[item.state],
              setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
              className: "border"
            })}
          </Col>
        )}
        <Col>
          <Button variant="black" className="w-100 mt-4 rounded-0 py-2" type="submit">
            {activePage === "phone" ? "ارسال کد" : "تغییر رمز عبور"}
          </Button>
        </Col>


        <Link className="fs-16 fw-700 text-black" to="/login">بازگشت به صفحه ورود</Link>
      </Form>
    </div>
  )
}

export default ResetPassword