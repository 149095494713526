import React, { useState, useEffect } from 'react'
import { axios, moment } from "../../../boot";
import { serverStatus } from "../../../constants";
import { Row, Col } from 'react-bootstrap'
import { Button, Modal, ChooseVps } from '../../../components';
import './index.scss'
import { motion } from 'framer-motion';
const ShowPlan = ({ setActivePage = () => { }, onShow = () => { } }) => {
    const [orders, setOrders] = useState([]);
    const [show, setShow] = useState(false)
    const getOrders = () => {
        const url = "/services";
        axios.get(url).then(({ data }) => {
            const res = data?.data?.map(e => ({ ...e, username: e.defaultLogin?.split(" ")[1], password: e.defaultLogin?.split(" ").at(-1) }))?.
                filter(item => item?.status == "active")

            setOrders(res);
        });
    };
    const serverInfo = (data = {}) => [
        {
            label: "نام کاربری",
            value: data?.username,
        },
        {
            label: "رمزعبور",
            value: data?.password,
        },
        {
            label: "RAM",
            value: data?.product?.ram,
        },
        {
            label: "CPU CORE",
            value: data?.product?.cpu,
        },
        {
            label: "پهنای باند",
            value: data?.product?.bandwidth,
        },
        {
            label: "حافظه ذخیره سازی",
            value: data?.product?.storage,
        },
        {
            label: "محل سرور",
            value: data?.product?.location,
        },
        {
            label: "تعداد",
            value: data?.product?.count,
        },
        {
            label: "قیمت(تومان)",
            value: Number(data?.product?.price).toLocaleString(),
        },
        {
            label: "قیمت(USD)",
            value: data["priceUsd"] ?? "-",
        },
    ];
    useEffect(getOrders, []);
    return (
        <div className="showPLAN">
            <Row className="w-100">

                <div className='border border-2 border-black bg-harp p-4' >
                    {orders?.length > 0 && [...Array(orders?.at(0))]?.map((order, index) =>
                        
                        <Col xs="12" md="6" lg="12" xl="12" key={index}>
                            <div className="w-100 ">
                                <div className="w-100 d-flex justify-content-between  text-center col-gap-3 fs-5 py-3">
                                    <p className="text-black fs-24 fw-700">VPS:</p>
                                    <span>{serverStatus.badge(order.status)}</span>
                                    {/* <p className="text-black fs-24 fw-700">سالانه {item.name}</p> */}
                                </div>
                                <div className="row w-100 py-3 text-secondary">
                                    {serverInfo(order).map((data, index) => (
                                        <React.Fragment key={index}>
                                            <div className="col-6 fs-18 fw-400 text-black">{data.label}</div>
                                            <div dir="ltr" className="col-6 font-en text-black text-start">
                                                {data.value}
                                            </div>
                                        </React.Fragment>
                                    ))}

                                    <div className="col-6 text-primary mt-4">قیمت کل</div>
                                    <div className="col-6  mt-4 d-flex justify-content-end">
                                        <p className="text-primary font-en">{order?.product.price.toLocaleString()} تومان</p>
                                    </div>
                                    <div className="row ">
                                        <Col xs="11" md="11" lg="12" xl="12" className="p-0"><motion.button style={{ height: "55px" }} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} onClick={() => { setActivePage("ServerBill"); onShow(true) }} variant='black' className='w-100 btn btn-black rounded-0  fs-16 ' >مشاهده صورتحساب‌ها قبلی</motion.button></Col>
                                        <Col xs="11" md="11" lg="12" xl="12" className="p-0"><motion.button style={{ height: "55px" }} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} onClick={() => { setActivePage("chooseVps"); onShow(true) }} variant='primary' className='w-100 btn btn-black rounded-0  fs-16' >خرید پلن VPS</motion.button></Col>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    )}
                    {
                        !orders?.length &&
                        <div className="">
                            <div className="w-100 col-gap-3 fs-5 py-2">
                                <p className="text-black text-start fs-24 fw-700 ">VPS:</p>

                            </div>
                            <p className="fs-18 fw-400 text-black my-3">در حال حاضر برای شما فعال نمی‌باشد</p>
                            <Button onClick={() => { setActivePage("chooseVps"); onShow(true) }} label='خرید پلن VPS' variant='black' className='w-100 rounded-0 py-3' />
                        </div>
                    }
                </div>


            </Row>
        </div>
    )
}

export default ShowPlan