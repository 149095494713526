import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { axios } from "../../../boot";
import { rules, toast } from "../../../methods";
import {
  Select,
  InputGroup,
  Modal,
  Form,
  Button,
  Accordion,
} from "../../../components";
export default function FreeServices() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);
  const [ipPool, setIpPool] = useState([]);
  const getProducts = () => {
    const url = "/pub/products";
    axios.get(url).then((response) => {
      const data = response.data.map((item) => ({
        name: item.name,
        id: item._id,
      }));
      setProducts(data);
    });
  };
  const getIpPool = () => {
    const url = "/admins/pool";
    axios.get(url).then(({ data }) => {
      setIpPool(data.data);
    });
  };
  const filterIpPool = () => {
    const data = cloneDeep(ipPool).map((item) => item.product.name);
    const planNames = [...new Set(data)];
    const value = planNames.map((item) => {
      return {
        title: item,
        data: ipPool.filter((e) => e.product.name === item),
      };
    });
    return value;
  };
  const addIp = () => {
    const url = "/admins/pool";
    const body = data;
    axios.post(url, body).then(() => {
      getIpPool();
      setShowModal(false);
    });
  };
  const deleteIp = (id = "") => {
    const url = "/admins/pool";
    const body = {
      data: {
        _id: id,
      },
    };
    axios.delete(url, body).then(() => {
      const text = "عملیات با موفقیت انجام شد.";
      toast({ text });
      const prev = cloneDeep(ipPool);
      const index = prev.findIndex((e) => e._id === id);
      prev.splice(index, 1);
      setIpPool(prev);
    });
  };
  const formControls = [
    {
      tag: Select,
      label: "پلن",
      state: "productId",
      items: products,
      rules: rules.required,
    },
    {
      tag: InputGroup,
      label: "(IP)",
      state: "ip",
      dir: "ltr",
      rules: rules.ip,
    },
    {
      tag: InputGroup,
      label: "نام کاربری",
      state: "username",
      dir: "ltr",
      rules: rules.required,
    },
    {
      tag: InputGroup,
      label: "کلمه عبور",
      state: "password",
      rules: rules.required,
    },
  ];
  useEffect(getIpPool, []);
  useEffect(getProducts, []);
  return (
    <Container>
      <Row className="w-100">
        <Col xs="12" className="d-flex flex-column row-gap-1">
          {filterIpPool().map((item, index) => (
            <Accordion key={index} title={item.title}>
              <div className="d-flex flex-column">
                {item.data.map((item, index) => (
                  <Row
                    key={index}
                    className="w-100 fs-5 shadow-sm py-3 my-1 rounded bg-white bg-opacity-10"
                  >
                    <Col xs="12" md="3">
                      (IP): {item.ip}
                    </Col>
                    <Col xs="12" md="4">
                      نام کاربری: {item.username}
                    </Col>
                    <Col xs="12" md="4">
                      کلمه عبور: {item.password}
                    </Col>
                    <Col xs="12" md="1">
                      <i
                        className="btn btn-sm btn-outline-danger bi bi-x-lg"
                        onClick={() => deleteIp(item._id)}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </Accordion>
          ))}
          {!ipPool.length && (
            <p className="text-center text-danger h4">
              در حال حاضر IP آزاد وجود ندارد.
            </p>
          )}
        </Col>
        <Col xs="12" className="d-flex flex-center">
          <Button onClick={() => setShowModal(true)} className="text-light">
            اضافه کردن IP جدید
          </Button>
        </Col>
      </Row>
      <Modal show={showModal} onHide={setShowModal} title="اضافه کردن IP جدید">
        <Form className="row" onSubmit={addIp}>
          {formControls.map((item, index) => (
            <Col key={index} xs="12">
              {React.createElement(item.tag, {
                ...item,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
              })}
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="success" className="text-light">
              ثبت
            </Button>
          </Col>
        </Form>
      </Modal>
    </Container>
  );
}
