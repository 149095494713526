import { useState, useRef, useContext } from 'react'
import { axios } from '../../../boot'
import { Form, Input } from '../../../components'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Context from './_context'
import { calcDiscount, toast } from '../../../methods'
import { motion } from 'framer-motion'
import { rules } from '../../../constants'
import { useNavigate } from 'react-router'
import { QRCodeSVG } from "qrcode.react";

const UsdPayment = () => {
    const navigate = useNavigate()
    const inputFileRef = useRef()
    const [data, setData] = useState({ useReferral: false })
    const [ discount , setDiscount ] = useState(false)
    const [ price , setPrice ] = useState(0)
    const [page, setPage, planDetails, setPlanDetails] = useContext(Context)
    const dispatch = useDispatch()
    // const handleTextId = () => {
    //     const url = "/payments/verifyzarinPayment"
    //     const body = { authority: data?.textId }
    //     axios.post(url, body).then(({ data }) => {
    //         console.log(data)
    //     })
    // }
    const uploadImage = async (e) => {
        dispatch({ type: "SHOW_LOADING" });
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const url = "/payments/addPaymentPhoto"
        await axios.post(url, formData).then(({ data }) => {
            let text = "آپلود عکس موفقیت آمیز بود"
            toast({ text })
            setData(p => ({ ...p, "image": data.message }))
            dispatch({ type: "HIDE_LOADING" });
        })
        dispatch({ type: "HIDE_LOADING" });
    }
    const handleReferralCode = () => {
        const url = `/users/RegisterReferalCode`
        const body = { ReferralCode: data?.ReferralCode }
        axios.post(url, body).then(({ data }) => {
            toast({ text: data?.message })
            setData(p => ({ ...p, "useReferral": true }))
        })
    }
    const handlediscountCode = () => {
        const url = `/discounts/${data?.discountCode}/getDiscount`

        axios.get(url).then(({ data }) => {
            let finalPrice = calcDiscount(planDetails?.price , data?.amount)
            setPrice(planDetails?.price)
            let text = `کد تخفیف قابل قبول است. شما دارای ${data?.amount} درصد تخفیف هستین`
            setPlanDetails(p => ({...p , price : finalPrice}))
            toast({ text })
            setDiscount(p => !p)
        })
    }
    const formControl = [

        {
            label: "کد معرف",
            placeholder: "کد معرف (اختیاری)",
            state: "ReferralCode",
            type: "text",
            className: "border",
            append: <button type="button" className='btn btn-black rounded-0' onClick={() => handleReferralCode()}>ثبت</button>,
            rules: rules.optional
        },
        {
            label: "کد تخفیف",
            placeholder: "کد تخفیف (اختیاری)",
            state: "discountCode",
            type: "text",
            className: "border",
            append: <button type="button" disabled={discount} className='btn btn-black rounded-0' onClick={() => handlediscountCode()}>ثبت</button>,
            rules: rules.optional
        },
        {
            label: "تکس آیدی",
            state: "textId",
            type: "text",
            className: "border",
            // append: <button className='btn btn-black rounded-0' onClick={() => handleTextId()}>ثبت</button>,
            rules: rules.textId
        },
        {
            label: "موجودی دلاری",
            state: "inventory",
            className: "border",
            placeholder: "موجودی(دلاری) برای طبقه بندی کلاس‌های آموزشی",
            type: "number",
        },
        // {
        //     label: "شماره تلگرام",
        //     state: "phone",
        //     type: "phone",
        //     className: "border",
        // },
    ]
    const sendData = () => {
        const url = "/payments/addPayment"
        const body = {}
        body.image = data?.image
        body.phone = data?.phone
        body.selectedPlan = planDetails?._id
        body.textId = data?.textId
        body.inventory = data?.inventory
        body.useReferral = data?.useReferral
        if (data?.discountCode) body.discountCode = data?.discountCode
        axios.post(url, body).then(({ data }) => {
            let text = "پرداخت شما با موفقیت ثبت شد. پرداخت شما تا 1 ساعت آینده تایید و اشتراک شما فعال می شود"
            toast({ text, duration: 7000 })
            // navigate(`/receipt?status=OK`)
            setPage("receipt")
        })


    }
    return (
        <div style={{ overflowX: "hidden" }} className='w-100 '>
            <Form className="row " onSubmit={sendData}>
                {formControl.map((item) =>
                    <Col key={item.state} xs="12" lg="6">
                        <Input
                            key={item.state}
                            {...item}
                            value={data[item.state]}
                            setValue={val => setData(p => ({ ...p, [item.state]: val }))}
                        />
                    </Col>


                )}
                <Col className="mb-4" xs="12" lg="12">
                    <p className="text-start my-1 text-black fw-500 fs-16">تصویر پرداخت</p>
                    <motion.button
                        whileHover={{ scale: 0.99 }}
                        type="button"
                        className="btn btn-white rounded-0 w-100  text-black border border-black border-2 fs-5"
                        onClick={() => inputFileRef.current.click()}
                    >ارسال تصویر پرداخت <i className="bi bi-image-fill"></i></motion.button>
                    <input
                        ref={inputFileRef}
                        type="file"
                        className="d-none"
                        onChange={uploadImage}
                        accept=".jpg, .jpeg, .png"
                    />
                </Col>
                <Col className="d-flex align-items-center justify-centent-start justify-content-lg-center my-2" xs="12">
                    <div className="">
                        <QRCodeSVG value={planDetails?.wallet} />
                        <p style={{ wordBreak: "break-all" }} className="text-black  fs-16 fw-700 my-2 fs-18">آدرس ولت : {planDetails?.wallet}</p>
                        <p className="text-black  fs-16 fw-700 my-1 fs-18">مبلغ واریزی : {planDetails?.price} تتر</p>
                    </div>
                </Col>
                <Col xs="12" lg="6">
                    <button onClick={() => { }} className="btn btn-tufts-blue w-100 py-2 rounded-0 fs-14 fw-700 text-white">پرداخت</button>
                </Col>
                <Col xs="12" lg="6">
                    <button onClick={() => { setPage("payment"); setPlanDetails(p => ({...p , price : price})) }} className="btn btn-black w-100 py-2 rounded-0 fs-14 fw-700 text-white">بازگشت</button>
                </Col>
            </Form>
        </div>
    )
}

export default UsdPayment