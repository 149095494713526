import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Select, Modal } from "../../../components";
import { axios } from "../../../boot";
import { rules, srcFile, toast } from "../../../methods";
import { orderStatus } from "../../../constants";
import base64Image from "../../../methods/_base64Image";
export default function UsdOrder() {
  const [show, setShow] = useState(false)
  const fromControls = [
    {
      label: "قیمت(USD)",
      state: "priceUsd",
    },
    {
      label: "TXID",
      state: "txid",
    },
    {
      label: "آدرس کیف پول",
      state: "fromAddress",
    },
    {
      label: "واریز به آدرس کیف پول",
      state: "toAddress",
    },
    {
      label: "توضیحات کاربر",
      state: "message",
    },
    {
      label: "توضیحات",
      state: "reviewMessage",
      readOnly: false,
    },
    {
      tag: Select,
      label: "وضعیت",
      state: "review",
      items: orderStatus.filter((e) => ["accepted", "rejected"].includes(e.id)),
      readOnly: false,
    },
  ].map((e) => ({ readOnly: true, ...e }));
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const getData = () => {
    const url = `/admins/orders/${id}`;
    axios.get(url).then(({ data }) => {
      setData(data);
     
    });
  };
  const updateOrder = () => {
    const url = "/admins/orders/review";
    const body = {
      review: data.review,
      reviewMessage: data.reviewMessage,
      orderId: id,
    };
    axios.post(url, body).then(() => {
      const text = "عملیات موفقیت آمیز بود";
      toast({ text });
      navigate(-1, { replace: true });
    });
  };
  const cols = {
    reviewMessage: 12,
  };
  const base = () => {
    var image = new Image();
    image.src = `data:image/png;base64,${data.image}`
    document.body.appendChild(image);
    return image
  }
  useEffect(getData, []);
  return (
    <Container className="Product">
      <Form onSubmit={updateOrder}>
        <Row>
          {fromControls.map((item, index) => (
            <Col
              key={index}
              xs="12"
              md={cols[item.state] ?? "6"}
              lg={cols[item.state] ?? "4"}
            >
              {React.createElement(item.tag ?? InputGroup, {
                rules: rules.required,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
                ...item,
              })}
            </Col>
          ))}
          {/* <Col
            xs="12"
            md="12"
            lg="6"

          >
            <img onClick={() => setShow(true)} width="150" height="150" src={base()} />
          </Col> */}
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="success" className="text-light">
              ثبت تغییرات
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal>
    </Container>
  );
}
