import React from 'react'
import { VideoPlayer } from '../../../components'
import align from '../../../assets/images/home/spaceship.png'
const SubmitVideo = () => {
    let src = "https://marveltrade.org/howLoginAndBuyMarvel.mp4"
    return (
        <div>
            <VideoPlayer height="100%" type='video/mp4' src={src} title="آموزش ثبت نام و خرید اشتراک در مارول" />
        </div>
    )
}

export default SubmitVideo