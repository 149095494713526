import logo from "../../assets/logo/userLogo.svg";
import useRenderNavItems from "./_useRenderNavLink";
export default function Navbar() {
  const renderNavItems = useRenderNavItems();
  return (
    <nav className="Navbar d-xl-flex align-items-center col-gap-3">
      <div className="avatar">
        <img src={logo} alt="logo"  />
      </div>
      <div className="nav-items d-none d-xl-flex align-items-center col-gap-3">
        {renderNavItems()}
      </div>
    </nav>
  );
}
