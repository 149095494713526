import { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Table, Button, Pagination } from '../../../../components'
import { axios, moment } from '../../../../boot'
import { useNavigate } from 'react-router'
import { purchaseStatus } from '../../../../constants'
import { convertPhone } from '../../../../methods'
const Discounts = () => {
    const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
    const navigate = useNavigate();
    const [params, setParams] = useState({ page: 1, ...basicParams });
    const [discounts, setDiscounts] = useState([])
    const [pages, setPages] = useState({})
    const saveParams = () => {
        const value = JSON.stringify(params);
        sessionStorage.setItem("params", value);
    };
    const getDiscounts = () => {
        const url = "/discounts/getallDiscount"
        saveParams();
        const config = {
            params: {
                perPage: 20,
                ...params,
            },
        };
        axios.get(url).then(({ data }) => {
            setDiscounts(data.data)
            setPages(data?.pages)
        })
    }
    useEffect(getDiscounts, [params])
    return (
        <Container className="">
            <Row className="w-100">
                <Col xs="12" md="12">
                    <Table className="text-center">
                        <thead>
                            <tr>
                                <th className="">توضیحات</th>
                                <th className="">کد</th>
                                <th className="">مقدار</th>
                                <th className="">وضعیت</th>
                                <th className="">تاریخ انقضاء</th>
                            </tr>
                        </thead>
                        <tbody>
                            {discounts?.map((e) => (
                                <tr key={e._id} onClick={() => navigate(e._id)}>
                                    <td>{e.description}</td>
                                    <td>{e?.code}</td>
                                    <td>{e.amount}</td>
                                    <td>{e.active ? <p className='text-success'>فعال</p> : <p className='text-danger'>غیرفعال</p>}</td>
                                    <td>{moment.miladiToShamsi({ date: e.ExpireDate })}</td>
                                </tr>
                            ))}
                            {/* <tr>
                <td colSpan="4" className="h5 py-3 text-danger">
                  <i className="bi bi-info fs-4" />
                  سفارشی موجود نیست
                </td>
              </tr> */}
                            <tr>
                                <td colSpan="8">
                                    <Button
                                        onClick={() => navigate("new")}
                                        variant="success"
                                        className="text-light"
                                    >
                                        اضافه کردن تخفیف جدید
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {/* <Pagination  
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      /> */}
        </Container>
    )
}

export default Discounts