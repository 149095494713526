import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Select, Modal, Input, MultiSelect } from "../../../../components";
import { axios } from "../../../../boot";
import { rules, toast } from "../../../../methods";
import { useGetChannels, useGetNewsCat } from "../../../../hooks";
import { trueAndFalse } from "../../../../constants";

function NewsDetails() {
    const { id } = useParams();
    const isNew = id === "new"
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [newsCat, getNews ] = useGetNewsCat()
    const getData = () => {
        if (!isNew) {
            const url = `/admins/news/${id}`;
            axios.get(url).then(({ data }) => {
                console.log(data)
                setData(data);
            });
        }
    };
    const files = ["image"];
    const addNews = () => {
        const url = "/admins/news"
        const body = new FormData();
        Object.keys(data).forEach((key) => {
            const isFile = files.includes(key);
            body.append(key, isFile ? data[key][0] : data[key]);
        });
        axios.post(url, body).then(({ data }) => {
            let text = " خبر جدید با موفقیت افزوده شد"
            toast({ text })
            navigate(-1)
        })
    }
    const updateNews = () => {
        const url = `/admins/${id}/news`
        axios.patch(url, data).then(({ data }) => {
            let text = " خبر با موفقیت ویرایش شد"
            toast({ text });
            navigate(-1);
        })
    }
    const fromControls = [
        {
            state: "title",
            label: "عنوان",
        },
        {
            state: "image",
            label: "عکس",
            type: "file"
        },
        {
            tag : Select,
            state: "subCategory",
            label: "زیردسته بندی",
            items : newsCat?.map((e) => ({name : e.name , id : e._id}))
        },
        {
            as: "textarea",
            state: "text",
            label: "متن خبر",
        },
    ]
    const deleteNews = () => {
        const url = `/admins/${id}/news`
        axios.delete(url).then(() => {
            let text = " خبر با موفقیت حذف شد"
            toast({ text });
            navigate(-1);
        });
    }
    useEffect(getNews,[])
    useEffect(getData, []);
    return (
        <Container className="Product">
            <Form onSubmit={isNew ? addNews : updateNews}>
                <Row>

                    {fromControls.map((item, index) => (
                        <Col
                            key={index}
                            xs="12"
                            md={index === 3 ? "12" : "6"}
                            lg={index === 3 ? "12" : "4"}
                        >
                            {React.createElement(item.tag ?? Input, {
                                rules: rules.required,
                                value: data[item.state],
                                setValue: (val) =>
                                    setData((p) => ({ ...p, [item.state]: val })),
                                ...item,
                            })}
                        </Col>
                    ))}
                    <Col xs="12" className="d-flex flex-center">
                        <Button type="submit" variant="primary" className="text-light">
                            {isNew ? " ثبت خبر جدید" : "ثبت تغییرات"}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {!isNew &&
                <div className="d-flex flex-center flex-wrap my-4">
                    <Button onClick={() => deleteNews()} variant="danger text-white" className="m-2 ">حذف خبر</Button>
                </div>
            }
            {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
        </Container>
    );
}

export default NewsDetails;