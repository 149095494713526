import { useContext } from 'react'
import './index.scss'

import Plan1 from '../../assets/images/home/plan1.png'
import Plan2 from '../../assets/images/home/plan2.png'
import Plan4 from '../../assets/images/home/plan4.png'
import Plan3 from '../../assets/images/home/plan3.png'
import Context from '../../pages/UserLayout/Profile/_context'
import {motion} from "framer-motion"
import { Link } from 'react-router-dom'

const PlanCard = ({
    plan = {},
    id = 0,
}) => {


    const handleImage = () => {
        const a = Math.floor(Math.random() * (4 - 1 + 1)) + 1;

        if (a== 1) {
            return Plan1
        } else if (a == 2) {
            return Plan2
        } else if (a == 3) {
            return Plan4
        }else {
            return Plan3
        }
    }

    return (
        <motion.div whileHover={{scale : 1.02}} className="PlanCard d-flex flex-column justify-content-between position-relative">
            <div className={`planCard-img w-100 d-flex flex-center my-2`}>
                <img className='' width={118} height={146} src={handleImage()} loading='lazy' alt='plan picture' />
            </div>
            <div className="">
                <p className=" text-truncate w-100 my-4 fs-18 fw-700 text-black">{plan.name}</p>
                <p className='text-primary fw-900 fs-30 my-2'>{` ${plan.price} تتر`}</p>
                <p className='text-primary fw-900 fs-25 my-2'>{` ${ plan?.toomanAmount ? Number(plan?.toomanAmount)?.toLocaleString() : 0} تومان`}</p>
                <p className="my-2 text-black fs-16 fw-400 my-2">{plan.duration ? `${plan.duration} ماهه` : "1 ماهه"}</p>
                <p className='fw-700 fs-16'>{plan.channels?.length} <span className="">کانال</span></p>
            </div>
            <div className="postion-relative bottom-0 left-0 right-0">
                <Link
                    to="profile"
                    className="btn btn-black rounded-0 w-100 text-white fs-14 fw-500"
                >
                    خرید پلن
                </Link>
            </div>
        </motion.div>
    )
}

export default PlanCard