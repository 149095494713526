import React from 'react'
import { Row, Col  } from 'react-bootstrap'
import { Accordion } from '../../../components'
import { questions } from "../../../constants";


const Qa = () => {
    return (
        <div className="w-100">
            <p className="fs-30 fw-700 text-black m-4">پر تکرارترین پرسش‌ها</p>
            <Row className="questions-section row-gap-3 my-4">
                {questions.map((item, index) => (
                    <Col xs="12" lg="6">
                        <Accordion key={index} variant="white" color='black' className="text-black w-100" title={item.label}>
                            <p className="white-space-pre-wrap text-justify">{item.value}</p>
                        </Accordion>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Qa