import { useState, useRef } from 'react'
import './index.scss'
import { Col, Row } from 'react-bootstrap'
import alien from '../../../assets/images/home/alien.png'
import Slider from "react-slick";
import cm1 from '../../../assets/images/home/cm1.png'
import cm2 from '../../../assets/images/home/cm2.png'
import cm3 from '../../../assets/images/home/cm3.png'
import cm4 from '../../../assets/images/home/cm4.png'
const UserSatisfaction = () => {
    const [a, setA] = useState(2)
    const sliderRef = useRef()
    const afterChange = (current) => {
        if (current == 4) {
            setA(3)
        } else if (current == 3) {
            setA(4)
        } else if (current == 2) {
            setA(0)
        } else if (current == 1) {
            setA(1)
        } else if (current == 0) {
            setA(2)
        }
    };
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "10px",
        rtl: false,
        arrows: false,
        afterChange,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    className: "center",
                    centerMode: true,
                    

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    // className: "center",
                    centerMode: true,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerMode: true,

                }
            }
        ]
    };
    const previous = () => {
        sliderRef.current.slickPrev();
    };
    const data = [
        {
            id: 0,
            src: cm3
        },
        {
            id: 1,
            src: cm2
        },
        {
            id: 2,
            src: cm3
        },
        {
            id: 3,
            src: cm4
        },
        {
            id: 4,
            src: cm4
        },
    ]

    return (
        <div className='UserSatisfaction bg-black'>
            <div className="d-flex align-items-start flex-wrap">
                <div className="alien-div position-relative h-100">
                    <div className='alien-img'></div>
                    <div className='w-100 d-flex justify-content-center'>
                        <div >
                            <p className='fs-50 fw-700 text-tufts-blue text-start'>رضایت کاربران</p>
                            <p className='fs-30 fw-400 text-tufts-blue text-start'>از تحلیل‌های اپلیکیشن مارول</p>
                        </div>
                    </div>
                </div>
                <div className="Slider-div h-100 py-4">
                    <div className="mx-auto w-100 p-4 ">
                        <Slider className="" ref={sliderRef} {...settings}>
                            {data.map((e, index) =>
                                <div key={e.id}>
                                    <img className={index === a ? "changedImage" : "notChangedImage"} src={e.src} alt="comments" />
                                </div>
                            )}
                        </Slider>
                    </div>
                    <div className="arrow-btn">
                        <button style={{ width: "24px", height: "24px" }} className="btn btn-white rounded-circle d-flex flex-center " onClick={previous}>
                            <i className="bi bi-arrow-left-short fs-20"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserSatisfaction