import React, { useState, useContext } from 'react'
import Context from './_context';
import { Form, InputGroup, Button, Input } from "../../../components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from '../../../methods';
import { axios } from "../../../boot"
import { useNavigate } from "react-router";

const Code = () => {
    const navigate = useNavigate();
    
    const [ activePage , setActivePage , data, setData] = useContext(Context);
    const [ code , setCode ] = useState("")
    const dispatch = useDispatch();

    const setIsLogged = (data = true) => {
        dispatch({ type: "SET_IS_LOGGED", data });
    };
    const setRole = (data = "") => {
        dispatch({ type: "SET_ROLE", data });
    };
    const formControl = [
        {
            label: "کد احراز هویت",
            state: "code",
            type: "number",
            // rules: rules.phoneNumber
        },
    ]
    
    const submit = () => {
        const url = "/authentication/register";
        axios.post(url, data).then(({data}) => {
            const text = `ثبت نام موفقیت آمیز بود`;
            toast({ text });
            const token = data.token;
            const role = data?.user?.role
            axios.defaults.headers["x-auth-token"] = token;
            localStorage.setItem("token", token);
            setRole(role);
            setIsLogged(true);
            navigate("/", { replace: true });
            setData({})

        });
    };
    const sendCode = () => {
        const url ="authentication/verifysms"
        let body = {
            code : code,phone : data?.phone
        }
        axios.post(url,body).then(({data})=>{
            submit()
        })
    }
    return (
        <>
            <p className="fs-30 fw-700 text-black mx-2">کد احراز هویت</p>
            <Form
                onSubmit={sendCode}
                className=" d-flex flex-column flex-center text-center "
            >
                {formControl.map(item =>
                    <Input
                        key={item.state}
                        {...item}
                        value={code}
                        setValue={(val) =>
                            setCode(val)
                        }
                        className="border "
                    />
                )}
                <Button variant="black" className="w-100 my-4 rounded-0 py-2" type="submit">
                    ثبت نام
                </Button>


                <button className="fs-16 fw-700 text-black" onClick={()=>{setActivePage("signup")}}>بازگشت به صفحه ثبت نام </button>
            </Form>
        </>
    )
}

export default Code