import { toast } from "../../methods";
import store from "../../redux";

function logout() {
  store.dispatch({ type: "SET_IS_LOGGED", data: false });
  store.dispatch({ type: "SET_ROLE", data: null });
  localStorage.removeItem("token");
  localStorage.removeItem("role");
}
export default function handleErrors(error) {
  const response = error?.response;
  console.log(response);
  if (response) {
    response.status === 403 && logout();
    // const errorsList = response.data.errors ?? [];
    // errorsList.forEach((error) => {
    //   const text = Object.values(error)[0];
    //   toast({ text, type: "error" });
    // });
    const text = response?.data?.message
    toast({ text, type: "error" });
    return;
  }
  const text = "Network error";
  toast({ text, type: "error" });
}
