const trueAndFalse = [
    {
        name : "فعال",
        id : true
    },
    {
        name : "غیرفعال",
        id : false
    },
]

export default trueAndFalse;