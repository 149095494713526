import { useState, useEffect, Fragment } from 'react'

import { axios, moment } from '../../../boot'
import { Col, Row } from 'react-bootstrap'
const PlanBill = () => {
    const [bills, setBills] = useState([])
    const getBills = () => {
        const url = "/payments/getUserPayments"
        axios.get(url).then(({data})=>{
            setBills(data.data)
            
        })
    }
    useEffect(getBills,[])
    const handleStatus = (status) => {
        if(status === "REJECTED"){
            return <span className='text-danger fs-16 fw-500'>رد شده</span>
        }else if(status === "APPROVED"){
            return <span className='text-success fs-16 fw-500'>تایید شده</span>
        }else{
            return <span className='text-warning fs-16 fw-500'>در انتظار</span>
        }
    }
    const details = (data = {}) => [
        {
            label : "نام پلن",
            value : data?.selectedPlan?.name
        },
        {
            label : "قیمت پلن",
            value : `${data?.selectedPlan?.toomanAmount} تومان - ${data?.selectedPlan?.price}`
        },
        {
            label : "تعداد کانال ها",
            value : data?.selectedPlan?.channels?.length
        },
        {
            label : "تاریخ خرید",
            value : moment.miladiToShamsi({date : data?.createdAt})
        },
        {
            label : "وضعیت",
            value : handleStatus(data?.status)
        },
    ]
    return (
        <div>
            <p className='fw-700 text-black text-center my-2 fs-20'>صورتحساب های پلن</p>
            <Row className="gap-2">
                {bills?.map((e,index)=>
                    <Col className="border border-black p-2 bg-harp" key={index} xs="12" md="12" lg="12">
                        <div className="row">
                            {details(e)?.map((e,index)=>
                                <Fragment key={index}>
                                    <div className="col-6">
                                        <p className="fs-18 fw-700 text-black text-start">{e.label}</p>
                                    </div>
                                    <div className="col-6">
                                        <p className='text-gray fs-16 fw-500 text-end'>{e.value}</p>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default PlanBill