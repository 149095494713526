import React, { useRef, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Form, InputGroup, Button, Modal } from "../../../components";
import { copyText, rules, toast } from "../../../methods";
import { axios } from "../../../boot";
// import qrCode from "../../../assets/images/wallet-qrcode.jpeg";
import qrCode from "../../../assets/images/wallet-qrcode2.jpg";
import "./index.scss";
export default function StartOrderUsd() {
  const inputFileRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showQrCode, setShowQrCode] = useState(false);
  const [data, setData] = useState({
    priceUsd: location.state?.totalUsd,
    toAddress: "TGAj7N7LaTE1xRJ3spkJmrQjY9h2SFbEr8",
  });

  const copyWallet = () => {
    copyText(data.toAddress)
      .then(() => {
        const text = "آدرس کیف پول با موفقیت کپی شد.";
        toast({ text });
      })
      .catch(() => {
        const text = "خطا در کپی کردن آدرس کیف پول، لطفا مجددا تلاش کنید.";
        toast({ text, type: "error" });
      });
  };
  const uploadFile = (e) => {
    dispatch({ type: "SHOW_LOADING" });
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const image = reader.result;
      setData((p) => ({ ...p, image }));
      dispatch({ type: "HIDE_LOADING" });
    };
    reader.readAsDataURL(file);
  };
  const submitOrder = () => {
    const base64Regex = /^data:image\/[a-z]+;base64,/;
    const text = "رسید تراکنش الزامی است";
    if (!data.image) return toast({ text, type: "error" });
    const url = "/carts/pay-usd";
    const body = { ...data };
    body.orderId = location.state._id;
    body.image = body.image.replace(base64Regex, "");
    axios.post(url, body).then(() => {
      const text = "درخواست شما با موفقیت ثبت شد.";
      toast({ text });
      navigate("/", { replace: true });
    });
  };
  const fromControls = [
    {
      label: "قیمت(USD)",
      state: "priceUsd",
      readOnly: true,
    },
    {
      label: "TXID",
      state: "txid",
    },
    {
      label: "آدرس کیف پول",
      state: "fromAddress",
    },
    {
      label: "واریز به آدرس کیف پول",
      state: "toAddress",
      readOnly: true,
      col: 7,
      append: (
        <React.Fragment>
          <button
            onClick={copyWallet}
            type="button"
            className="bi bi-files input-group-text"
          />
          <button
            onClick={() => setShowQrCode(true)}
            type="button"
            className="bi bi-qr-code-scan input-group-text"
          />
        </React.Fragment>
      ),
    },
    {
      as: "textarea",
      label: "توضیحات",
      state: "message",
      col: 12,
    },
  ].map((e) => ({ rules: rules.required, ...e }));
  return (
    <Container className="StartOrderUsd py-5 row">
      <Col xs="12" md="10" lg="8" className="px-0">
        <Form
          onSubmit={submitOrder}
          className="row bg-white rounded shadow-sm py-5"
        >
          <h3 className="text-info d-block text-center">
            تکمیل فرایند پرداخت با تتر
          </h3>
          <Col xs="12" className="d-flex flex-column flex-center text-center">
            <div className="image-uploader d-flex flex-center position-relative bg-white rounded shadow-sm border border-light-gray overflow-hidden">
              {data.image ? (
                <img
                  className="w-100 h-100 object-fit-cover"
                  src={data.image}
                  alt="رسید تراکنش"
                />
              ) : (
                <i className="bi bi-image text-secondary fs-1" />
              )}
              <button
                type="button"
                className="bi bi-plus-lg position-absolute text-white bg-primary rounded-circle d-flex flex-center fs-5"
                onClick={() => inputFileRef.current.click()}
              />
              <input
                ref={inputFileRef}
                type="file"
                className="d-none"
                onChange={uploadFile}
                accept=".jpg, .jpeg, .png"
              />
            </div>
            <p className="mt-1">آپلود رسید تراکنش</p>
          </Col>
          {fromControls.map((e) => (
            <Col key={e.state} xs="12" md={e.col ?? 6} lg={e.col ?? 4}>
              {React.createElement(e.tag ?? InputGroup, {
                ...e,
                value: data[e.state],
                setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
              })}
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="success" className="text-white">
              ثبت نهایی
            </Button>
          </Col>
        </Form>
      </Col>
      <Modal show={showQrCode} onHide={setShowQrCode}>
        <img className="w-100" src={qrCode} alt="qrCode" />
      </Modal>
    </Container>
  );
}
