import img1 from "./1.jpg";
import img2 from "./2.jpg";
import img3 from "./3.jpg";
import img4 from "./4.jpg";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
const elonMusk = {
  _id: "0",
  title: "زندگی نامه ایلان ماسک",
  createdAt: "1400/11/08",
  author: "گوتنبرگ",
  img: img1,
  full: [
    {
      img: img1,
      title: "پدیدآورنده ای به نام ایلان ماسک",
      text: "شاید امروزه کمتر کسی باشد که در مورد این کارآفرین چیزی نشنیده باشد.انتظار میرود که میلیون ها انسان ،این فرد را الگوی خود قرار داده اند و رویای هر یک از این افراد رسیدن به جایگاه فعلی او میباشد.در این پست در مورد قسمتی از زندگی نامه ی ایلان ماسک صحبت میشود.",
    },
    {
      img: img2,
      title: "کودکی",
      text: " زاده ی آفریقا ی جنوبی در سال 1971 است او دوران کودکی و نوجوانی خود را در این کشور سپری کرد.وی دارای یک خواهر و برادر است اما در زمانی که فقظ10 سال داشت پدر و مادرش ازیکدیگر جدا شدند و او با پدرش در افریقا جنوبی دوران نوجوانی اش را پشت سر گذاشت.",
    },
    {
      img: img3,
      title: "اولین ابداع",
      text: "شاید اولین رویدادی که میتوان گفت که زندگی این شخص را تحت تاثیر قرار داد خرید یک کامپیوتر در سن 10 سالگی اش بود که  این امر باعث شد که به برنامه نویسی علاقه مند شود . او در سن 12 ساگی اولین بازی کامپیوتری خود را پدید آورد که بلستر نام داشت جالب اینجا است که مجله‌ی PC and Office Technology‌ این بازی را با قیمت 500 دلار از او خرید. ",
    },
    {
      img: img4,
      title: "مهاجرت",
      text: "ایلان ماسک سعی داشت از جایی که در آن زندگی می‌کرد رهایی پیدا کند، چون او فهمیده بود که در آن‌جا به خوبی نمی‌تواند پیشرفت کند. پس به واسته نزدیکان خانواده مادری خود، در ۱۹ سالگی به کانادا رفت و پناهنده آنجا شد.مهم ترین هدف او از این کار تحصیل در این کشور بود.اما همانطور که میدانیم زندگی در کشوری جدید شامل سختی های زیادی می باشد که مهمترین آن مشکلات اقتصادی و فقدان سرمایه ی مالی میباشد که گریبان گیر ایلان جوان هم شد ولی هیچ چیزی وجود نداشت که بتواند جلوی بلندپروازی او را بگیرد حتی تنگدستی که در آن زمان وجودش کاملا لمس میشد برای همین درهمان  ابتدا مشغول به هر کاری میشد تا بتواند شکم خود را سیر نگه دارد.",
    },
    {
      img: img5,
      title: "دوران تحصیل",
      text: "مهم ترین هدف او از مهاجرت، تحصیل در این کشور بود. بنابراین 2 سال در دانشگاه کویینز رفت و در رشته فیزیک آن دانشگاه تحصیل کرد. اما در سال ۱۹۹۱ این دانشگاه را رها کرد و همان طور که یکی از اهداف زندگی اش تحصیل در ایلات متحده بود به دانشگاه پنسیلوانیا پیوست و در نهایت ماسک از آن دانشگاه در رشته فیزیک فارغ التحصیل شد. او در آنجا در رشته‌های کسب‌وکار و فیزیک، دو مدرک لیسانس گرفت. تحصیل در رشته علوم اقتصاد و یافته هایش در این رشته او را به دومین کارآفرین جهان تبدیل کرد.",
    },
    {
      img: img6,
      text: "در پست های بعدی درمورد ابداعات و کارافرینی های این نابغه صحبت خواهیم کرد.آتیتی\nبا ما همراه باشید......",
    },
  ],
};
export default elonMusk;
