import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import error404 from "../assets/icons/error 404.svg";
export default function Error({ title = null }) {
  return (
    <Container>
      <Row>
        <Col xs="12" md="8" lg="5">
          <div className="d-flex flex-column flex-center w-100">
            <img className="w-100" src={error404} alt="error404" />
            <h3 className="text-primary mb-5 text-center">{title}</h3>
            <Link to="/" replace className="Button btn btn-outline-primary">
              بازگشت به صفحه اصلی
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
