import BAccordion from "react-bootstrap/Accordion";
import "./index.scss";
export default function Accordion({
  title = "",
  defaultSetActive = false,
  children = "",
  variant = "primary",
  color = ""
}) {
  return (
    <BAccordion
      className="w-100"
      defaultActiveKey={defaultSetActive ? "0" : null}
    >
      <BAccordion.Item eventKey="0">
        {/* <BAccordion.Header className="bg-info">{title}</BAccordion.Header> */}
        <BAccordion.Button
          className={`btn text-${color} bg-${variant} bg-opacity-25 shadow-none text-start fs-15`}
        >
          {title}
        </BAccordion.Button>

        <BAccordion.Body>{children}</BAccordion.Body>
      </BAccordion.Item>
    </BAccordion>
  );
}
