import React, { useEffect, useState } from 'react'
import { purchaseStatus } from '../../../../constants';
import { axios, moment } from '../../../../boot';
import { Table } from '../../../../components';

const PaymentList = ({ payments = [] }) => {
    const showStatus = (id) => {
        const { color, name } = purchaseStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    }
    
    return (
        <div className='w-100'>
            <Table className="text-center">
                <thead>
                    <tr>
                        <th className="">وضعیت</th>
                        <th className="">تکست آیدی</th>
                        <th className="">كد تخفيف</th>
                        {/* <th class="">عكس</th> */}
                        <th className="">پلن</th>
                        <th className="">تاریخ ثبت</th>
                    </tr>
                </thead>
                <tbody>
                    {payments?.map((e) => (
                        <tr key={e._id}>
                            <td>{showStatus(e.status)}</td>
                            <td id="textId">{e.textId}</td>
                            <td>{e.code ? e.code : <p className="text-danger">بدون تخفیف</p>}</td>
                            <td>{e.selectedPlan?.name}</td>
                            <td>{moment.miladiToShamsi({ date: e?.createdAt, format: "HH:mm - jYYYY/jMM/jDD" })}</td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default PaymentList