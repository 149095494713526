import React, { useState } from "react";
import Select from "react-select";
import './index.scss'

export default function MultiSelect({
    options = [],
    selectedOptions = [],
    setSelectedOptions = () => {},
    label = "",
}) {
    // const [selectedOptions, setSelectedOptions] = useState();
    function handleSelect(data) {
        
        setSelectedOptions(data);
        
    }
    return (
        <div className="multiSelect-box">
            <span className="text-start d-block w-100 ">
                {label}
            </span>
            <div className="">
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    placeholder={`${label} را انتخاب کنید`}
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                />
            </div>
        </div>
    )
}
