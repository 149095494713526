import { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Table, Pagination } from '../../../../components'
import { axios } from '../../../../boot'
import { useNavigate } from 'react-router'
import { toast } from '../../../../methods'
import { useGetChannels } from '../../../../hooks'
const Signals = () => {
    const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
    const navigate = useNavigate();
    const [params, setParams] = useState({ page: 1, ...basicParams });
    const [signals, setSignals] = useState([])
    const [pages, setPages] = useState({})
    const [channels, getChannels] = useGetChannels()
    const saveParams = () => {
        const value = JSON.stringify(params);
        sessionStorage.setItem("params", value);
    };
    const getSignals = () => {
        const url = "/signals/getSignals"
        saveParams();
        const config = {
            params: {
                perPage: 20,
                ...params,
            },
        };
        axios.get(url).then(({ data }) => {
            console.log(data)
            setSignals(data.data)
            setPages(data?.pages)
        })
    }
    const deleteSignals = (id) => {
        const url = `/signals/${id}/deleteSignal`
        axios.delete(url).then(() => {
            let text = "سیگنال با موفقیت حذف شد"
            toast({ text });
            getSignals()
        });
    }
    const planName = (id) => {
        let yourPlan = channels.find(e => e._id === id)
        return yourPlan?.name
    }
    useEffect(getChannels, [])
    useEffect(getSignals, [])
    return (
        <Container className="">
            <Row className="w-100">
                <Col xs="12" md="12">
                    <Table className="text-center">
                        <thead>
                            <tr>
                                <th>متن پیام</th>
                                <th>پیامک</th>
                                <th>پلن</th>
                                <th>حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                            {signals?.map((e) => (
                                <tr key={e._id}>
                                    <td>{e.caption}</td>
                                    <td>{e.sms ? <p className='text-success'>فعال</p> : <p className='text-danger'>غیرفعال</p>}</td>
                                    <td>{planName(e.publishedIn)}</td>
                                    <td><i onClick={() => deleteSignals(e._id)} className="bi bi-trash"></i></td>
                                </tr>
                            ))}

                            {/* <tr>
                                <td colSpan="8">
                                    <Button
                                        onClick={() => navigate("new")}
                                        variant="success"
                                        className="text-light"
                                    >
                                        اضافه کردن سیگنال جدید
                                    </Button>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Pagination
                totalPages={pages.totalPages}
                activePage={params.page}
                setActivePage={(page) => setParams((p) => ({ ...p, page }))}
            />
        </Container>
    )
}

export default Signals