const serverStatus = [
  {
    name: "در انتظار پرداخت",
    id: "pending",
    color: "warning",
  },
  {
    name: "پرداخت موفق",
    id: "success",
    color: "success",
  },
  {
    name: "فعال",
    id: "active",
    color: "success",
  },
  {
    name: "منقضی شده",
    id: "expired",
    color: "danger",
  },
  {
    name: "پرداخت ناموفق",
    id: "failed",
    color: "danger",
  },
];
export default serverStatus;
