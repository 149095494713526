import React from 'react'
import AppleStore from '../../assets/icons/contact us/apple.svg'
import './index.scss'

const IosBtn = ({
    href = "",
}) => {
  return (
    <a href={href} target="_blank" className="bg-black d-flex justify-content-end p-1 IosBtn rounded-1 m-1">
        <div>
            <p className="fs-12 text-end text-white">Download on the</p>
            <p className="fs-16 text-end text-white"> App Store</p>
        </div>
        <div className="mx-1">
            <img src={AppleStore} alt="play store" width={26} height={33} />
        </div>
    </a>
  )
}

export default IosBtn