import React, { useEffect, useRef, useState } from "react";
import { FormControl } from "react-bootstrap";
import { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import Modal from "../Modal";
import Button from "../Button";
import { showNumber } from "../../methods";
import { moment } from "../../boot";
import "./index.scss";
export default function InputGroup({
  label = "",
  value = "",
  setValue = () => { },
  rules = [],
  type = "text",
  append = "",
  prepend = "",
  as = "input",
  readOnly = false,
  minDate = null,
  maxDate = null,
  dir = "rtl",
  placeholder = "",
  cancelBtn = false
  
}) {
  const input = useRef();
  const message = useRef();
  const [focused, setFocused] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const isDate = type === "date";

  const handleFormatDate = ({ day, month, year }) => {
    const date = `${year}/${showNumber(month.number)}/${showNumber(day)}`;
    return new Date(moment.shamsiToMiladi({ date }));
  };
  const handleChange = (e) => {
    // if (isFile) return;
    if (isDate) {
      const date = e.map(handleFormatDate);
      return setValue(date);
    }
    const value = e.target?.value;
    setValue(value);
  };

  const handleChangeValue = () => {
    input.current.classList.toggle("active", `${value}`.length !== 0);
    input.current.onblur = () => {
      return rules.every((rule) => {
        const isValid = rule(value ?? "");
        if (isValid !== true) {
          message.current.innerText = isValid;
        } else {
          rules.length !== 0 && (message.current.innerText = "");
        }
        return isValid === true;
      });
    };
  };

  useEffect(() => {
    input.current.classList.toggle("focused", focused);
  }, [focused]);
  useEffect(handleChangeValue, [value]);

  return (
    <div ref={input} className="InputGroup position-relative w-100 check-valid">
      <span className="label text-start d-block text-truncate">{label}</span>
      <div className="input-box d-flex flex-center w-100 position-relative border-bottom border-dark">
        {prepend}
        {React.createElement(FormControl, {
          as,
          dir,
          type,
          readOnly,
          className: "px-3 border-0 text-center shadow-none",
          value: value ?? "",
          placeholder,
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
          onInput: ({ target }) => {
            setValue(target.value);
          },
        })}
        {append}
      </div>
      {rules.length !== 0 && (
        <p
          ref={message}
          className="message w-100 d-block text-danger text-start px-2 mb-0"
        ></p>
      )}

      {isDate && (
        <Modal title={label} show={showCalendar} onHide={setShowCalendar}>
          <Calendar
            className="mx-auto"
            range={true}
            calendar={persian}
            locale={persian_fa}
            format="YYYY/MM/DD"
            minDate={minDate}
            maxDate={maxDate}
            value={value ?? ""}
            onChange={handleChange}
          />

          <div className="flex-center mt-3 gap-1">


            {cancelBtn &&
              <Button variant="danger" onClick={() => setValue([])}>
                حذف مقدار
              </Button>
            }


            <Button onClick={() => setShowCalendar(false)}>بستن</Button>
          </div>
        </Modal>
      )}
    </div>
  );
}
