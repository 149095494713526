import React from 'react'
import PlayStore from '../../assets/icons/contact us/playStore.svg'
import './index.scss'

const AndroidBtn = ({
    href = "",
}) => {
  return (
    <a href={href} target="_blank" className="bg-black d-flex justify-content-end p-1 AndroidBtn rounded-1 m-1">
        <div>
            <p className="fs-12 text-end text-white">GET IT ON</p>
            <p className="fs-16 text-end text-white">Google Play</p>
        </div>
        <div>
            <img src={PlayStore} alt="play store" width={35} height={40} />
        </div>
    </a>
  )
}

export default AndroidBtn