import React, { useState } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import correctIcon from "../../../assets/icons/cart/correct.svg";
import failIcon from "../../../assets/icons/cart/fail.png";
import { useQueryString } from "../../../hooks";
import "./index.scss";
import { downloadItems } from '../../../constants'
import { Modal, VideoPlayer } from '../../../components';
import videoApplication from '../../../assets/video/application.mp4'
import logo from "../../../assets/logo/logo.png";
const VerifyZarinPayment = () => {
    const { status, refId } = useQueryString();
    const [show, setShow] = useState(false)
    return (
        <Container className="verifyZarinPayment py-5">
            <Modal size='lg' show={show} onHide={setShow}>
                <div style={{ aspectRatio: "16 / 9" }}>
                    <VideoPlayer
                        height="100%"
                        title="نحوه استفاده از اپلیکیشن"
                        src={videoApplication}
                        thumbnail={logo}
                    />
                </div>
            </Modal>
            <Row>
                <Col xs="12" md="10" lg="5">
                    <div className="receipt-section w-100 d-flex flex-column flex-center row-gap-3 text-center border border-info rounded-3 p-5">
                        <img
                            width="100"
                            height="100"
                            src={status === "OK" ? correctIcon : failIcon}
                            alt={status === "OK" ? "correct" : "fail"}
                        />
                        <span className={`h4 text-${status === "OK" ? "info" : "danger"}`}>
                            {status === "OK"
                                ? "پرداخت شما با موفقیت انجام شد"
                                : "پرداخت شما ناموفق بود"}
                        </span>

                        <span className="my-auto d-flex col-gap-4 fs-5">
                            {refId && (
                                <React.Fragment>
                                    <span className="text-secondary">کد پیگیری</span>
                                    <span className="text-info">{refId}</span>
                                </React.Fragment>
                            )}
                        </span>
                        {status === "OK" &&
                            <>
                                <p className="text-black fw-500 fs-16 my-2">دانلود اپليكيشن مارول ترید برای آگاهی بیشتر</p>
                                <Row className="w-100 d-flex justify-content-start">
                                    {downloadItems.map((item, index) => (
                                        <Col key={index} xs="12" md="6" xl="6" className="px-1">
                                            <a
                                                href={item.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="position-relative d-inline-block shadow bg-black border border-black w-100 rounded overflow-hidden m-0 p-0"
                                                download={item.download ?? false}
                                            >
                                                <img
                                                    className="w-100 object-fit-contain"
                                                    height="40"
                                                    src={item.icon}
                                                    alt={item.label}
                                                />
                                            </a>
                                        </Col>
                                    ))}
                                    <Col xs="12" className="px-0">
                                        <button onClick={() => setShow(true)} className="btn btn-tufts-blue text-white w-100">نحوه استفاده از اپلیکیشن</button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default VerifyZarinPayment