import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Select, Modal } from "../../../../components";
import { axios } from "../../../../boot";
import { rules, toast } from "../../../../methods";
import { orderStatus } from "../../../../constants";
import { useGetPlan } from "../../../../hooks";

export default function Purchase() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  // const [ show , setShow ] = useState(false)
  const plan = useGetPlan()
  const getData = () => {
    const url = `/payments/${id}/getPayment`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  const updatePurchase = () => {
    const url = `/payments/${id}/editPayment`
    axios.patch(url, data).then(({ data }) => {
      let text = "پلن با موفقیت ویرایش شد"
      toast({text});
      navigate(-1);
    })
  }
  const fromControls = [
    {
      tag: Select,
      state: "selectedPlan",
      label: "پلن",
      items: plan?.filter(e => e.active == true && e._id !== "61ca2cb6927a5772a822653a")?.map(i => ({ name: i.name, id: i._id })),
     
    },
  ]
  const deletePurchase = () => {
    const url = `/payments/${id}/deletePayment`
    axios.delete(url).then(() => {
      let text = "پرداخت با موفقیت حذف شد"
      toast({text});
      navigate(-1);
    });
  }
  const handlePurchase = (enter) => {
    const url = `/payments/${id}/approvment`
    const body = { result : enter }
    axios.post(url,body).then(({data})=>{
      let text = ""
      if(enter){
        text = "پرداخت تایید شد"
      }else{
        text = "پرداخت رد شد"
      }
      toast({text})
      navigate(-1);
    })
  }
  useEffect(getData, []);
  return (
    <Container className="Product">
      <Form onSubmit={updatePurchase}>
        <Row>
          {fromControls.map((item, index) => (
            <Col
              key={index}
              xs="12"
              md="6"
              lg="4"
            >
              {React.createElement(item.tag ?? InputGroup, {
                rules: rules.required,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
                ...item,
              })}
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="primary" className="text-light">
              ثبت تغییرات
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="d-flex flex-center flex-wrap my-4">
        <Button onClick={() => handlePurchase(true)} className="m-2 text-white" variant="success">تایید پرداخت</Button>
        <Button onClick={() => handlePurchase(false)} className="m-2 text-white" variant="warning">رد پرداخت</Button>
        <Button onClick={()=> deletePurchase()} variant="danger text-white" className="m-2 ">حذف پرداخت</Button>
      </div>
      {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
    </Container>
  );
}
