import React, { useContext, useState } from 'react'
import Context from './_context';
import { Form , Button } from "../../../components"
import { toast } from '../../../methods';
import { axios } from '../../../boot'
const VerifyEmail = () => {
    const [activePage, setActivePage, data, setData] = useContext(Context);
    const sendMessage = () => {
        const url = "/authentication/sendemail"
        let body = {
            email: data?.email
        }
        axios.post(url, body).then(({ data }) => {
            let text = "کد با موفقیت ارسال شد"
            toast({ text })
            setActivePage("email")
        })
    }
    return (
        <>
            <p className="fs-30 fw-700 text-black mx-2">دریافت کد ورود</p>

            <Form
                onSubmit={sendMessage}
                className=" d-flex flex-column flex-center text-center "
            >
                <Button variant="black" className="w-100 my-4 rounded-0 py-2" type="submit">
                    دریافت ایمیل 
                </Button>


                <button onClick={()=>{setActivePage("signup")}} className="fs-16 fw-700 text-black">بازگشت به صفحه ثبت نام</button>
            </Form>
        </>
    )
}

export default VerifyEmail