import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { cloneDeep } from "lodash";
import { InputGroup, Button, Form, Select } from "../../../components";
import { axios } from "../../../boot";
import { rules, toast } from "../../../methods";
export default function Product() {
  const fromControls = [
    {
      label: "نام پلن",
      state: "name",
    },
    {
      label: "قیمت(تومان)",
      state: "oldPrice",
      type: "number",
    },
    {
      label: "قیمت(USD)",
      state: "oldPriceUsd",
      type: "number",
    },
    {
      label: "درصد تخفیف",
      state: "off",
      type: "number",
      rules: rules.off,
    },
    {
      label: "مقدار RAM",
      state: "ram",
    },
    {
      label: "مقدار CPU",
      state: "cpu",
    },
    {
      label: "حافظه ذخیره‌سازی",
      state: "storage",
    },
    {
      label: "پهنای باند",
      state: "bandwidth",
    },
    {
      label: "محل سرور(ip سرور)",
      state: "location",
    },
    {
      label: "دیتاسنتر",
      state: "datacenter",
    },
    {
      tag: Select,
      label: "وضعیت",
      state: "available",
      items: [
        { name: "فعال", id: "true" },
        { name: "غیر فعال", id: "false" },
      ],
    },
    {
      label: "توضیحات",
      state: "usage",
      as: "textarea",
    },
  ];
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const getProduct = () => {
    const url = `/admins/pub/products/${id}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  const editProduct = () => {
    const url = "/admins/pub/products";
    const body = cloneDeep(data);
    body.off = Number(body.off);
    body.price = Number(body.oldPrice);
    body.priceUsd = Number(body.oldPriceUsd);
    body.available = `${body.available}`;
    delete body.oldPrice;
    delete body.oldPriceUsd;
    delete body.createdAt;
    delete body.modifiedAt;
    axios.put(url, body).then(() => {
      const text = "عملیات موفقیت آمیز بود";
      toast({ text });
    });
  };
  const removeProduct = () => {
    const url = "/admins/pub/products";
    const body = { data: { _id: id } };
    axios.delete(url, body).then(() => {
      const text = "عملیات موفقیت آمیز بود";
      toast({ text });
      navigate(-1, { replace: true });
    });
  };
  useEffect(getProduct, []);
  return (
    <Container className="Product">
      <Form onSubmit={editProduct}>
        <Row>
          {fromControls.map((item, index) => (
            <Col
              key={index}
              xs="12"
              md={index === 10 ? "12" : "6"}
              lg={index === 10 ? "12" : "4"}
            >
              {React.createElement(item.tag ?? InputGroup, {
                rules: rules.required,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
                ...item,
              })}
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center gap-1">
            <Button type="submit" variant="success" className="text-light">
              ثبت تغییرات
            </Button>
            <Button
              variant="danger"
              className="text-light"
              onClick={removeProduct}
            >
              حذف
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
