import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { axios } from "../../../boot";
import { Button, Form, InputGroup, Modal } from "../../../components";
import { rules } from "../../../methods";

export default function ActiveService() {
  const serverInfo = (data = {}) => [
    {
      label: "(IP)",
      value: data["ip"] ?? (
        <span className="text-danger">اختصاص داده نشده</span>
      ),
    },
    {
      label: "(RAM)",
      value: data.product?.ram,
    },
    {
      label: "(CPU CORE)",
      value: data.product?.cpu,
    },
    {
      label: "پهنای باند",
      value: data.product?.bandwidth,
    },
    {
      label: "حافظه ذخیره سازی",
      value: data.product?.storage,
    },
    {
      label: "محل سرور",
      value: data.product?.location,
    },
    {
      label: "توضیحات",
      value: data["defaultLogin"] ?? "...",
    },
  ];
  const formControls = [
    {
      label: "(IP)",
      state: "ip",
      rules: rules.ip,
    },
    {
      label: "نام کاربری",
      state: "username",
      rules: rules.required,
    },
    {
      label: "کلمه عبور",
      state: "password",
      rules: rules.required,
    },
  ];
  const { id } = useParams();
  const [data, setData] = useState({});
  const [serverData, setServerData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const getData = () => {
    const url = `/admins/services/${id}`;
    axios.get(url).then(({ data }) => {
      setServerData(data);
    });
  };
  const addIp = () => {
    const url = "/admins/services/add-ip";
    const body = {
      ...data,
      serviceId: id,
    };
    axios.post(url, body).then(() => {
      const ip = data.ip;
      const defaultLogin = `username: ${data.username} and password: ${data.password}`;
      setServerData((p) => ({ ...p, ip, defaultLogin }));
      setShowModal(false);
    });
  };
  useEffect(getData, []);
  return (
    <Container>
      <Row className="w-100">
        {serverInfo(serverData).map((item, index) => (
          <Col key={index} xs={index === 6 ? "12" : "6"}>
            {item.label}: <span className="text-secondary">{item.value}</span>
          </Col>
        ))}
        <Col xs="12" className="d-flex flex-center">
          {!data.ip && (
            <Button className="text-light" onClick={() => setShowModal(true)}>
              اختصاص دادن IP
            </Button>
          )}
        </Col>
      </Row>
      <Modal show={showModal} onHide={setShowModal} title="اختصاص دادن IP">
        <Form onSubmit={addIp} className="row">
          {formControls.map((item, index) => (
            <Col key={index} xs="12">
              <InputGroup
                {...item}
                value={data[item.state]}
                setValue={(val) =>
                  setData((p) => ({ ...p, [item.state]: val }))
                }
              />
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" className="text-light" variant="success">
              اختصاص دادن IP
            </Button>
          </Col>
        </Form>
      </Modal>
    </Container>
  );
}
