import { Badge } from "../components";

export default function setPrototypes() {
  const defaultValue = {
    name: "نامشخص",
    id: null,
    color: "dark",
  };
  // eslint-disable-next-line
  Array.prototype.findById = function (id = "") {
    return this.find((e) => e.id === id) ?? defaultValue;
  };
  // eslint-disable-next-line
  Array.prototype.badge = function (id = "") {
    const { color, name } = this.find((e) => e.id === id) ?? defaultValue;
    return <Badge label={name} variant={color} />;
  };
  // eslint-disable-next-line
  Array.prototype.toMatrix = function (elementsPerSubArray = 1) {
    let matrix = [];
    let i = 0;
    let k = -1;
    for (i = 0, k = -1; i < this.length; i++) {
      if (i % elementsPerSubArray === 0) {
        k++;
        matrix[k] = [];
      }
      matrix[k].push(this[i]);
    }
    return matrix;
  };
  // eslint-disable-next-line
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
}
