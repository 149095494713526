import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Input } from "../../../components";
import { axios } from "../../../boot";
import { rules, srcFile, toast } from "../../../methods";
import moment from "moment-jalaali";
export default function Report() {
    const [data, setData] = useState({})

    const { id } = useParams();
    const navigate = useNavigate();

    const getData = () => {
        const url = `/admins/services/${id}`;
        axios.get(url).then(({ data }) => {
            const body = {}
            const defaultLogin = data?.defaultLogin?.split(" ")
            body.username = defaultLogin && defaultLogin[1]
            body.password = defaultLogin && defaultLogin.at(-1)
            body.ip = data?.ip
            body._id = id
            body.endDate = data?.endDate
            setData({...body});

        });
    };

    const formControls = [
        {
            label: "IP",
            state: "ip",
            placeholder: "",
            value : data?.ip
        },
        {
            label: "نام کاربری",
            state: "username",
            value: data?.username,
            placeholder: ""
        },
        {
            label: "رمزعبور",
            state: "password",
            value: data?.password,
            placeholder: "",
            dir : "ltr"
        },
        {
            label: "انقضا",
            state: "endDate",
            placeholder: "",
            value : data?.endDate
        },
    ]

    const editReport = () => {
        const url = "/admins/services"
        const body = {}
        body.serviceId = data?._id
        body.ip = data?.ip
        body.username = data?.username
        body.password = data?.password
        body.endDate = data?.endDate
        console.log(body)
        axios.put(url, body).then((data) => {
            if (data.status == 200) {
                const text = "عملیات موفقیت آمیز بود";
                toast({ text });
            }
        })
    }

    const deleteUser = () => {
        const url = "/admins/services"
        const options = { data: { _id: id } };
        axios.delete(url, options).then(({ data }) => {
            const text = "عملیات موفقیت آمیز بود";
            toast({ text });
            navigate("/admin/reporting")
        });

    }

    const handleState = (value) => {
        if (value == "endDate") {
            return data && data[value]
        } else if (value == "username") {
            return data?.defaultLogin?.split(" ")[1]
        } else if (value == "password") {
            const dataa = data?.defaultLogin?.split(" ")
            return dataa && dataa[dataa?.length - 1]
        } else {
            return data?.ip
        }
    }


    useEffect(getData, []);
    return (
        <Container className="Product">
            <Form onSubmit={editReport}>
                <Row>
                    {formControls.map((item, index) => (
                        <Col key={item.state} xs="12" lg="6">
                            <Input
                                
                                {...item}
                                
                                setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
                            />
                        </Col>
                    ))}
                    <Col xs="12" className="d-flex flex-center">
                        <Button type="submit" variant="success" className="text-light">
                            ثبت تغییرات
                        </Button>
                        <Button className="mx-1" variant="danger" type="button" onClick={() => deleteUser()}>
                            حذف
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
