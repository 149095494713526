import React, { useState } from 'react'
import { Button, Modal } from '../../../components'
import success from '../../../assets/images/profile/success.png'
import { downloadItems } from '../../../constants'
import { Row, Col } from "react-bootstrap"
import HowToUseIos from './HowToUseIos'
const Receipt = () => {
  const [show, setShow] = useState(false)
  return (
    <div className="w-100">

      <img width={138} height={326} src={success} alt="success" loading='lazy' />
      <p className="fs-30 fw-700 text-success my-2">پرداخت شما با موفقیت انجام شد</p>
      <p className="text-black fw-500 fs-20 ">درصورت پرداخت تتری سفارش شما تا   <span className='fw-700 '>1 ساعت</span> آینده تایید میشود.</p>
      <p className="text-black fw-500 fs-20 ">از صبر و شکیبایی شما سپاس گزاریم</p>
      <Row className=" d-flex justify-content-center my-4">
        {downloadItems.map((item, index) => (
          <Col key={index} xs="12" md="6" lg="5" className="px-1">
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`position-relative d-inline-block shadow bg-black border border-black w-100 rounded overflow-hidden m-0 p-0`}
              download={item.download ?? false}
            >
              <img
                className="w-100 object-fit-contain"
                height="40"
                src={item.icon}
                alt={item.label}
              />
            </a>
          </Col>
        ))}
        <Col xs="12" md="6" lg="5" className="px-1">
          <a target='_blank' className='btn btn-primary text-white w-100' href="https://marveltrade.org/iosInstalling.mp4">نحوه نصب و راه اندازی اپ ios</a>
        </Col>
      </Row>
      {/* <div className="w-100 d-flex justify-content-center">
        <Button variant="white" label="بازگشت" className='rounded-0 fs-16 py-2 px-4 my-2 text-danger d-flex align-items-center' ><i className="bi bi-arrow-left-short"></i></Button>

      </div> */}
    </div>
  )
}

export default Receipt