import React, { useEffect, useState } from 'react'
import { useGetChannels } from '../../../../hooks'
import { Button, Form, Input, Select } from '../../../../components'
import { Col, Container, Row } from 'react-bootstrap'
import { axios } from '../../../../boot'
import { toast } from '../../../../methods'
const SendMentorSms = () => {
    const [data, setData] = useState({})
    const fromControls = [
        {
            state: "phone",
            label: "شماره همراه",
            type: "number"
        },
        {
            state: "telegramId",
            label: "آیدی تلگرام",
        },
    ]
    const sendSms = () => {
        const url = `/admins/${data?.phone}/${data?.telegramId}/sendMentorSms`
        axios.get(url).then(({ data }) => {
            let text = "پیامک با موفقیت ارسال شد"
            toast({ text })
            setData({})
        })
    }
    return (
        <Container className="">
            <Form className="row py-4" onSubmit={sendSms}>
                {fromControls.map((item, index) => (
                    <Col
                        key={index}
                        xs="12"
                        md="6"
                        lg="6"
                    >
                        {React.createElement(item.tag ?? Input, {
                            value: data[item.state],
                            setValue: (val) =>
                                setData((p) => ({ ...p, [item.state]: val })),
                            ...item,
                        })}
                    </Col>
                ))}
                <Col xs="12" className="d-flex flex-center">
                    <Button type="submit" variant="success" className="text-light">
                        ارسال پیامک
                    </Button>
                </Col>
            </Form>
            
        </Container>
    )
}

export default SendMentorSms