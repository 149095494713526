import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { Button, Form, Input } from '../../../components'
import { axios } from '../../../boot'
import { rules, toast } from '../../../methods'
const UserMargin = () => {
    const [data, setData] = useState({});
    const submit = () => {
        const url = "/pub/getMargin";
        const body = data;
        axios.post(url, body).then(() => {
            const text = "اطلاعات شما با موفقیت ثبت شد";
            toast({ text });
            setData({})
        });
    };
    const formControls = [
        {
            label: "نام",
            state: "name",
            labelColor: "white",
        },
        {
            label: "نام خانوادگی",
            state: "family",
            labelColor: "white",
        },
        {
            label: "شماره تماس",
            state: "phone",
            type: "tel",
            labelColor: "white",
            rules: rules.phone,
        },
        {
            label: "Free Margin",
            state: "freeMargin",
            labelColor: "white",
            type : "number"
        },
        {
            label: "Margin Level",
            state: "levelMargin",
            labelColor: "white",
            type : "number"
        },
        {
            label: "آیدی تلگرام",
            state: "telegramId",
            labelColor: "white",
        },
    ];

    return (
        <div style={{minHeight : "100vh"}} className="w-100 row d-flex ">
            <Col xs="12" lg="8" className="p-4 d-flex h-full">
                <Form
                    onSubmit={submit}
                    className=" row p-3 pt-lg-3 text-center bg-tufts-blue rounded-0 border border-2 border-black"
                >
                    <h5 className="text-white w-100 my-4">
                        دریافت مارجین کاربران
                    </h5>
                    {formControls.map((item, index) => (
                        <Col key={index} xs="12" lg="6">
                            <Input
                                {...item}

                                className="border py-2 bg-white"
                                value={data[item.state]}
                                setValue={(val) =>
                                    setData((p) => ({ ...p, [item.state]: val }))
                                }
                            />
                        </Col>
                    ))}
                    <Col xs="12">
                        <Button type="submit" size="lg" className="bg-black rounded-0 w-100 text-white">
                            ثبت
                        </Button>
                    </Col>
                </Form>
            </Col>
        </div>
    )
}

export default UserMargin