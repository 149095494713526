import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Modal, Badge } from "..";
import arrow1 from "../../assets/icons/server/arrow 1.svg";
import arrow2 from "../../assets/icons/server/arrow 2.svg";
import arrow3 from "../../assets/icons/server/arrow 3.svg";
import "./index.scss";
export default function ServerCard({
  _id = "",
  available = true,
  name = "",
  oldPrice = 0,
  price = 0,
  oldPriceUsd = 0,
  priceUsd = 0,
  off = 0,
  usage = "",
  addToCart = () => { },
}) {
  const [showAvailable, setShowAvailable] = useState(false);
  return (
    <React.Fragment>
      <div className="ServerCard row w-100 text-center d-flex  align-items-center justify-content-between py-2">
        <Col xs="12" lg="8" className=" d-flex flex-column justify-content-around h-100 px-4">
          <p className="fs-18 fw-700 text-black text-start">پلن انتخابی شما</p>
          {/* <p>{name}</p> */}
          <p className="fs-14 fw-400 text-black text-start">{usage}</p>
        </Col>

        <Col  className=" d-flex flex-column justify-content-around h-100 px-4" xs="12" lg="4">
          <div className="my-4">
            <p className="fs-18 fw-700 text-black text-start">هزینه سالانه:</p>
            {off !== 0 && (
              <div className="fs-6 d-flex flex-center col-gap-1 w-fit">
                <Badge label={`${off}%`} variant="danger" />
                <span className="text-decoration-line-through">
                  {(+oldPrice).toLocaleString()} تومان{" - "}
                  {(+oldPriceUsd).toLocaleString()} USD
                </span>
              </div>
            )}
            <p className="fs-18 fw-700 text-tufts-blue text-start">
              {(+price).toLocaleString()} تومان
              ({(+priceUsd).toLocaleString()} USD)
            </p>
          </div>
          <div className=" d-flex flex-center">
            <Button
              variant="black"
              className="rounded-0 w-100"
              size="lg"
              onClick={() => {
                if (available) return addToCart(_id);
                return setShowAvailable(true);
              }}
            >
              خرید
              <i className="bi bi-chevron-left me-1" />
            </Button>
          </div>
        </Col>
      </div>


      {!available && (
        <Modal
          type="warning"
          title="توجه!"
          show={showAvailable}
          onHide={setShowAvailable}
        >
          <p className="usage-text text-justify">
            سرویس انتخاب شده در حال حاضر موجود نیست و در صورت خرید حداکثر تا 48
            ساعت آینده فعال میگردد.
          </p>
          <div className="buttons mt-5">
            <Button
              variant="danger"
              className="text-light"
              onClick={() => setShowAvailable(false)}
            >
              بستن
            </Button>
            <Button
              variant="success"
              className="text-light"
              onClick={() => {
                addToCart(_id);
                setShowAvailable(false);
              }}
            >
              خرید
            </Button>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
