import appStoreIcon from "../assets/icons/app-store.svg";
// import googlePlayIcon from "../assets/icons/google-play.svg";
import androidIcon from "../assets/icons/android.svg";
const downloadItems = [
  {
    label: "با لینک مستقیم",
    link: "/ecomarvel.apk",
    icon: androidIcon,
    download: "ecomarvel.apk",
  },
  {
    label: "از App Store",
    link: "https://apps.apple.com/us/app/ecot1/id1560742037",
    icon: appStoreIcon,
  },
  // {
  //  label: "از Google Play",
  //  link: "https://play.google.com/store/apps/details?id=com.tradebymarvel.app",
  //   icon: googlePlayIcon,
  //},
];
export default downloadItems;
