import { useRef, useEffect, useState } from "react";
import { showNumber } from "../../methods";
import "./index.scss";
export default function VideoPlayer({
  src = "",
  thumbnail = "",
  title = null,
  height = "auto",
  type = ""
  // description = null,
}) {
  const videoRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isPlay, setIsPlay] = useState(true);
  const [isMute, setIsMute] = useState(false);
  const [timeInfo, setTimeInfo] = useState({ currentTime: 0, duration: 0 });
  // {description && (
  //   <p
  //     className="w-100 mt-1 px-2"
  //     dangerouslySetInnerHTML={{ __html: description }}
  //   />
  // )}
  const showVideoTime = () => {
    const currentTime = Math.ceil(timeInfo.currentTime);
    const sec = currentTime & 60;
    const min = currentTime - sec;
    const hour = currentTime - min - sec;
    const time = [hour / 60 / 60, min / 60, sec]
      .map(Math.floor)
      .map(showNumber)
      .join(":");
    return <span className="text-white">{time}</span>;
  };
  const pauseVideo = () => {
    videoRef.current?.pause();
  };
  const playVideo = () => {
    videoRef.current?.play();
  };
  const handleTouchStart = () => {
    setIsPlay(false);
  };
  const handleTouchEnd = () => {
    setIsPlay(true);
    videoRef.current.currentTime = timeInfo.currentTime;
  };
  useEffect(isPlay ? playVideo : pauseVideo, [showVideo, isPlay]);
  useEffect(() => {
    videoPlayerRef.current?.classList.toggle("active", showVideo);
  }, [showVideo]);
  return (
    <div
      style={{ height }}
      ref={videoPlayerRef}
      className="VideoPlayer position-relative w-100 rounded overflow-hidden bg-white shadow-sm"
    >
      <div className="title position-absolute w-100 bg-dark bg-opacity-50 text-white text-center p-2">
        {title}
      </div>
      {showVideo ? (
        <video
          ref={videoRef}
          autoPlay
          onLoadedData={({ target }) =>
            setTimeInfo((p) => ({ ...p, duration: target.duration }))
          }
          className="video w-100 h-100 object-fit-contain d-block"
          muted={isMute}
          onTimeUpdate={({ target }) =>
            setTimeInfo((p) => ({ ...p, currentTime: target.currentTime }))
          }
        >
          <source src={src} type={type} />
        </video>
      ) : (
        <div style={{minHeight : "400px"}} className="d-flex flex-center position-relative bg-black w-100 h-100 p-4">
          <button
            onClick={() => setShowVideo(true)}
            style={{ zIndex: "10" }}
            className="position-absolute d-flex flex-center bi bi-play-fill text-primary fs-20 border rounded-circle p-2 border-primary border-2"
          />
          <img
            className="thumbnail w-100 h-100 object-fit-cover"
            src={thumbnail}
            alt={title}
          />
        </div>
      )}
      <div
        dir="ltr"
        className="controls d-flex align-items-center w-100 position-absolute bg-dark bg-opacity-50 gap-3 px-3 py-2"
      >
        <button
          className={`bi bi-${isPlay ? "pause" : "play"}-fill `}
          onClick={() => setIsPlay((p) => !p)}
        />
        <input
          type="range"
          className="text-primary"
          step={1}
          min={0}
          max={timeInfo.duration}
          value={timeInfo.currentTime}
          onTouchStart={handleTouchStart}
          onMouseDown={handleTouchStart}
          onMouseUp={handleTouchEnd}
          onTouchEnd={handleTouchEnd}
          onInput={({ target }) =>
            setTimeInfo((p) => ({ ...p, currentTime: target.value }))
          }
        />
        {showVideoTime()}
        <button
          className={`bi bi-volume-${isMute ? "mute" : "up"}-fill`}
          onClick={() => setIsMute((p) => !p)}
        />
      </div>
    </div>
  );
}
