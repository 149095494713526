import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Select, Modal, Input, MultiSelect } from "../../../../components";
import { axios } from "../../../../boot";
import { rules, toast } from "../../../../methods";
import {  useGetEducationCat } from "../../../../hooks";
import { trueAndFalse } from "../../../../constants";

function Education() {
    const { id } = useParams();
    const isNew = id === "new"
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [educationCat, getEducationCat ] = useGetEducationCat()
    const getData = () => {
        if (!isNew) {
            const url = `/admins/tutorial/${id}`;
            axios.get(url).then(({ data }) => {
                console.log(data)
                setData(data);
            });
        }
    };
    const files = ["image" , "video"];
    const addEducation = () => {
        const url = "/admins/tutorial"
        const body = new FormData();
        Object.keys(data).forEach((key) => {
            const isFile = files.includes(key);
            body.append(key, isFile ? data[key][0] : data[key]);
        });
        axios.post(url, body).then(({ data }) => {
            let text = " آموزش جدید با موفقیت افزوده شد"
            toast({ text })
            navigate(-1)
        })
    }
    const updateEducation = () => {
        const url = `/admins/${id}/tutorial`
        const body = {}
        body.title = data?.title
        body.subCategory = data?.subCategory
        axios.patch(url, body).then(({ data }) => {
            let text = " آموزش با موفقیت ویرایش شد"
            toast({ text });
            navigate(-1);
        })
    }
    const fromControls = [
        {
            state: "title",
            label: "عنوان",
        },
        {
            state: "image",
            label: "عکس",
            type: "file"
        },
        {
            tag : Select,
            state: "subCategory",
            label: "زیردسته بندی",
            items : educationCat?.map((e) => ({name : e.name , id : e._id}))
        },

        {
            state: "video",
            label: "ویدیو",
            type : "file"
        },
    ]
    const fromControls2 = [
        {
            state: "title",
            label: "عنوان",
        },
        // {
        //     state: "image",
        //     label: "عکس",
        //     type: "file"
        // },
        {
            tag : Select,
            state: "subCategory",
            label: "زیردسته بندی",
            items : educationCat?.map((e) => ({name : e.name , id : e._id}))
        },

        // {
        //     state: "video",
        //     label: "ویدیو",
        //     type : "file"
        // },
    ]
    const deleteEducation = () => {
        const url = `/admins/${id}/tutorial`
        axios.delete(url).then(() => {
            let text = " آموزش با موفقیت حذف شد"
            toast({ text });
            navigate(-1);
        });
    }
    const handleForm = {
        true : fromControls,
        false : fromControls2
    }
    useEffect(getEducationCat,[])
    useEffect(getData, []);
    return (
        <Container className="Product">
            <Form onSubmit={isNew ? addEducation : updateEducation}>
                <Row>

                    {handleForm[isNew].map((item, index) => (
                        <Col
                            key={index}
                            xs="12"
                            md="6"
                            lg="4"
                        >
                            {React.createElement(item.tag ?? Input, {
                                rules: rules.required,
                                value: data[item.state],
                                setValue: (val) =>
                                    setData((p) => ({ ...p, [item.state]: val })),
                                ...item,
                            })}
                        </Col>
                    ))}
                    <Col xs="12" className="d-flex flex-center">
                        <Button type="submit" variant="primary" className="text-light">
                            {isNew ? " ثبت آموزش جدید" : "ثبت تغییرات"}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {!isNew &&
                <div className="d-flex flex-center flex-wrap my-4">
                    <Button onClick={() => deleteEducation()} variant="danger text-white" className="m-2 ">حذف آموزش</Button>
                </div>
            }
            {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
        </Container>
    );
}

export default Education;