import { useState, useContext } from 'react'

import { axios } from '../../../boot'
import { calcDiscount, toast } from '../../../methods'
import { Form, Input } from '../../../components'
import { Col } from 'react-bootstrap'
import { rules } from '../../../constants'
import Context from './_context'
const ZarinPayment = () => {
    const [data, setData] = useState({ useReferral: false })
    const [ price , setPrice ] = useState(0)
    const [page, setPage, planDetails, setPlanDetails] = useContext(Context);
    const [ discount , setDiscount ] = useState(false)
    const handlediscountCode = () => {
        const url = `/discounts/${data?.discountCode}/getDiscount`

        axios.get(url).then(({ data }) => {
            let finalPrice = calcDiscount(planDetails?.toomanAmount , data?.amount)
            setPrice(planDetails?.toomanAmount)
            let text = `کد تخفیف قابل قبول است. شما دارای ${data?.amount} درصد تخفیف هستین`
            setPlanDetails(p => ({...p , toomanAmount : finalPrice}))
            toast({ text })
            setDiscount(p => !p)
            
        })
    }
    const handleIdentification = () => {
        const url = `/users/RegisterReferalCode`
        const body = { ReferralCode: data?.ReferralCode }
        axios.post(url, body).then(({ data }) => {
            // console.log(data)
            toast({ text: data?.message })
            setData(p => ({ ...p, "useReferral": true }))
        })
    }
    const handleZarinPal = () => {
        const url = `/payments/addzarinPayment`
        const body = {}
        body.toomanAmount = Number(planDetails?.toomanAmount)
        // body.phone = user?.phone
        body.selectedPlan = planDetails?._id
        body.inventory = Number(data?.inventory)
        body.discountCode = data?.discountCode
        body.useReferral = data?.useReferral
        if(data?.discountCode) body.discountCode = data?.discountCode
        axios.post(url, body).then(({ data }) => {
            window.location.href = data.url;
        })

    }
    const formControl = [
        {
            label: "کد تخفیف",
            placeholder : "کد تخفیف(اختیاری) وارد شود",
            state: "discountCode",
            type: "text",
            className: "border",
            append: <button type='button' disabled={discount} className='btn btn-black rounded-0' onClick={() => handlediscountCode()}>ثبت</button>,
            rules : rules.optional
        },
        {
            label: "کد معرف",
            placeholder : "کدمعرف(اختیاری) وارد شود",
            state: "ReferralCode",
            type: "text",
            className: "border",
            append: <button type='button' className='btn btn-black rounded-0' onClick={() => handleIdentification()}>ثبت</button>,
            rules : rules.optional
        },
        {
            label: "موجودی دلاری",
            state: "inventory",
            className: "border",
            placeholder: "موجودی(دلاری) برای طبقه بندی کلاس‌های آموزشی",
            type: "number",
        },
    ]
    return (
        <div className="w-100">
            <Form className="row d-flex flex-column align-items-center justify-content-center" onSubmit={handleZarinPal}>
                {formControl.map((item) =>
                    <Col key={item.state} xs="12" lg="5">
                        <Input

                            {...item}
                            value={data[item.state]}
                            setValue={val => setData(p => ({ ...p, [item.state]: val }))}
                        />
                    </Col>
                )}
                <Col className="d-flex align-items-center justify-centent-start justify-content-lg-center my-2" xs="12">
                    <div className="">
                        <p className="text-black  fs-16 fw-700 my-1 fs-18">مبلغ واریزی : {planDetails?.toomanAmount} تومان</p>
                    </div>
                </Col>
                <Col xs="12" lg="5">
                    <button className="btn btn-tufts-blue w-100 py-2 rounded-0 fs-14 fw-700 text-white">پرداخت</button>
                </Col>
                <Col xs="12" lg="5">
                    <button type='button' onClick={() => {setPage("payment");setPlanDetails(p => ({...p , toomanAmount : price}))}} className="btn btn-black w-100 py-2 rounded-0 fs-14 fw-700 text-white">بازگشت</button>
                </Col>
            </Form>
        </div>
    )
}

export default ZarinPayment