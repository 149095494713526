import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, DownloadSection, SupportBtn } from "../../components";
import { showCartCount } from "../../methods";
import { useShowFullName } from "../../hooks";
import { Container } from "react-bootstrap";
import Navbar from "./Navbar";
import Menu from "./Menu";
import Footer from "./Footer";
import RegisterModal from "./RegisterModal";
import "./index.scss";
import { rolesPath } from "../../constants";
import blackLogo from '../../assets/logo/blackLogo.png'

export default function LandingLayout() {
  const showFullName = useShowFullName();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const isLogged = useSelector((s) => s.isLogged);
  const cartCount = useSelector((s) => s.cartCount);
  const role = useSelector((s) => s.role);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);
  return (
    <div className="bg-white LandingLayout">
      <Container  className="position-relative LandingLayout-container">
        <header style={{zIndex : 10}} className="bg-white py-2 position-sticky">
          <div className="w-100 mx-auto d-flex align-items-center">
            {/* <img src={blackLogo} alt="logo" className="d-block d-xl-block" /> */}
            
            <Navbar />
            <i
              onClick={() => setShowMenu(true)}
              className="d-block d-xl-none bi bi-list fs-20 cursor-pointer text-black position-absolute start-0"
            />
            
            <Menu show={showMenu} setShow={setShowMenu} />
            <div className="user-control d-flex flex-center col-gap-3 me-auto">
              {isLogged ? (
                <React.Fragment>
                  <NavLink to={rolesPath[role]} className="btn btn-outline-black btn-sm rounded-0 d-none d-xl-block">
                    <i className="bi bi-person-fill ms-1" />
                    {showFullName()}
                  </NavLink>
                  <NavLink
                    to="/cart"
                    className="position-relative btn btn-outline-black btn-sm rounded-0 d-none d-xl-block"
                  >
                    {cartCount !== 0 && (
                      <Badge variant="danger" label={showCartCount(cartCount)} />
                    )}
                    <i className="bi bi-cart-fill ms-1" />
                    سبد خرید
                  </NavLink>
                </React.Fragment>
              ) : (
                <Link
                  to='/login'
                  className="btn btn-black btn-sm px-4 py-1 fs-13 rounded-0 text-white d-none d-xl-block"
                >
                  ورود / ثبت نام
                </Link>
              )}
            </div>
          </div>
        </header>
        </Container>
        <main className="py-2">
          <Outlet />
          <SupportBtn/>
        </main>
      
      {/* <DownloadSection /> */}
        <Footer />
        <RegisterModal />
    </div>
  );
}
