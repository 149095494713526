import { useState } from "react";
import { useNavigate } from "react-router";
import { Form, Modal, Button, Input } from "../../../components";
import { toast, convertPhone } from "../../../methods";
import { axios } from "../../../boot";
import { Container, Row, Col, FormControl, Form as Formm } from "react-bootstrap";
import './index.scss'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { rolesPath, rules } from "../../../constants";
import SubmitVideo from "./SubmitVideo";
export default function Login() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [ show , setShow ] = useState(false)
  const dispatch = useDispatch();
  const FormControl = [
    {
      label: "شماره تلفن یا ایمیل",
      state: "phone",
      type: "text",
    },
    {
      label: "پسورد",
      state: "password",
      type: "password",
      rules: rules.password

    },
  ]
  const setIsLogged = (data = true) => {
    dispatch({ type: "SET_IS_LOGGED", data });
  };
  const setRole = (data = "") => {
    dispatch({ type: "SET_ROLE", data });
  };
  const submit = () => {
    let body = {}
    let url = ""
    let reg = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    if (reg.test(data.phone)) {
      url = "/authentication/Emaillogin";
      body.email = data?.phone
      body.password = data?.password
    } else {
      url = "/authentication/login";
      body.phone = convertPhone(data?.phone)
      body.password = data?.password
    }
    axios.post(url, body).then(({ data }) => {
      const token = data.token;
      const role = data?.user?.role
      axios.defaults.headers["x-auth-token"] = token;
      localStorage.setItem("token", token);
      localStorage.setItem("priviliages", JSON.stringify(data?.user?.priviliages))
      const text = "ورود شما موفقیت آمیز بود.";
      toast({ text });
      setRole(role);
      setIsLogged(true);
      navigate("/", { replace: true });
    });
  };
  return (
    <div className="Login">
      <Modal size="xl" show={show} onHide={setShow}>
        <SubmitVideo />
      </Modal>
      <div className="d-flex align-items-center justify-content-between">
        <p className="fs-30 fw-700 text-black">خوش آمدید به مارول ترید</p>
        <Link to="/"><i class="bi bi-arrow-left-short fs-25 fw-700"></i></Link>
      </div>
      <Form
        onSubmit={submit}
        className=" d-flex flex-column flex-center text-center "
      >
        {FormControl.map(item =>
          <Input
            key={item.state}
            {...item}
            value={data[item.state]}
            setValue={(val) =>
              setData((p) => ({ ...p, [item.state]: val }))
            }
            className="border "
          />
        )}

        <div className="w-100  d-flex flex-wrap align-items-center justify-content-between px-2">
          <Link className="text-tufts-blue fs-14 fw-500 my-1" to="reset-password">فراموشی رمز عبور</Link>
        </div>
        <Col xs="12" lg="12">
          <Button variant="black" className="w-100 mt-4 rounded-0 py-2" type="submit">
            ورود
          </Button>
        </Col>
        <Col xs="12" lg="12">
          <Button onClick={()=>setShow(true)} variant="primary" className="w-100 mt-4 rounded-0 py-2 text-white" type="button">
          آموزش ثبت نام و خرید اشتراک در مارول
          </Button>
        </Col>


        <p className="fs-16 fw-400 my-4">حساب کاربری ندارید؟  <Link className="fs-16 fw-700 text-black" to="sign-up">ثبت نام کنید</Link></p>
      </Form>

    </div>
  );
}
