import { Container } from "react-bootstrap";
import { Accordion } from "../../../components";
import { questions, socialNetworks } from "../../../constants";

export default function Questions() {
  return (
    <div className="Questions">
      <Container>
        <h3 className="text-info py-4 d-block text-start">
          پر تکرارترین پرسش‌ها
        </h3>
        <div className="questions-section d-flex flex-column flex-center row-gap-3">
          {questions.map((item, index) => (
            <Accordion key={index} variant={item.variant} title={item.label}>
              <p className="white-space-pre-wrap text-justify">{item.value}</p>
            </Accordion>
          ))}
        </div>
        <div className="w-100 rounded-3 shadow-sm bg-info lh-lg p-3 py-lg-4 px-lg-5 mt-5">
          <p className="text-light text-center">
            کاربر گرامی، لطفا در صورت وجود هرگونه سوال یا ابهامی، میتوانید از
            طریق بخش پشتیبانی با تیم مارول ترید در ارتباط باشید و پاسخ سوالات
            خود را دریافت کنید.
            <br />
            <a
              href={socialNetworks[3].link}
              target="_blank"
              rel="noreferrer"
              className="transition text-light btn-link"
            >
              {`پشتیبانی تلگرام: ${socialNetworks[3].link}`}
            </a>
          </p>
        </div>
      </Container>
    </div>
  );
}
