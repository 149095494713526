import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { Form, InputGroup, Button, Modal } from "../../../components";
import { rules, toast } from "../../../methods";
import { axios } from "../../../boot";

export default function AddNewProduct({
  show = false,
  onHide = () => {},
  afterSubmit = () => {},
}) {
  const [data, setData] = useState({});
  const fromControls = [
    {
      label: "نام پلن",
      state: "name",
    },
    {
      label: "قیمت(تومان)",
      state: "price",
      type: "number",
    },
    {
      label: "قیمت(USD)",
      state: "priceUsd",
      type: "number",
    },
    {
      label: "درصد تخفیف",
      state: "off",
      type: "number",
    },
    {
      label: "مقدار RAM",
      state: "ram",
    },
    {
      label: "مقدار CPU",
      state: "cpu",
    },
    {
      label: "حافظه ذخیره‌سازی",
      state: "storage",
    },
    {
      label: "پهنای باند",
      state: "bandwidth",
    },
    {
      label: "محل سرور(ip سرور)",
      state: "location",
    },
    {
      label: "دیتاسنتر",
      state: "datacenter",
    },
    {
      label: "توضیحات",
      state: "usage",
      as: "textarea",
    },
  ];
  const submit = () => {
    const url = "/admins/pub/products";
    const body = cloneDeep(data);
    body.price = Number(body.price);
    body.priceUsd = Number(body.priceUsd);
    body.off = Number(body.off);
    body.available = "true";
    axios.post(url, body).then(() => {
      const text = "پلن جدید با موفقیت اضافه شد.";
      toast({ text });
      onHide(false);
      afterSubmit();
    });
  };
  return (
    <Modal title="افزودن ویندوز سرور ترید" show={show} onHide={onHide}>
      <Form onSubmit={submit}>
        <Row>
          {fromControls.map((item, index) => (
            <Col key={index} xs="12">
              <InputGroup
                {...item}
                rules={rules.required}
                value={data[item.state]}
                setValue={(val) =>
                  setData((p) => ({ ...p, [item.state]: val }))
                }
              />
            </Col>
          ))}
          <Button type="submit" variant="success" className="text-light">
            افزودن
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
