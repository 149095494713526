import { useState } from "react";
import { axios } from "../boot";

export default function useGetEducationCat() {
  const [ educationCat , setEducationCat ] = useState([]);
  const getEducationCat  = () => {
    const url = "/admins/tutorialCat";
    axios.get(url).then((res) => {
      
      const data = res.data.data;
      
      setEducationCat(data);
    });

  };
  return [educationCat, getEducationCat ];
}
