import React, { useState, useEffect } from "react";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ChoosenPlan, ServerCard, Table, VideoPlayer , ChooseVps } from "../../../components";
import { axios } from "../../../boot";
import { toast } from "../../../methods";
import windowsServerImg from "../../../assets/icons/windows-server-img.png";
import "./index.scss";
import Windows from '../../../assets/images/windowsTrade/windows.png'
import Ornament from '../../../assets/images/windowsTrade/Ornament.png'


export default function WindowsServerTrade() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const isLogged = useSelector((state) => state.isLogged);
  // const profile = useSelector((state) => state.profile);
  // const [products, setProducts] = useState({ plans: [], allPlans: [] });
  // const [details, setDetails] = useState({
  //   plans: []
  // })
  // const [plan, setPlan] = useState({})
  // const addToCart = (id = "") => {
  //   if (!isLogged) {
  //     const text = "برای خرید باید وارد حساب کاربری خود شوید.";
  //     toast({ text, type: "error" });
  //     return;
  //   }
  //   if (profile.needRegister) {
  //     const text = "برای خرید باید حساب کاربری خود را تکمیل کنید.";
  //     toast({ text, type: "error" });
  //     navigate({ search: "needRegister=true" });
  //     return;
  //   }
  //   const url = "/carts";
  //   const body = {
  //     productId: id,
  //     count: 1,
  //   };
  //   axios.post(url, body).then(() => {
  //     dispatch({ type: "ADD_TO_CART_COUNT" });
  //     const text = "محصول مورد نظر با موفقیت به سبد خرید اضافه شد.";
  //     toast({ text });
  //   });
  // };
  // const getProducts = () => {
  //   const url = "/pub/products";
  //   axios.get(url).then(({ data }) => {
  //     let plans = data.map((plan) => plan.location);
  //     plans = [...new Set(plans)];
  //     plans = plans.map((location) => ({
  //       location,
  //       plans: data.filter((e) => e.location === location),
  //     }));
  //     console.log("plans : ", plans)
  //     console.log("data : ", data)
  //     setDetails(p => p=plans[0])
  //     setProducts({ plans, allPlans: data });
  //   });
  // };
  // useEffect(getProducts, []);
  return (
    <Container className="WindowsServerTrade d-flex flex-column row-gap-5">
      <Row className="d-flex align-items-start">
        <Col className="px-4" xs="12" lg="7">
          <p className="fs-50 fw-700 text-black my-4">ویندوز سرور چیست ؟</p>
          <p className="fs-18 fw-400 text-black my-4">Vps (virtual private server) که معنای لغوی آن سرور مجازی خصوصی است.در سرور های مجازی از تکنولوژی مجازی سازی استفاده میشود.در این سرور های مجازی به جای اینکه چند سخت افزار مستقل برای هر سرور اختصاص داده شود ؛ یک سخت افزار قدرتمند را با استفاده نرم افزار ، به چند سرور شبیه سازی میکنند .در حال حاضر استفاده از VPN برای معاملات امن نیست زیرا از IP متفاوت استفاده می کنند و این امر باعث میشود مکان فعلی شما (کشور های ممنوعه از جمله ایران و ... ) مشخص شود . ملاک ما در استفاده از VPS ثابت بودن IP آن است که یکی از بهترین روش ها برای جلوگیری از ایجاد مشکل در انجام معاملات در پلتفرم های خارجی مانند بایننس ، کوکوین و ... .شما میتوانید با تهیه VPS از تغییر مکرر IP خود و مسدود شدن و حساب خود جلوگیری کنید .</p>
          <p className="text-black fs-30 fw-700 mt-5 mb-4">
            ویژگی های ویندوز سرور های مارول:
          </p>
          <ul className="mx-4 serverOption">
            <li className="fs-16 fw-700 my-1">
              ارزان قیمت
            </li>
            <li className="fs-16 fw-700 my-1">
              ارائه شده در پیکربندی های مختلف
            </li>
            <li className="fs-16 fw-700 my-1">
              تحویل آنی
            </li>
          </ul>
          <p className="text-tufts-blue fs-20 fw-700 mt-5">
            توجه داشته باشید که ویندوز سرور خریداری شده فقط تا 24 ساعت پس از
            واریز<br /> وجه در صورت داشتن مشکل و برطرف نشدن آن قابلیت مرجوعی دارد.
          </p>
        </Col>
        <Col className="py-4 px-0 d-flex flex-column justify-content-around align-items-end" xs="12" lg="5">
          <img className="w-100 mb-4" src={Windows} alt="windows trade" />
          <img className="my-4 w-100" src={Ornament} alt="ornament" />
        </Col>
      </Row>

      <Row className="bg-black d-flex flex-column justify-content-around align-items-center p-4">
        <p className="text-center fs-30 fw-700 text-white my-4 col-12">
          آموزش ثبت سفارش، خرید و <br /> استفاده از ویندوز ترید
        </p>
        <Col className="my-4" xs="12" lg="6">
          <VideoPlayer
            title="آموزش ثبت سفارش، خرید و استفاده از ویندوز ترید"
            thumbnail={windowsServerImg}
            src="https://marveltrade.org/downloads/how_to123.m4v"
          />
        </Col>
      </Row>

      <div
        // marginTop: "-100px"
        style={{ padding: "50px 0" }}
        className="bg-white"
      >
        {/* <Container>
          <Row>
            <Col xs="12" lg="8"></Col>
            <Col xs="12" lg="4"></Col>
          </Row>
        </Container> */}
        <ChooseVps/>
        {/* <Container>
          <Row className="">
            <Col className="" xs="12" lg="12">
              <p className=" my-4 fs-18 fw-500 text-black">کشور سرور را انتخاب کنید:</p>
              <Tabs
                dir="ltr"
                defaultActiveKey={0}
                className="tab-btns d-flex justify-content-center justify-content-lg-end gap-3 border-0"
              >
                {products.plans.map((item, index) => (
                  <Tab
                    className="my-3"
                    key={index}
                    eventKey={index}

                    title={
                      <span className="tab-btn btn btn-outline-light">
                        {item.location}
                      </span>
                    }
                  >
                    <p className="fs-18 fw-500 text-black my-4">نوع پلن را انتخاب کنید:</p>

                    <Tabs
                      dir="ltr"
                      variant="pill"
                      defaultActiveKey={0}
                      className="tab-btns d-flex justify-content-center justify-content-lg-end gap-3"
                    >
                      {item.plans.map((plan, index) => (

                        <Tab
                          className="my-3"
                          key={index}
                          eventKey={index}
                          title={
                            <span  className="tab-btn btn btn-outline-light ">
                              {plan.name}
                            </span>
                          }
                        >
                          <div className="row align-items-start">
                            <Col xs="12" lg="8"><ChoosenPlan {...plan} /></Col>
                            <Col xs="12" lg="4"><ServerCard {...plan} addToCart={addToCart} /></Col>
                          </div>
                          
                        </Tab>
                      ))}
                    </Tabs>
                  </Tab>
                ))}
              </Tabs>
            </Col>
          
          </Row>

         

        </Container> */}
      </div>
    </Container>
  );
}
