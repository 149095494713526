import { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Table, Button, Pagination } from '../../../../components'
import { axios, moment } from '../../../../boot'
import { useNavigate } from 'react-router'
import { purchaseStatus } from '../../../../constants'
import { convertPhone } from '../../../../methods'

const EducationCategories = () => {
    const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
    const navigate = useNavigate();
    const [params, setParams] = useState({ page: 1, ...basicParams });
    const [ tutorials , setTutorials ] = useState([])
    const [pages, setPages] = useState({})
    const saveParams = () => {
        const value = JSON.stringify(params);
        sessionStorage.setItem("params", value);
    };
    const getTutorials = () => {
        const url = "/admins/tutorialCat"
        saveParams();
        const config = {
            params: {
                perPage: 20,
                ...params,
            },
        };
        axios.get(url).then(({ data }) => {
            console.log(data)
            setTutorials(data?.data)
            setPages(data?.pages)
        })
    }
    useEffect(getTutorials, [params])
    return (
        <Container className="">
            <Row className="w-100">
                <Col xs="12" md="12">
                    <Table className="text-center">
                        <thead>
                            <tr>
                                <th className="">نام دسته بندی</th>
                                <th className="">تاریخ ثبت</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tutorials?.map((e) => (
                                <tr key={e._id} onClick={() => navigate(e._id)}>
                                    <td>{e.name}</td>
                                    <td>{moment.miladiToShamsi({ date: e.createdAt })}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="8">
                                    <Button
                                        onClick={() => navigate("new")}
                                        variant="success"
                                        className="text-light"
                                    >
                                        اضافه کردن دسته بندی جدید
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {/* <Pagination  
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      /> */}
        </Container>
    )
}

export default EducationCategories