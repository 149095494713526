import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Table } from "../../../components";
import { axios, moment } from "../../../boot";
import { orderStatus } from "../../../constants";
export default function UsdOrders() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const getOrders = () => {
    const url = "/admins/orders";
    axios.get(url).then(({ data }) => {
      setOrders(data.data);
    });
  };
  useEffect(getOrders, []);
  return (
    <Container className="Products">
      <Row className="w-100">
        <Col xs="12" md="10">
          <Table className="text-center">
            <thead>
              <tr>
                <th>کاربر</th>
                <th>قیمت USD</th>
                <th>تاریخ</th>
                <th>وضعیت</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((e) => (
                <tr key={e._id} onClick={() => navigate(e._id)}>
                  <td>{`${e.user[0]?.firstName} ${e.user[0]?.lastName}`}</td>
                  <td>{e.totalUsd?.toLocaleString()} USD</td>
                  <td>{moment.miladiToShamsi({ date: e?.createdAt })}</td>
                  <td>{orderStatus.badge(e.status)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="4" className="h5 py-3 text-danger">
                  <i className="bi bi-info fs-4" />
                  سفارشی موجود نیست
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
