import React from "react";

const setVariant = (index = 0) => {
  if (index % 3 === 0) return "primary";
  if (index % 3 === 1) return "info";
  if (index % 3 === 2) return "secondary";
};
const questions = [
  {
    label: "تحلیل های مارول ترید در کجا قرار میگیرد ؟",
    value:
      " تمامی تحلیل های مارول ترید در اپلیکیشن مارول قرار میگیرد که میتوانید آن را از سایت مارول ترید دانلود کنید .",
  },
  {
    label: "چگونه اکانت مشاوره اقتصادی کریپتو مارول را تهیه کنیم ؟",
    value: (
      <React.Fragment>
        {"از طریق لینک زیر میتوانید برای خرید اقدام کنید\n"}
        <a
          href="http://pr.marveltradepayments.com"
          target="_blank"
          rel="noreferrer"
          className="btn btn-link"
        >
          http://pr.marveltradepayments.com
        </a>
      </React.Fragment>
    ),
  },
  {
    label: "پرداخت هزینه اکانت مشاوره اقتصادی به چه صورت است ؟",
    value:
      "پرداخت به صورت تتر انجام می شود ؛  توجه کنید پرداخت به اشکال دیگری ائم از ریالی ، کارت به کارت ، خرید اینترنتی و ...  برای کاربران مقدور نمی باشد.",
  },
  {
    label: "شماره تراکنش (txid) جیست ؟",
    value:
      "بعد از هر انتقال رمز ارز ، آن والت یا صرافی که شما از آن انتقال را انجام داده اید به شما کدی جهت پیگیری تحت عنوان txid یا hash میدهد که شما آن را باید در سایت مارول ترید در قسمت شماره تراکنش به صورت کامل و صحیح ثبت کنید.",
  },
  {
    label:
      "آیا گروه مارول ترید خدماتی در سایر بازارهای مالی (فارکس و ... ) ارائه میدهد ؟",
    value:
      "خیر در حال حاضر گروه مارول ترید فقط در زمینه ارزهای دیجیتال خدمات ارائه میدهد.",
  },
  {
    label:
      "والت های سخت افزاری مناسب چه کسانی هستند و کدام آن برای استفاده مناسب تر می باشند ؟",
    value:
      "این نوع کیف پول ها مناسب برای کاربرانی هستند که قصد هولد بلند مدت ارزهای دیجیتال خود را دارند\nاما با تجربه به دست آمده از سمت کاربران این والت ها به شما پیشنهاد میشود  و ترتیب مهمی برای معرفی وجود ندارد چون باید کاربران نسبت به نیاز های خود ولتشان را از بین ولت های دیگر انتخاب کنند.\nکیف پول Ledger Nano S\nکیف پول Trezor Model T\nکیف پول Trezor One\nکیف پول Ledger Nano X\nکیف پول KeepKey\nکیف پول BitBox02\nکیف پول Coldcard Mk3\nکیف پول CoolWallet S\nکیف پول CoolWallet Pro\n",
  },
  {
    label:
      "والت های نرم افزاری مناسب چه کسانی هستند و کدام آن برای استفاده مناسب تر می باشند؟",
    value:
      "والت های نرم افزاری مناسب برای کسانی است که جا به جایی ارزی بیشتری نسبت به افراد هولد کننده دارند\nمارول ترید به دلیل تغییر قوانین هر روزه این نوع پلتفرم ها نمی تواند به صورت قطعی ولتی را به شما معرفی کند اما با تجربه به دست آمده از سمت کاربران این والت ها به شما پیشنهاد میشود که از (TRUST WALLET) یا  (SAFEPAL) استفاده کنید اما بهتر است کاربران نسبت به نیاز های خود ولتشان را از بین ولت های دیگر انتخاب کنند.\nو در آخر به تمامی کاربران پیشنهاد میشود که به هیچ عنوان با IP کشور ایران به این والت ها ورود نکنند و حتما با IP ثابت و غیر ایران به این ولت ها کانکت شوند.",
  },
  {
    label:
      "نحوه استفاده از ویندوز سرور (VPS) در کامپیوتر شخصی به چه صورت میباشد ؟",
    value: (
      <React.Fragment>
        {"لطفا از طریق لینک زیر ویدئو آموزشی را مشاهده کنید\n"}
        <a
          href="https://www.aparat.com/v/5Oyq6"
          target="_blank"
          rel="noreferrer"
          className="btn btn-link"
        >
          https://www.aparat.com/v/5Oyq6
        </a>
      </React.Fragment>
    ),
  },
  {
    label: "آیا با گوشی هم میتوان به ویندوزسرور (VPS) متصل شد ؟",
    value: (
      <React.Fragment>
        {"بله ؛ لطفا از طریق لینک زیر ویدئو آموزشی را مشاهده کنید,\n"}
        <a
          href="https://www.instagram.com/tv/CREtDklp8yA/?utm_medium=copy_link"
          target="_blank"
          rel="noreferrer"
          className="btn btn-link"
        >
          https://www.instagram.com/tv/CREtDklp8yA/?utm_medium=copy_link
        </a>
      </React.Fragment>
    ),
  },
  {
    label: "آیا با ویندوز سرور (VPS) می توان احراز هویت انجام داد ؟",
    value:
      "زیرا برای انجام احراز هویت ؛ شما نیازمند به متصل کردن دوربین برای تشخیص چهره به صورت زنده را دارید که این کار انجام نمیشود .",
  },
  {
    label: "رمز ویندوز سرور(VPS) را چگونه تغییر دهیم ؟",
    value:
      "تغییر رمز ویندوز سرور (VPS) همانند تعویض رمز ویندوز کامپیوتر شخصی می باشد.",
  },
].map((item, index) => ({ ...item, variant: setVariant(index) }));

export default questions;
