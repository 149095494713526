import React from 'react'
import './index.scss'

const ChoosenPlan = ({
    ram = "",
    cpu = "",
    storage = "",
    bandwidth = "",
    location = "",
    datacenter = "",
}) => {
    return (
        <div>
        <p className="fs-18 fw-500 text-black my-4 text-start">پلن انتخابی شما : </p>
        <div className="w-100 CHOOSENPLAN d-flex flex-wrap justify-content-around align-items-center py-4 px-2">
            <div
                dir="ltr"
                className="info-box bg-white d-flex flex-column flex-center p-3 text-black font-en m-1"
            >
                <span className="fs-12 fw-500">RAM</span>
                <span className="fs-16 fw-700 my-1 ">{ram}</span>
            </div>
            <div
                dir="ltr"
                className="info-box bg-white d-flex flex-column flex-center p-3 text-black font-en m-1 "
            >
                <span className="fs-12 fw-500">CPU CORE</span>
                <span className="fs-16 fw-700 my-1 ">{cpu}</span>
            </div>
            <div
                dir="ltr"
                className="info-box bg-white d-flex flex-column flex-center p-3 text-black font-en m-1"
            >
                <span className="fs-12 fw-500">حافظه ذخیره‌سازی</span>
                <span className="fs-16 fw-700 my-1 ">{storage}</span>
            </div>
            <div
                dir="ltr"
                className="info-box bg-white d-flex flex-column flex-center p-3 text-black font-en m-1 "
            >
                <span className="fs-12 fw-500">پهنای باند</span>
                <span className="fs-16 fw-700 my-1 ">{bandwidth}</span>
            </div>
            <div
                dir="ltr"
                className="info-box bg-white d-flex flex-column flex-center p-3 text-black font-en m-1 "
            >
                <span className="fs-12 fw-500" dir="rtl">IP سرور</span>
                <span className="fs-16 fw-700 my-1 ">{location}</span>
            </div>
            <div
                dir="ltr"
                className="info-box bg-white d-flex flex-column flex-center p-3 text-black font-en m-1 "
            >
                <span className="fs-12 fw-500">دیتاسنتر</span>
                <span className="fs-16 fw-700 my-1 ">{datacenter}</span>
            </div>
        </div>
        </div>
    )
}

export default ChoosenPlan