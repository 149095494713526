import { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Button, Pagination, Table } from '../../../../components'
import { axios } from '../../../../boot'
import { useNavigate } from 'react-router'
const Channels = () => {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [ channels , setChannels ] = useState([])
  const [ pages , setPages ] = useState({})
  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getChannels = () => {
    const url = "/channels/getChannels"
    saveParams();
    const config = {
      params: {
        perPage: 20,
        ...params,
      },
    };
    axios.get(url,config).then(({ data }) => {
      console.log(data)
      setChannels(data?.data)
      setPages(data?.pages)
    })
  }
  useEffect(getChannels, [params])

  return (
    <Container className="">
      <Row className="w-100">
        <Col xs="12" md="10">
          <Table className="text-center">
            <thead>
              <tr>
                <th>نام</th>
                <th>رنگ</th>
                <th> سیگنال ها</th>
                <th>زبان</th>
              </tr>
            </thead>
            <tbody>
              {channels?.map((e) => (
                <tr key={e._id} onClick={() => navigate(e._id)}>
                  <td>{e.name}</td>
                  <td><div style={{width : "25px" , height : "25px" , backgroundColor : `${e.color}`,borderRadius : "50%"}} className="mx-auto"></div></td>
                  <td>{e.signals?.length}</td>
                  <td>{e.forPersian ? "فارسی" : "غیر فارسی"}</td>
                </tr>
              ))}
              {/* <tr>
                <td colSpan="4" className="h5 py-3 text-danger">
                  <i className="bi bi-info fs-4" />
                  سفارشی موجود نیست
                </td>
              </tr> */}
              <tr>
                <td colSpan="8">
                  <Button
                    onClick={() => navigate("new")}
                    variant="success"
                    className="text-light"
                  >
                    اضافه کردن کانال جدید
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Pagination 
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
    </Container>
  )
}

export default Channels