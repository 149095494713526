import React, { useEffect, useState } from 'react'
import { useGetChannels } from '../../../../hooks'
import { Button, Form, Input, Select } from '../../../../components'
import { Col, Container, Row } from 'react-bootstrap'
import { axios } from '../../../../boot'
import { toast } from '../../../../methods'
const PrivateChannel = () => {
    const [channels, getChannels] = useGetChannels()
    const [data, setData] = useState({})
    const [deleteData, setDeleteData] = useState({})
    const handlePrivateChannel = () => {
        const url = `/channels/private/${data?.id}/${data?.user}/addToMembers`
        axios.patch(url).then(({ data }) => {
            let text = "کاربر با موفقیت اضافه شد"
            toast({ text })
            setData({})
        })
    }
    const deleteFromChannel = () => {
        const url = `/channels/private/${deleteData?.id}/${deleteData?.user}/removeFromMembers`
        axios.patch(url).then(({ data }) => {
            let text = "کاربر از کانال خصوصی حذف شد"
            toast({ text })
            setDeleteData({})
        })
    }
    const fromControls = [
        {
            state: "user",
            label: "شماره همراه",
            type: "number"
        },
        {
            tag: Select,
            state: "id",
            label: "کانال ها",
            items: channels?.filter(e => e?.isPublic === false)?.map(i => ({ name: i?.name, id: i?._id })),
            cancelBtn: true
        },
    ]
    useEffect(getChannels, [])
    return (
        <Container className="">
            <Form className="row border-bottom border-2 py-4" onSubmit={handlePrivateChannel}>
                <div className="d-flex align-items-center">
                    <i className="bi bi-plus-circle text-success"></i>
                    <p className="mx-2">اضافه کردن به کانال</p>
                </div>
                {fromControls.map((item, index) => (
                    <Col
                        key={index}
                        xs="12"
                        md="6"
                        lg="6"
                    >
                        {React.createElement(item.tag ?? Input, {
                            value: data[item.state],
                            setValue: (val) =>
                                setData((p) => ({ ...p, [item.state]: val })),
                            ...item,
                        })}
                    </Col>
                ))}
                <Col xs="12" className="d-flex flex-center">
                    <Button type="submit" variant="success" className="text-light">
                        اضافه کردن به کانال خصوصی
                    </Button>
                </Col>
            </Form>
            <Form className="row py-4" onSubmit={deleteFromChannel}>
                <div className="d-flex align-items-center">
                    <i className="bi bi-trash-fill text-danger"></i>
                    <p className="mx-2"> حذف از کانال</p>
                </div>
                {fromControls.map((item, index) => (
                    <Col
                        key={index}
                        xs="12"
                        md="6"
                        lg="6"
                    >
                        {React.createElement(item.tag ?? Input, {
                            value: deleteData[item.state],
                            setValue: (val) =>
                                setDeleteData((p) => ({ ...p, [item.state]: val })),
                            ...item,
                        })}
                    </Col>
                ))}
                <Col xs="12" className="d-flex flex-center">
                    <Button type="submit" variant="danger" className="text-light">
                        حذف از کانال خصوصی
                    </Button>
                </Col>
            </Form>
        </Container>
    )
}

export default PrivateChannel