import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { InputGroup, Button, Form, Select, Modal, Input, MultiSelect } from "../../../../components";
import { axios } from "../../../../boot";
import { rules, toast } from "../../../../methods";
import { useGetChannels } from "../../../../hooks";

function Plan() {
  const { id } =  useParams();
  const isNew = id === "new"
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [selectOption, setSelectedOption] = useState([])
  const [channels, getChannels] =  useGetChannels()
  let AllChannels =  channels.map(e => ({ label: e.name, value: e._id }))
  
  const getData = () => {
    if (!isNew) {
      const url = `/plans/${id}`;
      axios.get(url).then(({ data }) => {
        let convertChannels = data?.channels?.map((e) => ({label : e?.name , value : e?._id}))
        setSelectedOption(convertChannels)
        setData(data);
      });
    }
  };
  const addPlan = () => {
    const url = "/plans/addplan"
    const body = { ...data, channels: selectOption?.map((e) => e.value) }
    axios.post(url, body).then(({ data }) => {
      let text = "پلن جدید با موفقیت افزوده شد"
      toast({ text })
      navigate(-1)
    })
  }
  const updatePurchase = () => {
    const url = `/plans/${id}/editPlan`
    axios.patch(url, data).then(({ data }) => {
      let text = "پلن با موفقیت ویرایش شد"
      toast({ text });
      navigate(-1);
    })
  }
  const fromControls = [
    {
      state: "name",
      label: "عنوان",
    },
    {
      state: "wallet",
      label: "ولت",
    },
    {
      state: "price",
      label: "قیمت",
    },
    {
      state: "duration",
      label: "مدت زمان",
    },
    {
      state: "toomanAmount",
      label: "قیمت ریالی",
    }
  ]
  const deletePurchase = () => {
    const url = `/plans/${id}/deletePlan`
    axios.delete(url).then(() => {
      let text = "پلن با موفقیت حذف شد"
      toast({ text });
      navigate(-1);
    });
  }
  useEffect(getChannels, [])
  useEffect(getData, []);
  return (
    <Container className="Product">
      <Form onSubmit={isNew ? addPlan : updatePurchase}>
        <Row>

          {fromControls.map((item, index) => (
            <Col
              key={index}
              xs="12"
              md="6"
              lg="4"
            >
              {React.createElement(item.tag ?? Input, {
                rules: rules.required,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
                ...item,
              })}
            </Col>
          ))}
          <Col
            xs="12"
            lg="12" >
            <MultiSelect selectedOptions={selectOption} setSelectedOptions={setSelectedOption} label="کانال" options={AllChannels} />
          </Col>
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="primary" className="text-light">
              {isNew ? " ثبت پلن جدید" : "ثبت تغییرات"}
            </Button>
          </Col>
        </Row>
      </Form>
      {!isNew &&
        <div className="d-flex flex-center flex-wrap my-4">
          <Button onClick={() => deletePurchase()} variant="danger text-white" className="m-2 ">حذف پلن</Button>
        </div>
      }
      {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
    </Container>
  );
}

export default Plan;