import React , { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useRenderNavItems from "./_useRenderNavLink";
import { useShowFullName } from "../../hooks";
import { Link, NavLink } from "react-router-dom";
import { Badge } from "../../components";
import { showCartCount } from "../../methods";
import { rolesPath } from "../../constants";
export default function Menu({ show = true, setShow = () => { } }) {
  const renderNavItems = useRenderNavItems();
  const isLogged = useSelector((s) => s.isLogged);
  const showFullName = useShowFullName();
  const cartCount = useSelector((s) => s.cartCount);
  const role = useSelector((s) => s.role);
  const menu = useRef();
  const handleShow = () => {
    menu.current.classList.toggle("active", show);
  };
  useEffect(handleShow, [show]);
  return (
    <div
      ref={menu}
      className="Menu position-fixed overflow-hidden w-100 h-100 transition d-block d-xl-none"
    >
      <button
        onClick={() => setShow(false)}
        className="hide-btn position-absolute w-100 h-100 border-0"
      />
      <nav className="position-relative d-flex flex-column row-gap-3 text-light h-100 bg-dark px-3 py-5 overflow-auto transition">

        {renderNavItems()}
        {isLogged ? (
          <React.Fragment>
            <NavLink to={rolesPath[role]} className="">
              {showFullName()}
            </NavLink>
            <NavLink
              to="/cart"
              className="position-relative"
            >
              {cartCount !== 0 && (
                <Badge variant="danger" label={showCartCount(cartCount)} />
              )}
              سبد خرید
            </NavLink>
          </React.Fragment>
        ) : (
          <Link
            to='/login'
            className="btn btn-black btn-sm px-4 py-1 fs-13  rounded-0 text-white"
          >
            ورود / ثبت نام
          </Link>
        )}
      </nav>
    </div>
  );
}
