const user = [
  {
    label: "صفحه اصلی",
    path: "/",
  },
  // {
  //   label: "ادمین",
  //   path: "/admin",
  // },
  // {
  //   label: "قوانین",
  //   path: "/terms-and-conditions",
  // },
  {
    label: "خرید ویندوز ترید",
    path: "/windows-server-trade",
  },
  {
    label: "خرید اکانت مشاوره و تحلیل کریپتو",
    path: "/profile",

  },
  // {
  //   label: "تغییر پلن مارول",
  //   path: "/profile",

  // },
  // {
  //   label: "فعالسازی پیامک",
  //   path: "/profile",

  // },
  // {
  //   label: "مقالات",
  //   path: "/articles",
  // },
  {
    label: "پروفایل",
    path: "/profile",
  },
  {
    label: "آکادمی مارول ترید",
    path: "https://academymarvel.com/",
    isExternal: true,
  },
  {
    label: "درباره ما",
    children: [
      {
        label: "سوالات متداول",
        path: "/questions",
      },
      // {
      //   label: "مجوز ثبت شرکت در انگلستان",
      //   path: "/permit",
      // },
      {
        label: "تماس با ما",
        path: "/contact-us",
      },

      {
        label: "مارول چیست؟",
        path: "/what-is-marvel",
      },
      // {
      //   label: "تیم مارول ترید",
      //   path: "/gallery",
      // },
    ],
  },
];
const admin = [
  {
    label: "صفحه اصلی",
    path: "/",
  },

  {
    title: "سرور مجازی",
    children: [
      // {
      //   label: "پلن‌ها",
      //   path: "plans",
      // },
      {
        pr : 0,
        label: "گزارش گیری خرید کاربران",
        path: "reporting",
      },
      { 
        pr : 1,
        label: "مدیریت محصولات (ویندوز ترید)",
        path: "products",
      },
      { 
        pr : 2,
        label: "سرویس‌های اختصاص داده نشده",
        path: "active-services",
      },
      { 
        pr : 3,
        label: "IPهای آزاد",
        path: "free-services",
      },
      { 
        pr : 4,
        label: "ثبت دستی خرید",
        path: "manual-buy",
      },
      { 
        pr : 5,
        label: "خریدهای تتری",
        path: "usd-orders",
      },
    ]
  },
  {
    title: "پنل مارول",
    children: [
      { 
        pr : 6,
        label: "کاربران",
        path: "/admin/marvel-users",
        icon: "clock-history",
      },
      { 
        pr : 7,
        label: "پلن ها",
        path: "/admin/marvel-plans",
        icon: "chat-left-dots",
      },
      { 
        pr : 8,
        label: "پرداخت ها",
        path: "/admin/marvel-purchases",
        icon: "table",
      },
      { 
        pr : 9,
        label: "کانال ها",
        path: "/admin/marvel-channels",
        icon: "table",
      },
      
      { 
        pr : 10,
        label: "سیگنال ها",
        path: "/admin/marvel-signals",
        icon: "table",
      },
      { 
        pr : 11,
        label: "کدتخفیف",
        path: "/admin/marvel-discounts",
        icon: "table",
      },
      { 
        pr : 12,
        label: "اخبارها",
        path: "/admin/marvel-news",
        icon: "table",
      },
      { 
        pr : 13,
        label: "آموزش ها",
        path: "/admin/marvel-educations",
        icon: "table",
      },
      { 
        pr : 14,
        label: "دسته بندی آموزش",
        path: "/admin/marvel-educationCat",
        icon: "table",
      },
      { 
        pr : 15,
        label: "دسته بندی اخبار",
        path: "/admin/marvel-newsCat",
        icon: "table",
      },
      { 
        pr : 16,
        label: "کانال خصوصی",
        path: "/admin/marvel-private-channel",
        icon: "table",
      },
      { 
        pr : 17,
        label: "ارسال پیامک منتور",
        path: "/admin/marvel-send-mentor",
        icon: "table",
      },
    ],
  },
];

const landing = [
  {
    label: "صفحه اصلی",
    path: "/",
  },
  // {
  //   label: "ورود خرید VPS ",
  //   path: "/login",
  // },
  // {
  //   label: "ادمین",
  //   path: "/admin",
  // },
  // {
  //   label: "قوانین",
  //   path: "/terms-and-conditions",
  // },
  {
    label: "خرید ویندوز ترید",
    path: "/windows-server-trade",
  },
  {
    label: "خرید اکانت مشاوره و تحلیل کریپتو",
    path: "/profile",

  },
  // {
  //   label: "تغییر پلن مارول",
  //   path: "/profile",

  // },
  // {
  //   label: "فعالسازی پیامک",
  //   path: "/profile",

  // },
  {
    label: "آکادمی مارول ترید",
    path: "https://academymarvel.com/",
    isExternal: true,
  },
  {
    label: "فرصت های شغلی",
    path: "https://careers.academymarvel.com/",
    isExternal: true,
  },
  // {
  //   label: "مقالات",
  //   path: "/articles",
  // },
  {
    label: "درباره ما",
    children: [
      {
        label: "سوالات متداول",
        path: "/questions",
      },
      // {
      //   label: "مجوز ثبت شرکت در انگلستان",
      //   path: "/permit",
      // },
      {
        label: "تماس با ما",
        path: "/contact-us",
      },

      {
        label: "مارول چیست؟",
        path: "/what-is-marvel",
      },
      // {
      //   label: "تیم مارول ترید",
      //   path: "/gallery",
      // },
    ],
  },
];
const navItems = {
  user,
  admin,
  landing
};
export default navItems;
