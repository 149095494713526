import { ListGroup } from "react-bootstrap";

export default function VpsRules() {
  return (
    <ListGroup className="text-justify shadow-sm fs-7">
      <ListGroup.Item>
        پس از تحویل مشخصات ویندوز سرور ترید ؛ خواهشمندیم پسورد خود را تغییر
        دهید.
      </ListGroup.Item>
      <ListGroup.Item>
        مشخصات ویندوز سرور ترید خود را در اختیار هیچ فرد دیگری قرار ندهید.
      </ListGroup.Item>
      <ListGroup.Item>
        (VPS)های تحویل داده شده مخصوص ترید می باشد و برای انجام کار های متفرقه
        مناسب نیستند.
      </ListGroup.Item>
      <ListGroup.Item>
        به هیچ وجه ویندوز سرور خود را (SHUT DOWN) و یا (Disconnect) نکنید.
      </ListGroup.Item>
      <ListGroup.Item>
        خواهشمندیم ایمیل خود را داخل (VPS) باز نگه ندارید و برای امنیت بیشتر پس
        از اتمام کار ، از حساب خود (Log out) کنید.
      </ListGroup.Item>
    </ListGroup>
  );
}
