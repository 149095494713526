import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { axios } from "../../../boot";
import { Button, VpsRules } from "../../../components";
import { startOrder , copyText , toast } from "../../../methods";
export default function ActiveServices() {
  const [activeServices, setActiveServices] = useState([]);
  const defaultLoginToObject = (str = "") => {
    return str
      .split(" and ")
      .map((item) => {
        const array = item.split(": ");
        return { [array[0]]: array[1] };
      })
      .reduce((a, b) => ({ ...b, ...a }));
  };
  const getActiveServices = () => {
    const url = "/services";
    axios.get(url).then((res) => {
      const data = cloneDeep(res.data.data);
      setActiveServices(
        data.map((item) => ({
          ...item,
          ...defaultLoginToObject(item.defaultLogin),
        }))
      );
    });
  };
  const extendService = (id = "") => {
    const message = "آیا از درخواست خود مطمئن هستید؟";
    if (window.confirm(message)) {
      const url = "/carts/extend-service";
      const body = {
        serviceId: id,
      };
      axios.post(url, body).then(() => {
        startOrder();
      });
    }
  };
  const copyCode = (value) => {
    
    copyText(value)
      .then(() => {
        const text = "کپی شد.";
        toast({ text });
      })
      .catch(() => {
        const text = "خطا در کپی کد معرف لطفا مجددا تلاش کنید.";
        toast({ text, type: "error" });
      });
  };
  const serverInfo = (data = {}) => [
    {
      label: "IP",
      value: data["ip"] ?? <span className="text-success">به زودی</span>,
    },
    {
      label: "RAM",
      value: data["ram"],
    },
    {
      label: "CPU CORE",
      value: data["cpu"],
    },
    {
      label: "پهنای باند",
      value: data["bandwidth"],
    },
    {
      label: "حافظه ذخیره سازی",
      value: data["storage"],
    },
    {
      label: "محل سرور",
      value: data["location"],
    },
    {
      label: "نام کاربری",
      value: data["username"],
    },
    {
      label: "کلمه عبور",
      value: data["password"],
      append: <button onClick={()=>copyCode(data["password"])} className="bi bi-files" />,
    },
    // {
    //   label: "توضیحات",
    //   value: data["defaultLogin"] ?? "...",
    // },
  ];

  
  useEffect(getActiveServices, []);
  return (
    <div className="ActiveServices">
      <h3 className="text-info py-5 d-block text-center">سرویس‌های فعال</h3>
      <Container>
        <Row className="align-items-start">
          <Col xs="12" lg="4" className="px-0">
            <div className="d-flex flex-column flex-center">
              <h5 className="text-center text-info">قوانین (vps)</h5>
              <VpsRules />
            </div>
          </Col>
          <Col xs="12" lg="8" className="px-0">
            <Row className="justify-content-end">
              {activeServices.map((item, index) => (
                <Col xs="12" md="6" key={index}>
                  <div className="w-100 rounded-3 border border-primary">
                    <div className="w-100 d-flex flex-center text-center col-gap-3 border-bottom border-primary fs-5 p-3">
                      <span className="text-info">{item.product.name} </span>
                      <span className="text-secondary">سالانه</span>
                    </div>
                    <div className="row w-100 p-3 text-secondary">
                      {serverInfo({
                        ...item,
                        ...item.product,
                      }).map((data, index) => (
                        <React.Fragment key={index}>
                          <div className="col-5">{data.label}</div>
                          <div dir="ltr" className="col-7 text-end">
                            {data.value}
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="col-5 text-danger ">تاریخ انقضا</div>
                      <div className="col-7 text-danger font-en ">
                        {item.endDate}
                      </div>
                      <div className="col-5 text-primary mt-4">قیمت</div>
                      <div className="col-7 text-primary font-en mt-4">
                        {Number(item.product.price).toLocaleString()} تومان
                      </div>
                      {item.status === "expired" ? (
                        <Button
                          outline
                          variant="danger"
                          disabled
                          onClick={() => {
                            extendService(item._id);
                          }}
                        >
                          سرویس منقضی شده است.
                        </Button>
                      ) : (
                        <Button
                          outline
                          variant="success"
                          onClick={() => {
                            extendService(item._id);
                          }}
                        >
                          تمدید سرویس
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
