import React, { useState } from 'react'
import { useGetPlan } from '../../../hooks'
import { useSelector } from 'react-redux'
import { moment } from '../../../boot'
import { Badge } from '../../../components'
import { motion } from 'framer-motion'
import { toast } from '../../../methods'
import { Col } from 'react-bootstrap'
const CurrentPlan = ({
    onShow = () => { },
    planId = "",
    setActivePage = () => { },
    expiresIn
}) => {
    const yourPlan = useGetPlan(planId)
    const handleDate = (expire) => {
        const endDate = new Date(expire).getTime()
        let now = new Date()
        let difference = endDate - now;
        let days = Math.ceil(difference / (1000 * 3600 * 24));
        let res = new Date(endDate)
        return { days, res }
    }
    let { days, res } = handleDate(expiresIn)

    // const handleBuy = () => {
    //     let text = "قابلیت خرید و یا تمدید اشتراک فعلا بسته می باشد"
    //     toast({text})
    // }
    const handleBuy = () => {
        // let text = "در حال حاضر امکان خرید پلن نمی باشد."
        // return toast({text , type : "error"})
        if (days > 7) {
            let text = "شما پلن فعال دارید. درحال حاضر امکان خرید پلن جدید نمی باشد."
            return toast({ text, type: 'error' })
        }
        return onShow(true)
    }
    return (
        <div className='Current-plan p-4 '>
            {yourPlan &&
                <div className=''>
                    <div className='d-flex w-100 align-items-center justify-content-between'>
                        <p className='fs-20 fw-500 text-white mb-4 my-2'>پلن فعلی شما:</p>
                        <p className='fs-20 fw-700 text-white mb-4 my-2'>{yourPlan?.name}</p>
                    </div>
                    <div className='d-flex w-100 align-items-center justify-content-between my-4'>
                        <p className='fs-18 fw-300 text-white mb-4 my-2'>تاریخ انقضا : </p>
                        <div className='d-flex align-items-center'> {days > 0 ? <Badge pill={true} variant="success" label={`${days} روز دیگر`} /> : <Badge pill={true} className='mx-2' variant="danger" label="منقضی شد" />} <p className="fs-18 fw-500 text-white mx-1">{moment.miladiToShamsi({ date: res })}</p> </div>
                    </div>
                    <div className="w-100 row">
                        <Col className="p-1" xs="12" xl="6"><motion.button whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.95 }} className='w-100 fs-14 py-2 btn btn-black rounded-0' onClick={() => { setActivePage("PlanBill"); onShow(true) }}> صورت حساب</motion.button></Col>
                        <Col className="p-1" xs="12" xl="6"><motion.button whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.95 }} className='w-100 fs-14 py-2 btn btn-black rounded-0' onClick={() => handleBuy()}>خرید پلن مارول</motion.button></Col>
                    </div>
                </div>
            }
            {!yourPlan &&
                <div>
                    <p className='fs-24 fw-700 text-white mb-4 my-2'>پلن فعلی شما:</p>
                    <p className="fs-18 text-white my-2">در حال حاضر برای شما فعال نمی‌باشد</p>
                    <button onClick={() => onShow(true)} className="btn btn-black rounded-0 w-100 py-2 mt-4">خرید پلن مارول</button>
                </div>
            }
        </div>
    )
}

export default CurrentPlan