import React from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "..";
import './index.scss'

export default function CartCard({
  bandwidth = "",
  count = 0,
  cpu = "",
  location = "",
  name = "",
  price = "",
  priceUsd = "",
  ram = "",
  storage = "",
  totalPrice = 0,
  totalPriceUsd = 0,
  next = false,
  onDelete = () => { },
  onDeleteItem = () => { },
  onAddItem = () => { },
  // usage = "کوکوین و نصب والتها",
  // _id = "61e07fa968b81a0252241a39",
}) {
  const info = [
    {
      label: "RAM",
      value: ram,
    },
    {
      label: "CPU CORE",
      value: cpu,
    },
    {
      label: "پهنای باند",
      value: bandwidth,
    },
    {
      label: "حافظه ذخیره سازی",
      value: storage,
    },
    {
      label: "محل سرور",
      value: location,
    },
  ];

  const prices = [
    {
      label: "قیمت(تومان)",
      value: (+price).toLocaleString(),
    },
    {
      label: "قیمت(USD)",
      value: (+priceUsd).toLocaleString(),
    },
    // {
    //   label: "تعداد",
    //   value: count,
    // },
  ]
  return (
    <Row className="CartCard  w-100 p-4 ">
      <Col xs="12" lg="3">
        <p className="fs-24 fw-500 text-black">VPS :</p>
        <p className="fs-24 fw-700 text-black">سالیانه {name}</p>
      </Col>
      <Col className="px-2 infoBorder" xs="12" lg="4" xl="5">
        <Row className="w-100 text-secondary justify-content-between">
          {info.map((item, index) => (
            <React.Fragment key={index}>
              <Col xs="6" className={`${index === 0 ? "mb-3 text-info" : ""}`}>
                <p className="fs-18 fw-400 text-black">{item.label}</p>
              </Col>
              <Col
                dir="ltr"
                xs="5"
                className={`font-en text-start ${index === 0 ? "mb-3" : ""}`}
              >
                <p className="fs-18 fw-400 text-black">{item.value}</p>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </Col>
      <Col
        xs="12"
        lg="4"
        xl="4"
        className="mt-auto d-flex flex-column flex-center text-center"
      >
        <Row className="w-100 py-3">
          {prices.map((item, index) => (
            <React.Fragment key={index}>
              <Col xs="6" >
                <p className="fs-18 fw-400 text-black text-start">{item.label}</p>
              </Col>
              <Col
                dir="ltr"
                xs="6"
                className={`font-en text-start ${index === 0 ? "mb-3" : ""}`}
              >
                <p className="fs-18 fw-400 text-black">{item.value}</p>
              </Col>
            </React.Fragment>
          ))}
          <React.Fragment>
            <Col xs="6">
              <p className="fs-18 fw-400 text-black text-start mt-2 ">تعداد </p>
            </Col>
            <Col className="" xs="6">
              <div className="d-flex align-items-center justify-content-center mt-3 border border-black border-2 p-1 w-100">
                <button style={{ width: "34px", height: "34px" }} onClick={onDeleteItem} className="font-en  bg-black text-white rounded-0">
                  <i className="bi bi-dash-lg" />
                </button>
                <p className="mx-4 fs-24 fw-700 text-black">
                  {count}
                </p>

                <button style={{ width: "34px", height: "34px" }} onClick={onAddItem} className="font-en  bg-black text-white rounded-0">
                  <i className="bi bi-plus-lg" />
                </button>
              </div>
            </Col>
          </React.Fragment>

          <React.Fragment>
              <Col xs="6" >
                <p className="fs-18 fw-400 text-black text-start">قیمت کل:</p>
              </Col>
              <Col
                dir="ltr"
                xs="6"
                className={`font-en text-start`}
              >
                <p className="fs-18 fw-400 text-black">{(+totalPrice).toLocaleString()} <br/> {(+totalPriceUsd).toLocaleString()} USD</p>
              </Col>
            </React.Fragment>
        </Row>

      </Col>
      {/* {next && <hr/>} */}
      
    </Row>
  );
}
