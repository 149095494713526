import React, { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Table, Pagination, Input, Select } from '../../../../components'
import { axios, moment } from '../../../../boot'
import { useNavigate } from 'react-router'
import { purchaseStatus } from '../../../../constants'
import { convertPhone, jsonToXlsx } from '../../../../methods'
import { useGetPlan } from '../../../../hooks'
const Purchases = () => {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const plans = useGetPlan()
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [purchases, setPurchases] = useState([])
  const [pages, setPages] = useState({})
  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getPurchases = () => {
    const url = "/payments/getPayments"
    saveParams();
    const config = {
      params: {
        perPage: 20,
        ...params,
      },
    };
    
    axios.get(url, config).then(({ data }) => {
      setPurchases(data?.data)
      setPages(data?.pages)
    })
  }
  const formControls = [
    {
      label: "تکس آیدی",
      state: "textId",
      clear: true,
    },
    {
      label: "شماره تلفن",
      state: "phone",
      type: "number",
      clear: true,
    },
    {
      tag: Select,
      label: "وضعیت",
      state: "status",
      cancelBtn: true,
      items: purchaseStatus.filter((e) => e.filterable),
    },
    {
      tag: Select,
      label: "پلن",
      state: "selectedPlan",
      cancelBtn: true,
      items: plans?.map((e) => ({ name: e?.name, id: e?._id }))
    },
  ];
  useEffect(getPurchases, [params])
  const showStatus = (id) => {
    const { color, name } = purchaseStatus.find((e) => e.id === id) ?? {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  }
  const handleClick = (e, id) => {
    const target = e.target.id
    if (target === "phone" || target === "textId") {
      return
    }
    navigate(id)
  }
  const downloadXlsx = async () => {
    const url = "/payments/getPayments";
    const perPage = 100;
    const totalPages = Math.ceil(pages.totalItems / perPage);
    const config = (page) => ({ params: { ...params, perPage, page } });
    const getAllData = await [...Array(totalPages)]
      .map((e, i) => i + 1)
      .map(
        async (page) => await (await axios.get(url, config(page))).data?.data
      );
    const allData = await Promise.all(getAllData);
    const title = "report";
    const json = allData.flat().map((e) => ({
      "نام کاربر": `${e?.userId?.firstName} ${e?.userId?.lastName}`,
      "تلفن": `${e?.phone}`,
      "پلن": `${e?.selectedPlan?.name}`,
      "تاریخ ثبت": `${moment.miladiToShamsi({ date: e?.createdAt, format: "jYYYY/jMM/jDD - HH:mm" })}`,
      "تکست آیدی": `${e?.textId ? e?.textId : ""}`,
      "کدتخفیف": `${e.code ? e.code : "بدون تخفیف"}`,
      وضعیت: purchaseStatus.find((it) => it.id === e.status)?.name,
    }));
    jsonToXlsx({ json, title });
  };
  return (
    <Container className="">
      <Row>
        <Col>
          <div className="d-print-none">
            <button
              className="bi bi-filetype-xlsx fs-3 text-success"
              onClick={downloadXlsx}
            />
            
          </div>
        </Col>

      </Row>
      <Row className="d-print-none">
        {formControls.map((e) => (
          <Col key={e.state} xs="12" md="6" lg="4">
            {React.createElement(e.tag ?? Input, {
              ...e,
              value: params[e.state],
              setValue: (val) => {
                setPurchases([]);
                setParams((p) => ({ ...p, page: 1, [e.state]: val }));
              },
            })}
          </Col>
        ))}
      </Row>
      <Row className="w-100">
        <Col xs="12" md="12">
          <Table className="text-center">
            <thead>
              <tr>
                <th>نام و نام خانوادگی</th>
                <th className="">تلفن</th>
                <th className="">وضعیت</th>
                <th className="">تکست آیدی</th>
                <th className="">كد تخفيف</th>
                {/* <th class="">عكس</th> */}
                <th className="">پلن</th>
                <th className="">تاریخ ثبت</th>
              </tr>
            </thead>
            <tbody>
              {purchases?.map((e) => (
                <tr key={e._id} onClick={(event) => handleClick(event, e._id)}>
                  <td>{e?.userId?.firstName} {e?.userId?.lastName}</td>
                  <td id="phone">{e.phone}</td>
                  <td>{showStatus(e.status)}</td>
                  <td id="textId">{e.textId}</td>
                  <td>{e.code ? e.code : <p className="text-danger">بدون تخفیف</p>}</td>
                  {/* <td><img src={e.image} /></td> */}
                  <td>{e.selectedPlan?.name}</td>
                  <td>{moment.miladiToShamsi({ date: e?.createdAt, format: "HH:mm - jYYYY/jMM/jDD" })}</td>

                </tr>
              ))}
              {/* <tr>
                <td colSpan="4" className="h5 py-3 text-danger">
                  <i className="bi bi-info fs-4" />
                  سفارشی موجود نیست
                </td>
              </tr> */}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
    </Container>
  )
}

export default Purchases