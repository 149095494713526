import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input , AndroidBtn , IosBtn } from "../../../components";
import { rules, toast } from "../../../methods";
import { axios } from "../../../boot";
import { socialNetworks } from "../../../constants";
import "./index.scss";
import Qa from "./QA";

export default function ContactUs() {
  const formControls = [
    {
      label: "نام",
      state: "name",
      labelColor : "white",
    },
    {
      label: "ایمیل",
      state: "email",
      labelColor : "white",
      rules: rules.email,
    },
    {
      label: "نوشتن پیام",
      state: "text",
      labelColor : "white",
      as: "textarea",
      rules: rules.required,
    },
  ];
  const [data, setData] = useState({ name: "" });
  const sendMessage = () => {
    const url = "/pub/contact-us";
    const body = data;
    axios.post(url, body).then(() => {
      const text = "پیام شما با موفقیت ارسال شد";
      toast({ text });
    });
  };
  return (

      <Container className="position-relative ContactUs bg-white px-0 py-5">
        {/* <div className="position-relative bg-white w-100"> */}
          <Row className="d-flex align-items-start w-100 ">
            <Col className=" " xs="12" lg="6">
              <p className="fs-30 fw-900 text-black my-4">تماس با ما:</p>
              <div className="w-100">
                {socialNetworks.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-light w-100 p-2 my-2 border border-2 border-black  rounded-0 d-flex justify-content-between align-items-center  text-center  fw-bold fs-5"
                  >
                    <div>
                      <img
                        width="25"
                        height="25"
                        className="object-fit-contain mx-2"
                        src={item.image}
                        alt={item.label}
                      />
                      <span className="fs-14 fw-400 text-black">{item.label}</span>
                    </div>
                    <span dir="ltr" className="fs-14 fw-700 text-black mx-1">
                      {item.value}
                    </span>
                  </a>
                ))}
              </div>
            </Col>
            <Col xs="12" lg="6">
              <Form
                onSubmit={sendMessage}
                className=" row p-3 pt-lg-3 text-center bg-tufts-blue rounded-0 border border-2 border-black"
              >
                <h5 className="text-white w-100 my-4">
                  پیام خود را برای ما ارسال کنید
                </h5>
                {formControls.map((item, index) => (
                  <Col key={index} xs="12">
                    <Input
                      {...item}

                      className="border py-2 bg-white"
                      value={data[item.state]}
                      setValue={(val) =>
                        setData((p) => ({ ...p, [item.state]: val }))
                      }
                    />
                  </Col>
                ))}
                <Col xs="12">
                  <Button type="submit" size="lg" className="bg-black rounded-0 w-100 text-white">
                    ثبت دیدگاه
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
          <Row className="getApp">
            <Col xs="12" lg="6">
                <p className="fs-30 fw-700 text-black text-center">اپلیکیشن مارول ترید برای آگاهی تلاش می‌کند</p>
            </Col>
            <Col className="d-flex justify-content-center align-items-center flex-wrap" xs="12" lg="6">
                <AndroidBtn />
                <IosBtn/>
            </Col>
          </Row>
          <Qa/>
        {/* </div> */}
      </Container>
  );
}
