import elonMusk from "./articles/elonMusk";
import robertKiyosaki from "./articles/robertKiyosaki";
import wallet from "./articles/wallet";

const articles = [elonMusk, robertKiyosaki, wallet];
export default articles;
// const wallet = {
//   _id: "",
//   title: "",
//   createdAt: "",
//   author: "",
//   img: "",
//   full: [
//     {
//       img: "",
//       title: "",
//       text: "",
//     },
//   ],
// };
