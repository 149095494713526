import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { navItems } from "../../constants";
import logo from "../../assets/logo/logo.png";
import bgImg from "../../assets/icons/home/background.svg";
import "./index.scss";
import Accordion from "./Accordion";
import { useSelector } from "react-redux";
export default function AdminLayout() {
  const [pr, setPr] = useState([])
  const role = useSelector((s) => s.role);
  useEffect(() => {
    const priviliages = JSON.parse(localStorage.getItem("priviliages"))
    setPr([...priviliages])
  }, [])
  const renderNavItems = () => {
    const elements = [];
    navItems.admin.forEach((item, index) => {
      if ("path" in item) {
        const element = (
          <li key={index} className="w-100">
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                `w-100 d-flex align-items-center p-2 ${isActive ? "bg-white text-violet active" : ""
                }`
              }
            >
              {item.label}
              {/* <i className="bi bi-arrow-left me-auto transition" /> */}
            </NavLink>
          </li>
        );
        elements.push(element);
      }
      if ("children" in item) {
        const element = (

          <li key={index} className="w-100">
            <Accordion title={item.title}>
              <div className="d-flex flex-column">
                {item.children.map((item, index) => (
                  pr[item.pr] &&
                  <NavLink
                    key={index}
                    to={item.path}
                    className={({ isActive }) =>
                      ` d-flex align-items-center justify-content-between transition p-2 ${isActive ? "text-violet active" : ""
                      }`
                    }
                  >
                    {item.label}
                    {/* <i className="bi bi-arrow-left transition text-violet" /> */}
                  </NavLink>
                ))}
              </div>
            </Accordion>
          </li>
        );
        elements.push(element);
      }

    });
    return elements;
  };
  const menu = useRef();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const handleShow = () => {
    menu.current.classList.toggle("active", showMenu);
  };
  useEffect(handleShow, [showMenu]);

  useEffect(() => setShowMenu(false), [location.pathname]);
  return (
    <Row className="AdminLayout w-100 align-items-start">
      <Col
        ref={menu}
        xs="12"
        lg="4"
        xl="3"
        xxl="2"
        className="sidebar-section p-0"
      >
        <div className="sidebar-menu position-relative bg-dark text-light overflow-auto h-100 py-5 px-2">
          <img className="bg-img" src={bgImg} alt="bgImg" />
          <ul className="d-flex flex-column gap-1">
            {role === "0aZ03@W.h!" &&
              <li className="w-100">
                <NavLink
                  to="adminUser"
                  className="position-relative bg-white d-block w-100 text-truncate p-2 rounded border-primary overflow-hidden"
                >
                  ادمین کاربران
                </NavLink>
              </li>
            }

            {renderNavItems()}
          </ul>
        </div>
        <button
          onClick={() => setShowMenu(false)}
          className="hide-btn d-block d-lg-none h-100 border-0"
        />
      </Col>
      <Col xs="12" lg="8" xl="9" xxl="10" className="px-0">
        <header className="bg-white d-flex align-items-center justify-content-between py-2 px-3 shadow-sm">
          <i
            onClick={() => setShowMenu(true)}
            className="bi bi-list d-block d-lg-none fs-2 cursor-pointer text-dark"
          />
          <img width="40" src={logo} alt="logo" />
        </header>
        <main className="p-4">
          <div className="py-4 bg-white rounded shadow-sm">
            <Outlet />
          </div>
        </main>
      </Col>
    </Row>
  );
}
