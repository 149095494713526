import { Outlet, useLocation, useNavigate } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import up from '../../assets/images/LoginLayout/upLong.svg'
import right from '../../assets/images/LoginLayout/right.svg'
import rightShort from '../../assets/images/LoginLayout/right-short.svg'
import down from '../../assets/images/LoginLayout/downLong.svg'
import logo from '../../assets/images/LoginLayout/logo.png'
import Ornament from '../../assets/images/LoginLayout/Ornament.png'
import { CurrencyInfo } from "../../components";
import chart from '../../assets/images/LoginLayout/chart.png'
import './index.scss'

export default function LoginLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = location.pathname === "/login/verify-code";
  const goBack = () => {
    navigate(-1, { replace: true });
  };
  return (
    <div
      className="LoginLayout position-relative w-100 d-flex align-items-center  "
    >
      <div className="loginRightSection d-flex flex-column flex-center ">
        <Outlet />
        {/* <div style={{width : "90%"}} className=" my-2 d-block d-lg-none">
          <div className="needLearning-card d-flex flex-column justify-content-center p-2 border border-2 border-black">
            <p className="my-1 text-black fs-14 fw-700">به آموزش نیاز داری؟</p>
            <button className="border border-2 border-black btn btn-black w-100">آموزش خرید و تغییر پسورد کاربری</button>
          </div>
        </div> */}
      </div>
      <div className="loginLeftSection bg-tufts-blue position-relative d-none d-lg-block ">
        <div className="w-100 d-none d-lg-flex align-items-center justify-content-between justify-content-xl-center mb-4 py-2 px-4">
          <div className="logoSection d-flex flex-center">
            <img src={logo} alt="marvel logo" />
          </div>
          <img src={right} alt="arrow right" className="d-none d-xl-block" />
          <div className="BeSure d-flex flex-center px-2">
            <p className="text-center text-black ">انتخاب مطمئن شما برای مشاوره و سرمایه <br /> گذاری در بازار های مالی و کریپتوکارنسی</p>
          </div>
        </div>

        <img src={down} alt="arrow down" className="arrowDown " />

        <img src={rightShort} alt="arrow right" className="arrowRightShort " />

        <div className="currency-price ">
          <img src={chart} height="400px" alt="chart" loading="lazy" />
        </div>

        {/* <div className="needLearning">
          <img src={up} alt="arrow up" className="needLearning-arrow d-none d-lg-block" />
          <div className="needLearning-card d-flex flex-column justify-content-center p-2 bg-tufts-blue">
            <p className="my-1 text-white fs-14 fw-700">به آموزش نیاز داری؟</p>
            <button className="border border-2 border-black btn btn-white w-100">آموزش خرید و تغییر پسورد کاربری</button>
          </div>
        </div> */}

        <img src={Ornament} alt="ornament" className="ornament d-none d-lg-block" />

      </div>

    </div>
  );
}
