import { Carousel, Col, Container, Row } from "react-bootstrap";
import { downloadItems } from "../../../constants";
import MainImage from '../../../assets/images/home/main.png'
import marvelTeam from "../../../assets/icons/home/carousel/marvel team.jpg";
// import macbook from "../../../assets/icons/home/carousel/macbook.svg";
// import imgSlider1 from "../../../assets/icons/home/carousel/1.svg";
// import imgSlider2 from "../../../assets/icons/home/carousel/2.svg";
// import imgSlider3 from "../../../assets/icons/home/carousel/3.svg";
// import imgSlider4 from "../../../assets/icons/home/carousel/4.svg";
// import imgSlider5 from "../../../assets/icons/home/carousel/5.svg";
// import imgSlider6 from "../../../assets/icons/home/carousel/6.svg";
// import imgSlider7 from "../../../assets/icons/home/carousel/7.svg";
// import imgSlider8 from "../../../assets/icons/home/carousel/8.svg";
// import imgSlider9 from "../../../assets/icons/home/carousel/9.svg";
export default function MainSection() {
  // const imgSections = [
  //   imgSlider7,
  //   imgSlider2,
  //   imgSlider1,
  //   imgSlider3,
  //   imgSlider8,
  //   imgSlider6,
  //   imgSlider4,
  //   imgSlider5,
  //   imgSlider9,
  // ];
  return (
    <Container className="main-section position-relative py-5">
      <Row className="d-flex flex-column-reverse flex-xl-row">
            <Col className="p-0" xs="12" xl="7">
              <div className="MainSection-img">
                <img  className="w-100" src={MainImage} alt="imgSlider1" />
              </div>
            </Col>
            <Col xs="12" xl="5">
              <div className="d-flex flex-column justify-content-start row-gap-3 fade-in-right">
                <p className="lh-lg text-light mainText fw-500 white-space-pre-wrap">
                  اپلیکیشن مارول ترید{"\n"}
                  آگاهی بیشتر سود بیشتر{"\n"}
                  ضرر نکردن الویت ماست
                </p>
                <p className=" text-white fs-18">
                  انتخاب مطمئن شما برای مشاوره و سرمایه گذاری در بازار های مالی
                  و کریپتوکارنسی
                </p>
                <Row className="w-100 d-flex justify-content-start">
                  {downloadItems.map((item, index) => (
                    <Col key={index} xs="12" md="6" xl="4" className="px-1">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="position-relative d-inline-block shadow bg-black border border-black w-100 rounded overflow-hidden m-0 p-0"
                        download={item.download ?? false}
                      >
                        <img
                          className="w-100 object-fit-contain"
                          height="40"
                          src={item.icon}
                          alt={item.label}
                        />
                      </a>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
    </Container>
  );
}
