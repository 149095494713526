import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { Table } from "../../../components";
import { axios , moment } from "../../../boot";

export default function ActiveServices() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const getData = () => {
    const url = "/admins/services";
    const params = {
      sort: "createdAt:-1",
      status: "active",
    };
    axios.get(url, { params }).then(({ data }) => {
      setData(data.data.filter((e) => !e.ip));
    });
  };
  useEffect(getData, []);
  return (
    <Container>
      <Row className="w-100">
        <Col xs="12">
          <Table className="text-center">
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام طرح</th>
                <th>IP</th>
                <th>RAM</th>
                <th>CPU CORE</th>
                <th>حافظه ذخیره‌سازی</th>
                {/* <th>پهنای باند</th> */}
                <th>محل سرور</th>
                <th>قیمت</th>
                {/* <th>تاریخ شروع</th> */}
                <th>تاریخ پایان</th>
                <th>کاربر</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} onClick={() => navigate(item._id)}>
                  <td>{index + 1}</td>
                  <td>{item?.product?.name}</td>
                  <td className="text-danger">اختصاص داده نشده</td>
                  <td>{item?.product?.ram}</td>
                  <td>{item?.product?.cpu}</td>
                  <td>{item?.product?.storage}</td>
                  {/* <td>{item.product.bandwidth}</td> */}
                  <td>{item?.product?.location}</td>
                  <td>{Number(item?.product?.price).toLocaleString()} تومان</td>
                  {/* <td>{item.startDate}</td> */}
                  <td>{item?.endDate?.split("/").reverse().join("/")}</td>
                  <td>
                    {`${item?.user[0]?.firstName} ${item?.user[0]?.lastName}`}
                    <span dir="ltr">({item?.user[0]?.phone})</span>
                  </td>
                </tr>
              ))}
              {!data.length && (
                <tr>
                  <td colSpan="12" className="h5 py-3 text-success">
                    <i className="bi bi-hand-thumbs-up" />
                    همه سرویس‌ها اختصاص داده شده‌اند.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
