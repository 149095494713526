import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import planIcon from "../../../assets/icons/plan.svg";
import { Button, Modal, PlanCard } from "../../../components";
import { axios } from "../../../boot";
import './index.scss'
import Slider from "react-slick";

export default function JoinMarvelSchool() {
  const [plans, setPlans] = useState([]);
  // const [activeVideo, setActiveVideo] = useState(null);
  const getPlans = () => {
    const url = "/pub/plans";
    axios.get(url).then(({ data }) => {
      let res = data?.data?.filter(e => e._id !== "65396705333123113d85ac55" && e.sell !== false)
      setPlans(res);

    });
  };
  useEffect(getPlans, []);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,

  };
  return (
    <div className="JoinMarvelSchool my-5 text-center py-4">
      <Container>
        <p className="text-black d-block fs-30 fw-600 my-4">پلن‌های پیشنهاد اقتصادی مارول ترید</p>
        {/* <p className="text-danger h6 mt-3">
          لطفا پيش از خريد ويديو توضيحات را الزاما مشاهده نماييد
        </p> */}
        <Row className="w-100 my-4 d-none d-lg-flex">

          {plans.map((plan, index) => (
            plan?.active &&
            <Col
              key={plan._id}
              xs="12"
              md="6"
              lg="4"
              xxl="3"
              className="px-2 px-lg-1"
            >
              <PlanCard plan={plan} id={index} />
            </Col>
          ))}
        </Row>
        <div className="d-block d-lg-none">
          <Slider className="d-block d-lg-none" {...settings}>
            {plans.map((plan, index) => (
              plan?.active &&
              <div key={index} >
                <PlanCard plan={plan} id={index} />
              </div>
            ))}
          </Slider>
        </div>

      </Container>
      {/* <Modal
        title={`توضیحات ${activeVideo?.title}`}
        show={activeVideo !== null}
        onHide={() => setActiveVideo(null)}
      >
        {activeVideo && (
          <video className="w-100 rounded-3 shadow-sm overflow-hidden" controls>
            <source src={activeVideo.video} />
          </video>
        )}
        <div className="d-flex flex-center col-gap-1">
          <Button
            onClick={() => setActiveVideo(null)}
            variant="danger"
            className="text-white"
          >
            بستن
          </Button>
          <a
            href="http://pr.marveltradepayments.com"
            className="btn btn-sm btn-primary text-white"
            target="_blank"
            rel="noreferrer"
          >
            خرید پلن
          </a>
        </div>
      </Modal> */}
    </div>
  );
}
