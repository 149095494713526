import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Input, Button, Form, Select, Modal } from "../../../../components";
import { axios } from "../../../../boot";
import { rules, toast } from "../../../../methods";
import { orderStatus } from "../../../../constants";
import { useGetPlan } from "../../../../hooks";

export default function Channel() {
  const { id } = useParams();
  const isNew = id === "new"
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const getData = () => {
    if (!isNew) {
      const url = `/channels/${id}/getChannels`;
      axios.get(url).then(({ data }) => {
        setData(data);
      });
    }

  };
  const files = ["logo"];
  const addChannel = () => {
    const url = "/channels/insert"
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      const isFile = files.includes(key);
      body.append(key, isFile ? data[key][0] : data[key]);
    });
    axios.post(url, body).then(() => {
      let text = "کانال با موفقیت افزوده شد"
      toast({text});
      navigate(-1, { replace: true });
    });

  }
  const updateChannel = () => {
    const url = `/channels/${id}/editChannel`
    axios.patch(url, data).then(({ data }) => {
      let text = "کانال با موفقیت ویرایش شد"
      toast({ text });
      navigate(-1);
    })
  }
  const yesOrno = [
    {
      name : "فعال",
      id : true
    },
    {
      name : "غیرفعال",
      id : false
    },
  ]
  const fromControls = [
    {
      state: "name",
      label: "نام",
    },
    {
      state: "color",
      label: "رنگ کانال",
      type:"color"
    },
    // {
    //   tag : Select,
    //   state: "forPersian",
    //   label: "زبان فارسی",
    //   items : yesOrno
    // },
    {
      tag : Select,
      state: "isPublic",
      label: "عمومی",
      items : yesOrno
    },
    {
      state: "logo",
      label: "عکس کانال",
      type : "file"
    }

  ]
  const deletePurchase = () => {
    const url = `/channels/${id}/delete`
    axios.delete(url).then(() => {
      let text = "پلن با موفقیت حذف شد"
      toast({ text });
      navigate(-1);
    });
  }
  useEffect(getData, []);
  return (
    <Container className="Product">
      <Form onSubmit={isNew ? addChannel : updateChannel}>
        <Row>
          {fromControls.map((item, index) => (
            <Col
              key={index}
              xs="12"
              md="6"
              lg="4"
            >
              {React.createElement(item.tag ?? Input, {
                rules: rules.required,
                value: data[item.state],
                setValue: (val) =>
                  setData((p) => ({ ...p, [item.state]: val })),
                ...item,
              })}
            </Col>
          ))}
          <Col xs="12" className="d-flex flex-center">
            <Button type="submit" variant="primary" className="text-light">
              {isNew ? " ثبت کانال جدید"  : "ثبت تغییرات"}
            </Button>
          </Col>
        </Row>
      </Form>
      {!isNew &&
        <div className="d-flex flex-center flex-wrap my-4">
          <Button onClick={() => deletePurchase()} variant="danger text-white" className="m-2 ">حذف کانال</Button>
        </div>
      }
      {/* <Modal show={show} onHide={setShow}>
        <img className="w-100" src={`data:image/jpeg;base64,${data?.image}`} alt="qrCode" />
      </Modal> */}
    </Container>
  );
}
