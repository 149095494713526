import React from 'react'
import './index.scss'
const SupportBtn = () => {
  return (
    <a title="پشتیبانی تلگرام" target='_blank' href='https://t.me/academymarvel' className='SupportBtn d-flex justify-content-center align-items-center p-2'>
        <i className="bi bi-headset fs-20 text-white"></i>
    </a>
  )
}

export default SupportBtn