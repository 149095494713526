const cartCount = 0;
export default function reducer(state = cartCount, action = {}) {
  switch (action.type) {
    case "SET_CART_COUNT":
      return action.data;
    case "ADD_TO_CART_COUNT":
      return state + (action.data ?? 1);
    case "REMOVE_FROM_CART_COUNT":
      return state - (action.data ?? 1);
    default:
      return state;
  }
}
