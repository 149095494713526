import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Form, InputGroup, Button, Input } from "../../../components";
import { convertPhone, toast } from "../../../methods";
import { axios } from "../../../boot";
import { Container, Row, Col } from "react-bootstrap";
import './index.scss'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { rules } from '../../../constants'
import SignupPage from "./Signup";
import Context from "./_context";
import Verify from "./Verify";
import Code from "./Code";
import VerifyEmail from "./VerifyEmail";
import Email from "./Email";

const Signup = () => {
    const [data, setData] = useState({});
    const [activePage, setActivePage] = useState("signup")

    return (
        <div className="SignUp d-flex justify-content-center ">
            <div className="SignUp-Overflow d-flex align-items-center justify-content-center px-2">
                
                <Context.Provider value={[activePage, setActivePage, data, setData]}>
                    <Container>
                        {activePage === "signup" && <SignupPage />}
                        {activePage === "verify" && <Verify />}
                        {activePage === "code" && <Code />}
                        {activePage === "verifyEmail" && <VerifyEmail />}
                        {activePage === "email" && <Email />}
                    </Container>
                </Context.Provider>
            </div>

        </div>
    )
}

export default Signup