import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, DownloadSection, SupportBtn } from "../../components";
import { showCartCount } from "../../methods";
import { useShowFullName } from "../../hooks";
import { Container } from "react-bootstrap";
import Navbar from "./Navbar";
import Menu from "./Menu";
import Footer from "./Footer";
import RegisterModal from "./RegisterModal";
import "./index.scss";
import { rolesPath } from "../../constants";
export default function UserLayout() {
  const showFullName = useShowFullName();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const isLogged = useSelector((s) => s.isLogged);
  const cartCount = useSelector((s) => s.cartCount);
  const role = useSelector((s) => s.role);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);
  return (
    <div className="bg-white UserLayout">
      <div className="bg-black">
        <Container>
          <header style={{ zIndex: 10 }} className="bg-black  py-3 position-sticky">

            <div className="w-100 mx-auto d-flex align-items-center">
              <Navbar />
              <i
                onClick={() => setShowMenu(true)}
                className="d-block d-xl-none bi bi-list fs-2 cursor-pointer text-light position-absolute start-0"
              />
              <Menu show={showMenu} setShow={setShowMenu} />
              <div className="user-control d-none d-xl-flex flex-center col-gap-3 me-auto">
                <React.Fragment>
                  <NavLink to={rolesPath[role]} className="btn btn-outline-light btn-sm rounded-0">
                    <i className="bi bi-person-fill ms-1" />
                    {showFullName()}
                  </NavLink>
                  <NavLink
                    to="/cart"
                    className="position-relative btn btn-outline-light btn-sm rounded-0"
                  >
                    {cartCount !== 0 && (
                      <Badge variant="danger" label={showCartCount(cartCount)} />
                    )}
                    <i className="bi bi-cart-fill ms-1" />
                    سبد خرید
                  </NavLink>
                </React.Fragment>

              </div>
            </div>

          </header>
        </Container>
      </div>
      <main className="pb-2">
        <Outlet />
        <SupportBtn/>
      </main>

      {/* <DownloadSection /> */}
      <Footer />
      <RegisterModal />
    </div >
  );
}
