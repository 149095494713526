import React, { useState, useEffect } from 'react'

import { Row, Col } from 'react-bootstrap'
import { axios, moment } from '../../../boot'
import { serverStatus } from '../../../constants'
const ServerBill = () => {
    const [serverBill, setServerBill] = useState([])
    const getServerBill = () => {
        const url = "/orders"
        axios.get(url).then(({ data }) => {
            setServerBill(data?.data)
        })
    }
    useEffect(getServerBill, [])
    const serverInfo = (data = {}) => [
        // {
        //     label: "تاریخ خرید",
        //     value: moment.miladiToShamsi({
        //         date: order.createdAt,
        //         format: "jYYYY/jMM/jDD HH:mm:ss",
        //     }),
        // },
        // {
        //     label: "نام کاربری",
        //     value: data?.username,
        // },
        // {
        //     label: "رمزعبور",
        //     value: data?.password,
        // },
        {
            label: "RAM",
            value: data?.ram,
        },
        {
            label: "CPU CORE",
            value: data?.cpu,
        },
        {
            label: "پهنای باند",
            value: data?.bandwidth,
        },
        {
            label: "حافظه ذخیره سازی",
            value: data?.storage,
        },
        {
            label: "محل سرور",
            value: data?.location,
        },
        {
            label: "تعداد",
            value: data?.count,
        },
        {
            label: "قیمت(تومان)",
            value: Number(data?.price).toLocaleString(),
        },
        {
            label: "قیمت(USD)",
            value: data["priceUsd"] ?? "-",
        },
    ];
    return (
        <div>
            <p className='fw-700 text-black text-center my-2 fs-20'>صورتحساب های سرور مجازی</p>
            <Row className="w-100">
                {serverBill?.map((order, index) =>
                    <div className='border border-2 border-black bg-harp' key={index}>
                        <Col xs="12" md="6" lg="12" xl="12" key={index}>
                            <div className="w-100 ">
                                <div className="w-100 d-flex justify-content-between  text-center col-gap-3 fs-5 py-3">
                                    <p className="text-black fs-24 fw-700">VPS:</p>
                                    <span>{serverStatus.badge(order.status)}</span>
                                    {/* <p className="text-black fs-24 fw-700">سالانه {item.name}</p> */}
                                </div>
                                <div className="row w-100 py-3 text-secondary">
                                    <div className="col-6 fs-18 fw-400 text-black text-start">تاریخ خرید</div>
                                    <div dir="ltr" className="col-6 font-en text-black text-start">
                                        {moment.miladiToShamsi({date : order.createdAt})}
                                    </div>
                                    {serverInfo(order?.content[0])?.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <div className="col-6 fs-18 fw-400 text-black text-start">{data.label}</div>
                                            <div dir="ltr" className="col-6 font-en text-black text-start">
                                                {data.value}
                                            </div>
                                        </React.Fragment>
                                    ))}

                                    {/* <div className="col-6 text-primary mt-4">قیمت کل</div>
                                    <div className="col-6  mt-4 d-flex justify-content-end">
                                        <p className="text-primary font-en">{order?.content.price.toLocaleString()} تومان</p>
                                    </div> */}


                                </div>
                            </div>
                        </Col>

                    </div>
                )}
            </Row>
        </div>
    )
}

export default ServerBill