import { NavLink } from "react-router-dom";
import { navItems } from "../../constants";
import { useSelector } from "react-redux";
export default function useRenderNavItems() {
  const isLogged = useSelector((s) => s.isLogged);

  return (items = []) => {
    const elements = [];


    items = navItems.user

    items.forEach((item, index) => {

      //   if ("isLogged" in item && !isLogged) return null;
      if ("path" in item) {
        const navLink = item.isExternal ? (
          <a
            key={index}
            href={item.path}
            target="_blank"
            rel="noreferrer"
            className="py-1 fs-13 fw-600"
          >
            {item.label}
          </a>
        ) : (
          <NavLink key={index} to={item.path} className="py-1 fs-13 fw-600">
            {item.label}
          </NavLink>
        );
        elements.push(navLink);
      }
      if ("children" in item) {
        const dropdown = (
          <div className="dropdown hover show" key={index}>
            <button className="dropdown-toggle py-1 fs-13 fw-600">
              {item.label}
              <i className="bi bi-chevron-left me-1 fs-12" />
            </button>
            <div className="dropdown-menu transition show">
              {item.children.map((item, index) => (
                <div key={index} className="dropdown-item text-start">
                  {item.isExternal ? (
                    <a
                      key={index}
                      href={item.path}
                      target="_blank"
                      rel="noreferrer"
                      className="text-dark"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <NavLink key={index} to={item.path} className="text-dark fs-12">
                      {item.label}
                    </NavLink>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
        elements.push(dropdown);
      }
    });
    return elements;
  };
}
