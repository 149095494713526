import React, { useState, useContext } from 'react'
import Context from './_context';
import { Form, InputGroup, Button, Input } from "../../../components";
import { Container, Row, Col } from "react-bootstrap";
import { rules } from '../../../constants';
import { Link } from 'react-router-dom';
import { convertPhone } from '../../../methods';
const SignupPage = () => {
    const [activePage, setActivePage, data, setData] = useContext(Context);
    const formControl = [
        {
            label: "شماره تلفن",
            state: "phone",
            type: "number",
            // rules: rules.phoneNumber
        },
        {
            label: "ایمیل",
            state: "email",
            type: "email",
            rules: rules.email
        },
        {
            label: "نام",
            state: "firstName",
        },
        {
            label: "نام خانوادگی",
            state: "lastName",
        },
        // {
        //     label: "کدمعرف",
        //     state: "referral",
        // },
        {
            label: "رمزعبور",
            state: "password",
            type: "password",
            rules: rules.password
        },

    ]
    const handleSubmit = () => {
        let phone = data?.phone
        let body = data
        // body.device = device ? "ios" : "android"
        let regPhone = new RegExp("^(\\+98|0|098)?9\\d{9}$");
        if (regPhone.test(phone)) {
            body = { ...body, phone: convertPhone(phone) }
            setData(body)
            setActivePage("verify")
        } else {
            setData(body)
            setActivePage("verifyEmail")
        }
    }

    return (
        <div className="h-100">
            
            <Form
                onSubmit={handleSubmit}
                className=" d-flex flex-column flex-center text-center"
            >
                {formControl.map(item =>
                    <Input
                        key={item.state}
                        {...item}
                        value={data[item.state]}
                        setValue={(val) =>
                            setData((p) => ({ ...p, [item.state]: val }))
                        }
                        className="border"
                    />
                )}

                {/* <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                        <input className="mx-1" value={device} onChange={(e) => setDevice(p => p = !p)} type="checkbox" />
                        <label className="fs-14 fw-500 text-black">اگر از آیفون استفاده میکنید</label>
                    </div>
                </div> */}
                <Button variant="black" className="w-100 my-4 rounded-0 py-2" type="submit">
                    بعدی
                </Button>


                <Link className="fs-16 fw-700 text-black" to="/login">بازگشت به صفحه ورود</Link>
            </Form>
        </div>
    )
}

export default SignupPage