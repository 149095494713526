import { Col, Container, Row } from "react-bootstrap";
import permitImage from "../../assets/icons/home/permit.svg";
import "./index.scss";
export default function PermitSection() {
  const link =
    "https://find-and-update.company-information.service.gov.uk/search?q=marveltradeco";
  return (
    <div className="PermitSection bg-primary py-3">
      <Container>
        <Row className="position-relative justify-content-around align-items-start py-5">
          <Col xs="12" lg="5">
            <div className="d-flex flex-column flex-center text-center row-gap-5">
              <h3 className="text-light">
                مجوز ثبت شرکت‌های انگلستان مارول ترید
              </h3>
              <p className="text-light fs-4">
                دفتر جدید مارول ترید در انگلستان همراه با مجوز ثبتی آن از لینک
                زیر می‌توانید استعلام این مدرک را به راحتی بگیرید.
              </p>
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="w-100 Button btn btn-lg btn-outline-light"
              >
                استعلام مجوز انگلستان
                <i className="bi bi-chevron-left" />
              </a>
            </div>
          </Col>
          <Col className="img-section" xs="12" lg="5">
            <img
              className="w-100 transition"
              src={permitImage}
              alt="permitImage"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
