import Crypto from '../assets/images/home/crypto.png'
import Dollor from '../assets/images/home/dollor.png'
import Dollor2 from '../assets/images/home/dollor2.png'
import Navigation from '../assets/images/home/navigation.png'
import Star from '../assets/images/home/star.png'
const guidance = [
  // {
  //   title: "منتور اختصاصی",
  //   text: "راهنمایی اختصاصی شما در جهت مدیریت سرمایه بهتر",
  // },
  {
    src : Star,
    title: "تحلیل اختصاصی بیت کوین",
    text: "به شکل روزانه چارت بیت کوین را تحلیل خواهیم کرد",
  },
  {
    src : Dollor2 ,
    title: "اعلان های خروج سرمایه",
    text: "در زمان هایی که احتمال ریزش شدیدی را در بازار بدهیم اعلان های فوری سیو سود در اپلیکیشن به شما داده میشود",
  },
  {
    src : Navigation ,
    title: "گزارش روزانه سود و زیان",
    text: "مارول روزانه برآیند سود و زیان تحلیل ها را در اپلیکیشن اطلاع رسانی می نماید ",
  },
  {
    src : Dollor,
    title: "آموزش‌های مدیریت سرمایه",
    text: " ویدئو های آموزش نحوه خرید و فروش صحیح برای شما آماه شده است تا با کمترین خطا معامله نمایید",
  },
  {
    src : Crypto,
    title: "ارائه مشاوره و تحلیل",
    text: "مارول سعی دارد تا بهترین و پر سود ترین ارز ها را به شما معرفی نماید ",
  },
];
export default guidance;
