const phoneNumber = [
  (val = "") => val.length > 0 || "ورودی الزامی است.",
  (val = "") => val.substring(0, 2) === "09" || "شماره باید با 09 شروع شود.",
  (val = "") => val.length === 11 || "شماره موبایل معتبر نیست.",
];
const required = [(val = "") => val.length !== 0 || "ورودی الزامی است."];
const attachments = [
  (val = []) => val.length !== 0 || "فایل الزامی است.",
  (val = []) => val.length <= 8 || "حداکثر تعداد فایل‌ها 8 تا است.",
];
const hour = [
  (val = "") => `${val}`.length !== 0 || "ورودی الزامی است.",
  (val = "") => (Number(val) >= 0 && Number(val) <= 24) || "ورودی نامعتبر است.",
];
const min = [
  (val = "") => `${val}`.length !== 0 || "ورودی الزامی است.",
  (val = "") => (Number(val) >= 0 && Number(val) <= 59) || "ورودی نامعتبر است.",
];
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const email = [(val = "") => emailRegex.test(val) || "ایمیل نامعتبر است"];
const birthDate = [
  (val = "") => val.length === 4 || "سال تولد باید 4 رقمی باشد",
  (val = "") => Number(val) >= 1300 || "حداقل مقدار مجاز 1300 است",
  (val = "") => Number(val) <= 1500 || "حداکثر مقدار مجاز 1500 است",
];
const off = [
  (val = "") =>
    (Number(val) >= 0 && Number(val) <= 100) ||
    "حداکثر مقدار مجاز از 0 تا 100 است",
];
const checkIp = (val = "") => {
  const condition1 = val.split(".").length === 4;
  const condition2 = val
    .split(".")
    .every((e) => Number(e) >= 0 && Number(e) <= 255);
  return condition1 && condition2;
};
const checkPort = (val = undefined) => {
  if (val === undefined) return true;
  const condition1 = val.length >= 1;
  const condition2 = val.length <= 5;
  return condition1 && condition2;
};
function ipAndPort(val = "") {
  const parts = val.split(":");
  const ip = parts[0];
  const port = parts[1];
  if (!checkIp(ip)) return "ورودی ip معتبر نیست";
  if (!checkPort(port)) return "ورودی port معتبر نیست";
  return true;
}
const ip = [ipAndPort];
const rules = {
  phoneNumber,
  required,
  attachments,
  hour,
  min,
  email,
  birthDate,
  ip,
  off,
};
export default rules;
