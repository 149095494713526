import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'
import Spaceship from '../../../assets/images/home/spaceship.png'

const TermAndConditions = () => {
    return (
        <div className="w-100 TermAndConditions-home">
            
                <Row className='w-100'>
                    <Col className="TermAndConditions-card py-4" xs="12" lg="8">
                        <p className="fs-30 my-4 fw-600 text-black d-block">
                            قوانین و مقررات
                        </p>
                        <p className="fs-15 fw-600 text-black">
                            با سلام و خوش آمد گویی خدمت جنابعالی اگر شما در حال خرید و یا تمدید اشتراک حساب کاربری در بستر مارول میباشید. <br /> خواهشمند است که قوانین و مقررات ذیل را مطالعه و در صورت قبول کلیه مفاد آن نسبت به این خرید اقدام نمایید.
                        </p>
                        <ul className="px-3 my-4">
                            <li className="my-1">
                                <p className="text-black fs-14">
                                    تمامی تحلیل های مارول ترید پیشنهاد بود و شما با آگاهی کامل از اینکه بازار های مالی هم سود و هم ضرر دارند اشتراک های مارول رو تهیه میکنید
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="text-black fs-14">
                                    با عضویت در تمامی پلن های مارول تمامی مسئولیت سرمایه و سود و زیان های احتمالی به عهده خود شماست و تیم مارول هیچ مسئولیتی ندارد و قبلا شما باید به این آگاهی رسیده باشید که بازار های مالی هم سود دارد و هم ضرر و هیچ موردی توسط تیم مارول تضمین شده نمی باشد
                                </p>
                            </li>
                            <li className="my-1">
                                <p className="text-black fs-14">

                                    با عضویت در تمامی پلن های مارول شما قبول میکنید با توجه به اینکه سرمایه شما در اختیار شماست و تیم ما تنها پیشنهادات خرید یا فروش می‌دهد هیچ مسئولیتی متوجه تیم مارول نمی باشد و مسئولیت سرمایه و یا سود و ضرر های احتمالی به عهده خود شماست
                                </p>
                            </li>
                            <li>
                                <p className="text-black fs-14">
                                    شناخت کامل و دقیقی از بازار ارزهای دیجیتال دارا بوده و دوره
                                    آموزشی مرتبط با خرید و فروش ارزهای دیجیتال را گذرانده و قدرت
                                    تحلیل بازار را دارا میباشید
                                </p>
                            </li>
                            <li>
                                <p className="text-black fs-14">
                                    اپلیکیشن مارول صرفا یک بستر آموزشی و مشاوره ای میباشد و
                                    مسئولیت انجام هرگونه دادوستد در این بازار بر عهده صاحب حساب
                                    میباشد. شما مطلع میباشید که در هر بازار سود و زیان بطور همزمان
                                    وجود دارد و تیم مارول صرفا تحلیل و برداشت خود را از بازار در
                                    این بستر ارائه میدهد و مسئولیت نهایی جهت انجام هرگونه خرید و
                                    یا فروش ارزهای دیجیتال با توجه به جمع بندی و تحلیل نهایی صاحب
                                    حساب صورت خواهد گرفت.
                                </p>
                            </li>
                            <li>
                                <p className="text-black fs-14">شما دارای سن قانونی ۱۸ سال تمام میباشید</p>
                            </li>
                            <li>
                                <p className="text-black fs-14">تابع قوانین جمهوری اسلامی ایران میباشید</p>
                            </li>
                        </ul>

                    </Col>
                    <Col style={{backgroundColor : "#191D2B"}} className="d-flex flex-column justify-content-around TermAndConditions-card px-2 py-4" xs="12" lg="4">
                        <img className="w-100" src={Spaceship} alt="spaceship" />
                        <p className="text-center text-primary fs-18 fw-500 mb-4">
                            استفاده از اپلیکیشن و سایت مارول ترید به منزله <br/> تایید قوانین و مقررات است.
                        </p>
                    </Col>
                </Row>
            
        </div>
    )
}

export default TermAndConditions