import React from 'react'

import './CardServices.scss'

const CardServices = ({title = "" , text= "" , src}) => {
  return (
    <div className='CardServices p-2 '>
        <div className="CardServices-image m-2 py-4">
            <img src={src} width={70} height={65} alt='images services' />
        </div>
        <p className="text-black fw-700 fs-18 my-4">{title}</p>
        <p className='text-black text-center fs-18 fw-400 mx-2'>{text}</p>
    </div>
  )
}

export default CardServices