import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import correctIcon from "../../../assets/icons/cart/correct.svg";
import failIcon from "../../../assets/icons/cart/fail.png";
import { useQueryString } from "../../../hooks";
import "./index.scss";
import { downloadItems } from "../../../constants";
export default function Receipt() {
  const { status, refId } = useQueryString();
  return (
    <Container className="Receipt py-5">
      <Row>
        <Col xs="12" md="10" lg="5">
          <div className="receipt-section w-100 d-flex flex-column flex-center row-gap-3 text-center border border-info rounded-3 p-5">
            <img
              width="100"
              height="100"
              src={status === "OK" ? correctIcon : failIcon}
              alt={status === "OK" ? "correct" : "fail"}
            />
            <span className={`h4 text-${status === "OK" ? "info" : "danger"}`}>
              {status === "OK"
                ? "پرداخت شما با موفقیت انجام شد"
                : "پرداخت شما ناموفق بود"}
            </span>
            {status === "OK" &&
              <Row className=" d-flex justify-content-center my-4">
                {downloadItems.map((item, index) => (
                  <Col key={index} xs="12" md="6" lg="12" className="px-1">
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`position-relative d-inline-block shadow bg-black border border-black w-100 rounded overflow-hidden m-0 p-0`}
                      download={item.download ?? false}
                    >
                      <img
                        className="w-100 object-fit-contain"
                        height="40"
                        src={item.icon}
                        alt={item.label}
                      />
                    </a>
                  </Col>
                ))}
                <Col xs="12" md="6" lg="12" className="px-1">
                  <a target='_blank' className='btn btn-primary text-white w-100' href="https://marveltrade.org/iosInstalling.mp4">نحوه نصب و راه اندازی اپ ios</a>
                </Col>
              </Row>
            }

            <span className="my-auto d-flex col-gap-4 fs-5">
              {refId && (
                <React.Fragment>
                  <span className="text-secondary">کد پیگیری</span>
                  <span className="text-info">{refId}</span>
                </React.Fragment>
              )}
            </span>

            <p className="text-secondary">
              برای پیگیری سفارش میتوانید به پروفایل مراجعه کنید
            </p>
          </div>
        </Col>

      </Row>
    </Container>
  );
}
