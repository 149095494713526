import blackLogo from '../../assets/logo/landingLogo.svg'
import useRenderNavItems from "./_useRenderNavLink";
export default function Navbar() {
  const renderNavItems = useRenderNavItems();
  return (
    <nav className="Navbar d-xl-flex align-items-center col-gap-3">
      <div className="avatar">
        <img src={blackLogo} alt="logo" className='' />
      </div>
      <div className="nav-items d-none d-xl-flex align-items-center col-gap-3">
        {renderNavItems()}
      </div>
    </nav>
  );
}
