import { useNavigate } from "react-router-dom";
// import { moment } from "../../boot";
import { Button } from "..";
import "./index.scss";
export default function ArticleCard({
  comments = "",
  createdAt = "",
  title = "",
  img = "",
  _id = "",
  // brief=''
}) {
  const navigate = useNavigate();
  const showComment = () => {
    if (comments) return `تعداد دیدگاه‌ها: ${comments}`;
    return "بدون دیدگاه";
  };
  const goToArticle = () => {
    navigate(_id);
  };
  return (
    <div className="ArticleCard d-flex flex-column w-100 position-relative overflow-hidden rounded-3 shadow-sm">
      <img className="bg-img bg-dark" src={img} alt={title} />
      <div className="content position-relative d-flex flex-column mt-auto text-light fw-bold fs-5 row-gap-3 p-5">
        <h5 className="h4 lh-normal text-truncate mb-0">{title}</h5>
        <span>{createdAt}</span>
        {/* <span>{moment.miladiToShamsi(createdAt)}</span> */}
        <span>{showComment()}</span>
        <Button variant="light" size="lg" outline onClick={goToArticle}>
          ادامه مطلب
          <i className="bi bi-chevron-left me-1" />
        </Button>
      </div>
    </div>
  );
}
