import { useState, useContext, useEffect } from 'react'
import { axios } from '../../../boot'
import { Input } from '../../../components'
import { Col } from 'react-bootstrap'
import { motion } from 'framer-motion'
import Context from './_context'
import { toast } from '../../../methods'

const Payment = () => {
    const [page, setPage, planDetails, setPlanDetails] = useContext(Context);

   
    return (
        <div className='w-100 row d-flex flex-column PAYMENT-PLAN'>
            {/* {formControl.map((item) =>
                <Col key={item.state} xs="12" lg="5">
                    <Input
                        
                        {...item}
                        value={data[item.state]}
                        setValue={val => setData(p => ({ ...p, [item.state]: val }))}
                    />
                </Col>
            )} */}
            <Col xs="12" lg="5">
                <p className="fs-16 fw-500 text-black text-start">نحوه پرداخت :</p>
                <div className="d-flex justify-content-center justify-content-lg-between align-items-center flex-wrap my-2">
                    <motion.button onClick={() => setPage("zarinPayment")} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className='paymentBtn m-1  shadow'>
                        <p className='my-2 fs-16 fw-400 text-black'>ریالی</p>
                        <i className="bi bi-cash-stack fs-20"></i>
                        <p className='fs-20 fw-900 text-black'>{Number(planDetails?.toomanAmount)?.toLocaleString()} تومان</p>
                    </motion.button>
                    <motion.button onClick={() => setPage("usdPayment")} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className='paymentBtn m-1  shadow'>
                        <p className='my-2 fs-16 fw-400 text-black'>تتری</p>
                        <i className="bi bi-currency-exchange fs-20"></i>
                        <p className='fs-20 fw-900 text-black'>{planDetails?.price}تتر</p>
                    </motion.button>
                </div>
            </Col>
            {/* <Col xs="12" lg="5">
                <button onClick={() => { handlePayment === "usd" ? setPage("usdPayment") : handleZarinPal() }} className="btn btn-tufts-blue w-100 py-2 rounded-0 fs-14 fw-700 text-white">پرداخت</button>
            </Col> */}
            <Col xs="12" lg="5">
                <button onClick={() => setPage("condition")} className="btn btn-black w-100 py-2 rounded-0 fs-14 fw-700 text-white">بازگشت</button>
            </Col>
        </div>
    )
}

export default Payment