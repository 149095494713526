// import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArticleCard } from "../../../components";
import { articles } from "../../../constants";
// import { axios } from "../../../boot";
import "./index.scss";
export default function Articles() {
  // const [articles, setArticles] = useState([]);
  // const getArticles = () => {
  //   const url = "/pub/blog";
  //   axios.get(url).then(({ data }) => {
  //     setArticles(data.data);
  //   });
  // };
  // useEffect(getArticles, []);
  return (
    <div className="Articles">
      <Container>
        <h3 className="text-info py-5 d-block text-center">صفحه اصلی وبلاگ</h3>
        <Row className="align-items-start justify-content-start">
          {articles.toMatrix(Math.ceil(articles.length / 2)).map((item, i) => (
            <Col
              key={i}
              xs="12"
              md="6"
              className="d-flex flex-column row-gap-4"
            >
              {item.map((item, index) => (
                <ArticleCard key={index} index={index} {...item} />
              ))}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
