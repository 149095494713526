import React from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { socialNetworks } from "../../constants";
import namad from '../../assets/images/namad.png'
import neshan from '../../assets/images/neshan.png'

export default function Footer() {

  const footerItems = [
    {
      src: "",
      label: "خدمات مارول",
      className: "",
      children: [
        {
          label: "خرید اکانت پیشنهاد  تحلیل کریپتو",
          href: "profile",
          // isExternal: true,
        },
        {
          label: "عضویت در مدرسه مارول",
          href: "https://academymarvel.com/",
          isExternal: true,
        },
        {
          label: "خرید ویندوز سرور ترید",
          href: "/windows-server-trade",
        },
      ],
    },
    // {
    //   label: "آخرین مقالات",
    //   children: [
    //     {
    //       label: "کیف پول ارزهای دیجیتال",
    //       href: "/articles/2",
    //     },
    //     {
    //       label: "زندگی نامه ایلان ماسک",
    //       href: "/articles/0",
    //     },
    //     {
    //       label: "رابرت کیوساکی کیست؟",
    //       href: "/articles/1",
    //     },
    //   ],
    // },
    {
      src: "",
      label: "اطلاعات تماس",
      className: "CallInfo",
      children: [
        {
          label: "info@marveltrade.org",
          icon: "envelope",
          href: "mailto:info@marveltrade.org",
          isExternal: true,
          dir: "ltr",
        },
        {
          label: "@academymarvel",
          icon: "telegram",
          href: "https://t.me/academymarvel",
          isExternal: true,
          dir: "ltr",
        },
        {
          label: "@marvel_frequency",
          icon: "instagram",
          href: "https://www.instagram.com/marvel_frequency",
          isExternal: true,
          dir: "ltr",
        },
      ],
    },
    {
      src: "",
      label: "شبکه‌های اجتماعی",
      className: "",
      children: [
        ...socialNetworks.map(({ icon, link }) => ({
          icon,
          href: link,
          isExternal: true,
        })),
      ],
    },
  ];
  return (
    <footer className="FooterUser  position-relative bg-black  py-3 shadow-sm">
      <Row className="position-relative align-items-center my-4">
        {footerItems.map((item, i) => (
          <Col className={`position-relative d-flex align-items-start justify-content-center ${item.className} `} key={i} xs="12" md="6" lg="3">
            <div className={`d-flex flex-column align-items-center w-fit  `}>
              <h5 className="lh-normal text-light">{item.label}</h5>
              <ul
                className={
                  i === 2
                    ? `d-flex align-items-center justify-content-between w-100`
                    : `lh-lg d-flex flex-column align-items-center justify-content-center`
                }
              >
                {item.children.map((child, j) => (
                  <li key={j}>
                    {React.createElement(child.isExternal ? "a" : NavLink, {
                      [child.isExternal ? "href" : "to"]: child.href,
                      ...(child.isExternal && { target: "_blank" }),
                      className: "d-flex col-gap-2 w-fit text-start text-white transition",
                      children: (
                        <React.Fragment>
                          {"icon" in child && (
                            <i className={`bi bi-${child.icon}`} />
                          )}
                          {"label" in child && (
                            <span dir={child.dir ?? "rtl"}>{child.label}</span>
                          )}
                        </React.Fragment>
                      ),
                    })}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        ))}
        {/* <Col className="d-flex align-items-center justify-content-center" xs="12" md="6" lg="3">
          <div className='d-flex flex-center bg-white rounded mx-1'>
            <img width="80" height="85" src={namad} alt="" />
          </div>
          <div className='d-flex flex-center bg-white rounded mx-1'>
            <img width="80" height="85" src={neshan} alt="" />
          </div>
        </Col> */}
      </Row>
      <hr className="w-100 my-3" />
      <Row>
        <Col xs="12" md="6" lg="4">
          <p className="text-center text-light pb-3">
            کلیه حقوق وبسایت متعلق به مارول ترید می‌باشد.
          </p>
        </Col>
      </Row>
    </footer>
  );
}
