import instagram from "../assets/icons/contact us//InstagramOutline.svg";
import telegram from "../assets/icons/contact us/TelegramOutline.svg";
import twitter from "../assets/icons/contact us/twitter.svg";
import youtube from "../assets/icons/contact us/YoutubeOutline.svg";
import twitch from "../assets/icons/contact us/twitch.svg";
const socialNetworks = [
  {
    label: "اینستاگرام",
    image: instagram,
    icon: "instagram",
    value: "@marvel_frequency",
    link: "https://www.instagram.com/marvel_frequency",
  },
  {
    label: "تلگرام",
    image: telegram,
    icon: "telegram",
    value: "@academymarvel",
    link: "https://t.me/academymarvel",
  },
  {
    label: "یوتیوب",
    image: youtube,
    icon: "youtube",
    value: "@marvelfrequency",
    link: "https://www.youtube.com/@marvelfrequency",
  },
  {
    label: "کانال تلگرام",
    image: telegram,
    icon: "telegram",
    value: "@arash_timess",
    link: "https://t.me/arash_timess",
  },
  // {
  //   label: "Twitch",
  //   image: twitch,
  //   icon: "twitch",
  //   value: "marvel_trade",
  //   link: "https://www.twitch.tv/marvel_trade?sr=a",
  // },
  // {
  //   label: "توییتر",
  //   image: twitter,
  //   icon: "twitter",
  //   value: "@Marveltrade2",
  //   link: "https://twitter.com/Marveltrade2?s=09",
  // },

  // {
  //   label: "فیسبوک",
  //   image: facebook,
  //   value: "",
  //   link: "",
  // },
  // {
  //   label: "تلفن",
  //   image: phone,
  //   value: "",
  //   link: "",
  // },
];
export default socialNetworks;
